import React, { useState } from 'react'
import uuid from 'uuid/v4'
import { isEqual, omit } from 'lodash'
import formatMyAddressesFormData from '../utils/formatMyAddressesFormData'
import flattenFields from '../../shared/util/flattenFields'
import submitMyAddressesForm from '../network/requests/submitMyAddressesFormV2'
import deleteAddress from '../network/requests/deleteAddress'
import { trackEvent } from '../analytics'
import transformAddressesErrors from '../validation/transformAddressesErrors'
import removeFalsyStreetValueFromAddress from '../utils/removeFalsyStreetValueFromAddress'
import toOptionsObject from '../utils/toOptionsObject'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import MultipleAddressesV2 from './MultipleAddressesV2'
import SuccessModal from './SuccessModal'

const GENERIC_ADDRESS_ERROR_MESSAGE = "Sorry, we can't save your address at this time. Please try again later."

const validateAddresses = addresses => {
  const errors = addresses.reduce((acc, address) => {
    if (!address.address.residentialStatus) {
      acc[address.clientId] = { residentialStatus: 'The residential status is required' }
    }
    return acc
  }, {})
  return Object.keys(errors).length && errors
}

export default function MyAddressesFormV2 ({ data, onAddressChange }) {
  const [initialState, setInitialState] = useState(
    () => formatMyAddressesFormData(data.primaryAddress, data.additionalAddresses),
    [data],
  )
  const [addressesSuccessfullyDeleted, setAddressesSuccessfullyDelete] = useState([])
  const [addressesToDelete, setAddressesToDelete] = useState([])
  const [editable, setEditable] = useState(false)
  const [errorModalContent, setErrorModalContent] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [switchedPrimaryAddress, setSwitchedPrimaryAddress] = useState([])
  const [state, setState] = useState(initialState)

  const haveAnyAddressesBeenRemoved = () => {
    // addressesSuccessfullyDeleted contains addresses that were removed successfully
    // we need to ignore the case where an additional address was set as the primary address
    // when this happens, we don't need to show the message explaining products and guarantees may gave been removed
    return addressesSuccessfullyDeleted.filter(deletedId => !switchedPrimaryAddress.includes(deletedId)).length
  }

  const handleSave = async () => {
    setFormErrors({})
    setIsSaving(true)
    flattenFields(state)

    const [primaryAddress, ...addresses] = state
      // empty addresses were like;y "add"ed by mistake
      // will get removed from state too on a successful save
      .filter(address => Boolean(address.value))
      .map(item => ({
        ...item,
        address: removeFalsyStreetValueFromAddress(item.address)
      }))

    const residentialStatusErrors = validateAddresses([primaryAddress, ...addresses])
    if (residentialStatusErrors) {
      setIsSaving(false)
      setFormErrors(residentialStatusErrors)
      return
    }

    const payload = {
      id: data.id,
      user: {
        id: data.details.userId,
      },
      'primary-address': {
        ...primaryAddress.address,
        id: primaryAddress.id || uuid(),
      },
      addresses: addresses
        .filter(x => x.address)
        .map(({ clientId, ...x }) => ({
          ...x.address,
          id: x.id || clientId,
        })),
    }

    try {
      if (addressesToDelete.length) {
        await Promise.all(
          addressesToDelete.map(addressId =>
            deleteAddress(addressId).then(() => {
              setAddressesSuccessfullyDelete(prev => [...prev, addressId])
              setAddressesToDelete(prev => prev.filter(prevAddressId => prevAddressId !== addressId))
            }),
          ),
        )
      }
    } catch (err) {
      setIsSaving(false)
      const removedAddressText = addressesToDelete.length > 1 ? 'one or more removed addresses' : 'your removed address'
      setErrorModalContent(
        <>
          <p className='text--center'>There was an issue recording {removedAddressText}. Please try again later.</p>
        </>,
      )
      return
    }

    submitMyAddressesForm(data.id, payload)
      .then(response => {
        const newState = state
          .filter(x => x.address)
          .map((x, i) => ({
            ...x,
            id: response[i].id,
            address: response[i],
          }))
        setInitialState(newState)
        setState(newState)
        setEditable(false)
        setShowSuccessModal(true)
        setIsSaving(false)
        trackEvent('submit', 'updated address details')
        onAddressChange(newState)
      })
      .catch(error => {
        setIsSaving(false)
        if (error.status === 422) {
          setFormErrors(transformAddressesErrors(error.responseJSON.errors))
          return
        }
        if (error.status === 404) {
          const rejectedAddressId = error.responseJSON.errors.detail
          const rejectedAddress = [data.primaryAddress, ...data.additionalAddresses].find(
            address => String(address.id) === rejectedAddressId,
          )
          setErrorModalContent(
            <>
              <p className='text--center'>
                Sorry, we couldn&apos;t match your information in our records for the following address:
              </p>
              <p className='text--center'>
                <strong>{rejectedAddress.fullAddress}</strong>
              </p>
            </>,
          )
          return
        }

        setErrorModalContent(<p className='text--center'>{GENERIC_ADDRESS_ERROR_MESSAGE}</p>)
      })
  }

  const removeIdsFromChangedAddresses = changedAddresses => {
    const omitList = ['residentialStatus'] // items that can change that don't need to trigger an address replacement in the DB

    return changedAddresses.map((item, idx) => {
      if (
        // address already exists
        item.id &&
        // has matching counterpart
        state[idx] &&
        // address has changed
        !isEqual(omit(item.address, omitList), omit(state[idx].address, omitList))
      ) {
        if (item.id === data.primaryAddress.id) {
          // if it is the primary address, just add it to the success list
          setAddressesSuccessfullyDelete(prev => [...prev, item.id])
        } else {
          setAddressesToDelete(prev => [...prev, item.id])
        }
        return { ...item, id: undefined }
      }
      return item
    })
  }

  return (
    <div style={isSaving ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
      {isSaving && <LoadingOverlay description={'Saving changes'} spinner />}
      <div>
        <h2 className='header__details--address'>My address</h2>
      </div>
      <div>
        <MultipleAddressesV2
          fields={state}
          statusOptions={toOptionsObject(data.details.statusOptions)}
          onChange={(addresses, switched) => {
            if (switched) {
              setSwitchedPrimaryAddress(switched)
            }
            setState(removeIdsFromChangedAddresses(addresses, state))
          }}
          editable={editable}
          onDelete={addressId => setAddressesToDelete([...addressesToDelete, addressId])}
          errors={formErrors}
          initialState = {initialState}
        />
        {editable && (
          <div style={{ marginTop: '20px' }}>
            <button
              onClick={() => {
                handleSave().catch(() => {
                  setIsSaving(false)
                  setErrorModalContent(<p className='text--center'>{GENERIC_ADDRESS_ERROR_MESSAGE}</p>)
                })
              }}
              className='button button__secondary'
              disabled={isEqual(state, initialState) || isSaving}
              style={isEqual(state, initialState) || isSaving ? { opacity: 0.3 } : {}}
            >
              Save your changes
            </button>
          </div>
        )}
      </div>
      <div className='bottom-spacer' style={{ paddingTop: '20px' }}></div>
      {errorModalContent && (
        <SuccessModal
          isSuccess={false}
          onClose={() => setErrorModalContent('')}
          message={errorModalContent}
          title='Oops, there was a problem'
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          isSuccess={true}
          onClose={() => {
            setShowSuccessModal(false)
            setAddressesSuccessfullyDelete([])
          }}
          message={`You changes have been saved successfully. ${
            haveAnyAddressesBeenRemoved()
              ? 'Any products registered at your removed addresses have now been disassociated from you account.'
              : ''
          }`}
          title='Changes saved'
        />
      )}
    </div>
  )
}
