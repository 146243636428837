import React, { useState } from 'react'
import cx from 'classnames'
import uuid from 'uuid/v4'
import InputText from './InputText'
import SelectOption from './SelectOption'
import transformError from '../validation/transformError'
import CapturePlusInput from '../../shared/components/CapturePlusInput'
import submitRegistrationForm from '../network/requests/submitRegistrationForm'
import validator from '../validation/validator'
import registrationSchema, { registrationPhaseTwoSchema } from '../validation/schemas/registration'
import removeFalsyStreetValueFromAddress from '../utils/removeFalsyStreetValueFromAddress'
import { CONSENT_TYPES } from '../consts'
import AddressFallbackInput from './AddressFallbackInput'
import WelcomeModal from './WelcomeModal'
import LoadingOverlay from './LoadingOverlay.js'
import Genesys from './Genesys'

const INITIAL_STATE = {
  title: '',
  firstName: '',
  lastName: '',
  telephoneNumber: '',
  alternativePhoneNumber: '',
  residentialStatus: 'Homeowner',
  primaryAddress: {
    address: {
      unitNumber: '',
      buildingName: '',
      houseNumber: '',
      street: '',
      district: '',
      city: '',
      postcode: '',
    },
  },
  hasExistingProduct: '',
  surveysAndResearch: '',
  productsAndServices: '',
  otherGroups: '',
  thirdParty: '',
  acceptTc: false,
}

// We need to pass in a dummy address id for new address because json:api implementation requires it
// although it won't be used in the backend
const DUMMY_ADDRESS_ID = 'dummy_id'

const RegistrationForm = ({ data, isPhaseTwo, successUrl, wasStpNumberTransferred }) => {
  const [state, setState] = useState(INITIAL_STATE)
  const [loading, setLoading] = useState(false)
  const statusOptions = ['Homeowner', 'Landlord', 'Tenant']
  const statusOptionsV2 = [...statusOptions, 'Other']
  const surveyOptions = ['Yes', 'No']
  const titleOptions = ['Mr', 'Mrs', 'Ms', 'Miss', 'Doctor', 'Sir', 'Professor', 'Other']
  const [fieldErrors, setFieldErrors] = useState({})
  const [addressFallback, setAddressFallback] = useState(false)
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)
  const consent = data

  const goToSuccessUrl = () => (window.location = successUrl)

  const goToSerialVerifyPage = () => (window.location = '/registration/serial-verify')

  const handleChange = key => e => {
    const value = e.target ? e.target.value : e
    const newState = {
      ...state,
      [key]: value,
    }
    setState(newState)
  }

  const handleChangeAddress = (name, value) => {
    const address = removeFalsyStreetValueFromAddress({...state.primaryAddress.address, [name]: value})
    const newState = {
      ...state,
      primaryAddress: {
        address,
      },
    }
    setState(newState)
  }

  const handleAddressError = error => {
    setLoading(false)
    const addressErrors = error.responseJSON.errors[`address_${DUMMY_ADDRESS_ID}`]
    setFieldErrors({
      primaryAddress: {
        address: Object.keys(addressErrors).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: addressErrors[curr][0],
          }),
          {},
        ),
      },
    })
  }

  const handleSubmit = () => {
    const address = removeFalsyStreetValueFromAddress(state.primaryAddress.address)
    setFieldErrors({})
    validator(isPhaseTwo ? registrationPhaseTwoSchema : registrationSchema, state)
      .then(() => {
        const payload = {
          consent_type: {
            privacy_policy: state.acceptTc,
            consumer_legal_terms: state.acceptTc,
            consumer_surveys_and_research: state.surveysAndResearch === 'Yes',
            consumer_products_and_services: state.productsAndServices === 'Yes',
            consumer_other_groups: state.otherGroups === 'Yes',
            consumer_third_party: state.thirdParty === 'Yes',
          },
          consentRevisionId: consent.revisionId,
          user: {
            id: uuid(),
            firstName: state.firstName,
            lastName: state.lastName,
          },
          'primary-address': {
            ...address,
            id: DUMMY_ADDRESS_ID,
            telephoneNumber: state.telephoneNumber,
            mobilePhoneNumber: state.alternativePhoneNumber,
            title: state.title,
            has_existing_product: state.hasExistingProduct === 'Yes',
            residentialStatus: state.residentialStatus,
          },
        }

        if (isPhaseTwo) {
          setLoading(true)
          submitRegistrationForm(payload)
            .then(response => {
              if (response.statusCode === 201) {
                setLoading(false)
                setShowWelcomeModal(true)
              }

              if (response.statusCode === 200) {
                goToSerialVerifyPage()
              }
            })
            .catch(error => {
              setLoading(false)
              if (error.status === 422) {
                handleAddressError(error)
              } else {
                const errors = transformError(error)
                setFieldErrors(errors)
              }
            })
        } else {
          submitRegistrationForm(payload)
            .then(goToSuccessUrl)
            .catch(error => {
              setLoading(false)
              if (error.status === 422) {
                handleAddressError(error)
              } else {
                const errors = transformError(error)
                setFieldErrors(errors)
              }
            })
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.status === 422) {
          handleAddressError(error)
        } else {
          const errors = transformError(error)
          setFieldErrors(errors)
        }
      })
  }

  const renderConsents = () => {
    const consents = Object.values(CONSENT_TYPES)
    return consents.map(({ label, name }) => (
      <div
        key={name}
        className={cx(fieldErrors[name] && 'has-error', 'input__container margin__bottom--double')}
        style={{ marginBottom: '30px', position: 'relative' }}
      >
        <label className='input__label details__header'>{label}</label>
        <div className='input__control margin__top--double'>
          {surveyOptions.map((option, ix) => (
            <div key={ix} className='input__container--radio'>
              <input type='radio' name={name} className='input__control' onChange={() => handleChange(name)(option)} />
              <label className='input__label details__header'>{option}</label>
            </div>
          ))}
        </div>
        <span className='input__validation'>{fieldErrors[name]}</span>
      </div>
    ))
  }

  const renderForm = () => (
    <div style={{ padding: 30 }}>
      <div>
        <h2 className='heading__customer--register'>
          <span className='heading__icon--register'></span>Almost done. Finish setting up your MyBosch account
        </h2>
        <p>
          If you are creating your account and have recently submitted an enquiry or order through our Find a Boiler,
          Find an Installer or FastTrack Installation services please ensure you use the same details as you did on your
          enquiry so that we can match up your details.
        </p>
      </div>
      <div>
        <h2 className='heading__customer--subheading'>
          <span className='heading__icon--details'></span>Your details
        </h2>
        <div className='form-group'>
          <div className='consumer-register__row--three'>
            <SelectOption
              label='Title'
              value={state.title}
              onChange={handleChange('title')}
              options={titleOptions}
              error={fieldErrors.title}
            />

            <InputText
              value={state.firstName}
              onChange={handleChange('firstName')}
              label='First name'
              error={fieldErrors.firstName}
            />

            <InputText
              value={state.lastName}
              onChange={handleChange('lastName')}
              label='Last name'
              error={fieldErrors.lastName}
            />
          </div>

          <div className='consumer-register__row--two'>
            <InputText
              value={state.telephoneNumber}
              onChange={handleChange('telephoneNumber')}
              label='Mobile number'
              error={fieldErrors.telephoneNumber}
              placeholder='01234567890'
            />

            <div className='input__container--text input__container--status'>
              <label className='input__label details__header'>Residential status</label>
              <div className='input__control input--opacity'>
                {statusOptions.map((option, ix) => (
                  <div key={ix} className='input__container--radio'>
                    <input
                      type='radio'
                      name='residentialStatus'
                      className='input__control'
                      onChange={() => handleChange('residentialStatus')(option)}
                      checked={option === state.residentialStatus}
                    />
                    <label className='input__label details__header'>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className={cx(
              fieldErrors.primaryAddress?.address && 'has-error',
              'input__container--text input__container--address',
            )}
          >
            <label className='input__label details__header'>Your primary address</label>
            <CapturePlusInput
              className='input__control'
              placeholder='Start typing your address'
              value={state.primaryAddress.value}
              onChange={(address, value) => handleChange('primaryAddress')({ value, address })}
            />
            <span className='input__validation'>
              {fieldErrors.primaryAddress?.address
                ? 'Some address fields are missing, please enter your address manually'
                : ''}
            </span>
          </div>
          {addressFallback ? (
            <AddressFallbackInput
              address={state.primaryAddress?.address}
              onChange={handleChangeAddress}
              errors={fieldErrors.primaryAddress?.address}
            />
          ) : (
            <a className='input__link' onClick={() => setAddressFallback(true)}>
              Can&apos;t find your address? Enter your address details manually.
            </a>
          )}
          <p className='input__help-text'>
            This is the main address where you reside and can be contacted at. If you have multiple properties you will
            be able to add these later from the My Account area of the MyBosch portal.
          </p>
        </div>
      </div>
      <div>
        <h2 className='heading__customer--subheading'>
          <span className='heading__icon--privacy'></span>Your privacy and consent
        </h2>
        <p className='input__help-text--large'>
          From time to time we would like to contact you with offers we think you will be interested in, and also
          surveys and market research to help improve our services. Are you happy to receive these emails?
        </p>
        {renderConsents()}
        <div
          className='margin__top--double margin__bottom--double'
          dangerouslySetInnerHTML={{ __html: consent.text }}
        ></div>
        <div
          className={cx(fieldErrors.acceptTc && 'has-error', 'input__container--checkbox', 'margin__bottom--double')}
        >
          <input
            className='input__control'
            name='acceptTc'
            type='checkbox'
            checked={state.acceptTc}
            value={state.acceptTc}
            onChange={() => {
              handleChange('acceptTc')(!state.acceptTc)
            }}
          />
          <label className='input__label details__header'></label>
          <span className='input__validation'>{fieldErrors.acceptTc}</span>
        </div>
        <div style={{ display: 'inline' }}>
          <label className='input__label details__header'>
            I accept the{' '}
            <a href='/terms' target='_blank' rel='noopener noreferrer'>
              terms and conditions
            </a>{' '}
            of the MyBosch portal
          </label>
        </div>
      </div>
      <div className='text__align--right'>
        <button className='button__primary' onClick={handleSubmit}>
          Submit your details
        </button>
      </div>
    </div>
  )

  const renderFormV2 = () => (
    <div className='phase-two'>
      <div>
        <h2 className='heading__customer--register'>
          <span className='heading__icon--register'></span>About you
        </h2>
        <p>Fields marked with an asterisk (*) are mandatory</p>
        { wasStpNumberTransferred ? (
          <p style={{ fontWeight: 'bold', color: 'red' }}>Your account was automatically closed. Please go through the registration process below.</p>)
          : ''
        }
      </div>
      <div className='consumer-data'>
        <div className='form-group'>
          <div
            className='consumer-register__row'
            style={{ marginBottom: '15px', maxWidth: '200px', position: 'relative' }}
          >
            <SelectOption
              label='Title *'
              value={state.title}
              onChange={handleChange('title')}
              options={titleOptions}
              error={fieldErrors.title}
            />
          </div>

          <div className='consumer-register__row--two'>
            <InputText
              value={state.firstName}
              onChange={handleChange('firstName')}
              label='First name *'
              error={fieldErrors.firstName}
            />

            <InputText
              value={state.lastName}
              onChange={handleChange('lastName')}
              label='Last name *'
              error={fieldErrors.lastName}
            />
          </div>

          <div className='consumer-register__row--two'>
            <InputText
              value={state.telephoneNumber}
              onChange={handleChange('telephoneNumber')}
              label='Mobile number *'
              error={fieldErrors.telephoneNumber}
              placeholder='01234567890'
            />

            <InputText
              value={state.alternativePhoneNumber}
              onChange={handleChange('alternativePhoneNumber')}
              label='Alternative number'
              error={fieldErrors.alternativePhoneNumber}
              placeholder='01234567890'
            />
          </div>

          <div className='consumer-register__row margin__bottom--double'>
            <div
              className={cx(
                fieldErrors.primaryAddress?.address && 'has-error',
                'input__container--text input__container--address',
              )}
            >
              <label className='input__label details__header'>Your address *</label>
              <CapturePlusInput
                className='input__control'
                placeholder='Start typing your address'
                value={state.primaryAddress.value}
                onChange={(address, value) => handleChange('primaryAddress')({ value, address })}
              />
              <span className='input__validation'>
                {fieldErrors.primaryAddress?.address
                  ? 'Some address fields are missing, please enter your address manually'
                  : ''}
              </span>
            </div>
            {addressFallback ? (
              <AddressFallbackInput
                address={state.primaryAddress?.address}
                onChange={handleChangeAddress}
                errors={fieldErrors.primaryAddress?.address}
              />
            ) : (
              <a className='input__link' onClick={() => setAddressFallback(true)}>
                Can&apos;t find your address? Enter your address details manually.
              </a>
            )}
            <p className='details__header'>
              This is the address where your product is or will be installed. Multiple addresses can be added later,
              within the &apos;My Account&apos; section.
            </p>
            <br />
          </div>
        </div>

        <div className='consumer-register__row margin__top--double'>
          <div
            className={cx(fieldErrors.hasExistingProduct && 'has-error', 'input__container margin__bottom--double')}
            style={{ marginBottom: '30px', position: 'relative' }}
          >
            <label className='input__label details__header'>
              Do you already have a Worcester Bosch product installed at this address? *
            </label>
            <div className='input__control margin__top--double'>
              {surveyOptions.map((option, ix) => (
                <div key={ix} className='input__container--radio'>
                  <input
                    type='radio'
                    name={'hasExistingProduct'}
                    className='input__control'
                    onChange={() => handleChange('hasExistingProduct')(option)}
                  />
                  <label className='input__label details__header'>{option}</label>
                </div>
              ))}
            </div>
            <span className='input__validation'>{fieldErrors.hasExistingProduct}</span>
          </div>
        </div>

        <div className='consumer-register__row'>
          <div className='input__container--status'>
            <label className='input__label details__header'>At this address, are you the: *</label>
            <div className='input__control margin__top--double'>
              {statusOptionsV2.map((option, ix) => (
                <div key={ix} className='input__container--radio margin__bottom--double'>
                  <input
                    type='radio'
                    name='residentialStatus'
                    className='input__control'
                    onChange={() => handleChange('residentialStatus')(option)}
                    checked={option === state.residentialStatus}
                  />
                  <label className='input__label details__header'>{`${option} ${
                    option === 'Other' ? '(e.g Carer or power of attorney)' : ''
                  }`}</label>
                </div>
              ))}
            </div>
          </div>
          {state.residentialStatus === 'Other' && (
            <div className='container__verify-reg'>
              <div className='verify-reg__warning'>
                <img src='img/customer-loyalty/journey/alert-warning.svg' className='exclamation-triangle' />
              </div>
              <div className='verify-reg__button-wrapper'>
                <span className='margin__bottom--double'>
                  Please note: if you need to access an account on behalf of someone who has passed away, if you hold
                  power of attorney or for any other reasons, please contact us:
                </span>
                <a className='verify-reg__chat' href='javascript:void(0);'>
                  {<Genesys />}
                </a>
                <a className='verify-reg__phone' href='tel:+441234567890'>
                  <img src='/img/icons/phone.png' />
                  <span>01234567890</span>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='privacy-data'>
        <h2 className='heading__customer--subheading'>
          <span className='heading__icon--privacy'></span>Your privacy and consent
        </h2>
        <p className='input__help-text--large'>
          From time to time we would like to contact you with offers we think you will be interested in, and also
          surveys and market research to help improve our services. Are you happy to receive these emails?
        </p>
        {renderConsents()}
        <div
          className='margin__top--double margin__bottom--double'
          dangerouslySetInnerHTML={{ __html: consent.text }}
        ></div>
        <div
          className={cx(fieldErrors.acceptTc && 'has-error', 'input__container--checkbox', 'margin__bottom--double')}
        >
          <input
            className='input__control'
            name='acceptTc'
            type='checkbox'
            checked={state.acceptTc}
            value={state.acceptTc}
            onChange={() => {
              handleChange('acceptTc')(!state.acceptTc)
            }}
          />
          <label className='input__label details__header'></label>
          <span className='input__validation'>{fieldErrors.acceptTc}</span>
        </div>
        <div style={{ display: 'inline' }}>
          <label className='input__label details__header'>
            I accept the{' '}
            <a href='/terms' target='_blank' rel='noopener noreferrer'>
              terms and conditions
            </a>{' '}
            of the MyBosch portal
          </label>
        </div>
      </div>

      <div className='text__align--right'>
        <button className='button__primary' onClick={handleSubmit}>
          Submit your details
        </button>
      </div>
      {<WelcomeModal show={showWelcomeModal} action={goToSuccessUrl} />}
      {loading && <LoadingOverlay spinner={true} description='Finalising Your Registration' />}
    </div>
  )

  return isPhaseTwo ? renderFormV2() : renderForm()
}

export default RegistrationForm
