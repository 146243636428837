import React, { useState } from 'react'
import { trackEvent } from '../analytics'
import AddProductModal from './AddProductModal'
import ProductNotFoundModal from './ProductNotFoundModal'

const AddProduct = () => {
  const [showModal, setShowModal] = useState(false)
  const [showNotFoundModal, setNotFoundShowModal] = useState(false)
  return (
    <div className='missing'>
      <div className='missing__box'>
        <p className='missing__title'>Missing a product? Add it manually</p>
        <p className='missing__description'>
          When you register for or log in to your MyBosch account we automatically check for new products registered in
          your name. In some cases it may not be possible to automatically match a guarantee to your account, if for
          example some details do not match. If you have your products serial number available you can add this product
          manually.
        </p>
        <button
          className='button button__primary'
          onClick={() => {
            setShowModal(true)
            trackEvent('modal-open', 'Add product')
          }}
        >
          Add a product
        </button>
      </div>

      {showModal && (
        <AddProductModal
          hide={() => setShowModal(false)}
          onError={() => setNotFoundShowModal(true)}
        />
      )}

      {showNotFoundModal && <ProductNotFoundModal hide={() => setNotFoundShowModal(false)} />}
    </div>
  )
}

export default AddProduct
