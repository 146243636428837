import React from 'react'

const Header = () => {
  return (
    <header className="header__customer--orders">
      <h1 className="heading__customer--orders">
        <span className="heading__icon--orders"></span>
        My Orders &amp; Enquiries
      </h1>
      <p className="text__paragraph--orders!cp">
        Below are the details of your engineer appointment. You may cancel or rebook this at anytime, before the day we are due to attend.
        <br />
        On the day of your scheduled appointment you will be able to view your appointment status here.
      </p>
    </header>
  )
}

export default Header
