import Presenter from '../../../shared/network/presenters/Presenter'
import { AddressPresenter } from './AddressPresenter'
import { FilePresenter } from './FilePresenter'

export class ConsumerAccountEnquiryPresenter extends Presenter {
  relationships () {
    return {
      address: AddressPresenter,
      enquiryFile: FilePresenter,
    }
  }
}
ConsumerAccountEnquiryPresenter.prototype.type = 'consumerAccountEnquiry'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(ConsumerAccountEnquiryPresenter, Presenter)

export default data => ConsumerAccountEnquiryPresenter.render(data)
