import React, {useMemo, useState} from 'react'
import cx from 'classnames'
import addressToString from '../../../shared/util/addressToString'
import {objectToCamel} from '../../../fast-track/util/cases'
import toArray from '../../utils/toArray'
import chunkArray from '../../utils/chunkArray'
import Appointment from './Appointment'
import FaiOrFab from './FaiOrFab'
import Fasttrack from './Fasttrack'
import Pagination from './Pagination'
import RectificationNeededModal from '../RectificationNeededModal'
import Tabs from './Tabs'

const CHUNK_SIZE = 2

const splitFasttrackOrdersAndEngineerEnquiries = items =>
  items.reduce(
    ([fasttrackOrders, engineerEnquiries], item) => {
      return item.type.includes('fast-track')
        ? [[...fasttrackOrders, item], engineerEnquiries]
        : [fasttrackOrders, [...engineerEnquiries, item]]
    },
    [[], []],
  )

const WithPaginatedAccordion = ({children, description, iconSrc, title}) => {
  const items = [...children]
  const firstItem = items.shift()
  const paginatedItems = useMemo(() => chunkArray(items, CHUNK_SIZE), [children])
  const [page, setPage] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='band__body--cp'>
      {firstItem}
      {paginatedItems.length > 0 && (
        <div className={cx(['accordion__container--orders', isOpen && 'is-open'])}>
          <div className='accordion__header accordion__header--icon-left' onClick={() => setIsOpen(!isOpen)}>
            <span
              className='accordion__icon'
              style={{backgroundImage: iconSrc, width: '50px', height: '50px'}}
            ></span>
            <div>
              <h2 className='heading heading--h2 mrg--x0'>{title}</h2>
              {description && <p className='accordion__description'>{description}</p>}
            </div>
            <span className='accordion__chevron'></span>
          </div>
          <div className='accordion__body'>
            {paginatedItems[page]}
            <div className='band__cp--pagination'>
              <Pagination indexPage={page} onChange={p => setPage(p)} totalPages={paginatedItems.length}/>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const FastTrackOrders = ({items}) => (
  <WithPaginatedAccordion iconSrc='url("/img/icons/icon-previous-orders.svg")' title='Your previous FastTrack orders'>
    {items.map((item, index) => (
      <Fasttrack key={index} {...item} />
    ))}
  </WithPaginatedAccordion>
)

const IndependentEngineerEnquiries = ({items}) => (
  <WithPaginatedAccordion iconSrc='url("/img/icons/icon-previous-orders.svg")' title='Your previous Engineer enquiries'>
    {items.map((item, index) => (
      <FaiOrFab key={index} {...item} />
    ))}
  </WithPaginatedAccordion>
)

const RequestNewAppointment = ({options, repairRoute, serviceRoute}) => {
  if (options.length === 0) return null

  const [id, setId] = useState(options[0].value)
  return (
    <div className='header__customer--orders'>
      <div className='frame frame--shadow'>
        <label className='form__label'>Select property</label>
        <select
          className='form__control'
          onChange={id => setId(options.find(addressID => String(addressID.value) === id.target.value).value)}
        >
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className='matrix matrix--appointment'>
        <div className='matrix__cell'>
          <a href={`${serviceRoute}/${id}`} className='service-frame'>
            <div className='repair__header-icon repair__header-icon--mxw100'>
              <img className='card__customer--icon' src='/img/icons/cog-tools.svg' alt='Request a repair'/>
            </div>
            <h2 className='repair__header-title text--center'>Request a service</h2>
            <p className='repair__header-text text--center'>Request a service for your Worcester Bosch product</p>
          </a>
        </div>
        <div className='matrix__cell'>
          <a href={`${repairRoute}/${id}`} className='service-frame'>
            <div className='repair__header-icon repair__header-icon--mxw100'>
              <img src='/img/icon-repair-150x120.svg' alt=''/>
            </div>
            <h2 className='repair__header-title text--center'>Request a repair</h2>
            <p className='repair__header-text text--center'>Request a repair for your Worcester Bosch product</p>
          </a>
        </div>
      </div>
    </div>
  )
}

const ManageBookedAppointments = ({booked}) => {
  const [firstBooked, ...restOfBooked] = booked
  const NoBookedAppointments = () => (
    <div className='header__customer--orders'>
      <div className='band__cp--orders band__cp--enquiry'>
        <div className='band__body--cp'>
          <div className='sect text--center'>
            <h2 className='heading heading--h2 text--center mrg--b32'>
              You have no current Worcester Bosch appointments
            </h2>
            <img width='136' src='/img/icon-calendar-darkblue-138x121.svg' alt=''/>
            <p className='text text--sm text--center mrg--t32'>
              Once a Worcester engineer has been arranged, you will be able to view the appointment details, rebook the
              date or cancel the appointment.
            </p>
          </div>
        </div>
      </div>
    </div>
  )

  const AppointmentBlock = ({appointment, title}) => (
    <div className='header__customer--orders'>
      <div className='band__cp--orders band__cp--enquiry no-margin'>
        <div className='band__body--cp'>
          <h2 className='tab__customer--header'>{title}</h2>
          <div className='margin-top'>
            <div className='grid__cp--orders'>
              <div className='grid__col--details'>
                <ul className='list__defaulted--cp'>
                  <li className='list__item'>
                    <strong className='list__heading--cp'>Product:</strong>
                    {appointment.product}
                  </li>
                  <li className='list__item'>
                    <strong className='list__heading--cp'>Appointment type:</strong>
                    {appointment.appointment_type}
                  </li>
                </ul>
              </div>
            </div>
            <iframe className='iFrame-standin' src={appointment.iframe_url} title='Iframe embed'/>
          </div>
        </div>
      </div>
    </div>
  )

  const AppointmentLinkBlock = ({appointments, title}) => (
    <div className='band__body--cp'>
      {title && <h2 className='tab__customer--header margin-top'>{title}</h2>}
      {appointments.map((item, index) => (
        <div className='margin-top padding-sm-b' key={index}>
          <div className='grid__cp--orders'>
            <div className='grid__col--details'>
              <ul className='list__defaulted--cp'>
                <li className='list__item width-third vertical-align-top margin-top'>
                  <strong className='list__heading--cp'>Product:</strong>
                  {item.product}
                </li>
                <li className='list__item width-third vertical-align-top margin-top'>
                  <strong className='list__heading--cp'>Appointment type:</strong>
                  {item.appointment_type}
                </li>
                <li className='list__item width-auto vertical-align-top margin-top'>
                  <a
                    href={item.iframe_url}
                    className='button button__primary width-auto'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    View appointment
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <>
      {firstBooked && <AppointmentBlock appointment={firstBooked} title='Your booked Worcester Bosch appointments'/>}
      {restOfBooked.length > 0 && (
        <AppointmentLinkBlock appointments={restOfBooked} title='Your pending Worcester Bosch appointments'/>
      )}
      {!firstBooked && <NoBookedAppointments/>}

    </>
  )
}

const Orders = props => {
  const {customerActions = []} = props

  const items = useMemo(() => toArray(props.values))
  const [fasttrackOrders, engineerEnquires] = splitFasttrackOrdersAndEngineerEnquiries(items)
  const [showActionRequiredModal, setActionRequiredModal] = useState(!!customerActions.length)

  const guaranteesWithProducts = useMemo(() => {
    if (!props.guarantees) return []
    return props.guarantees.map(guarantee => {
      const camelGuarantee = objectToCamel(guarantee)
      const installationAddress = objectToCamel(camelGuarantee.installationAddress)
      return {
        ...installationAddress,
        fullAddress: addressToString(installationAddress, ', '),
        productName: camelGuarantee.productName,
        consumerServices: camelGuarantee.consumerServices.map(consumerService => ({
          ...objectToCamel(consumerService),
          productName: camelGuarantee.productName,
        })),
      }
    })
  })

  const newApptxAddressOptions = useMemo(() => {
    if (props.addresses === null) return []
    return Object.values(props.addresses).map(address => ({
      label: addressToString(objectToCamel(address)),
      value: address.consumer_guarantee_account_id,
    }))
  }, [])

  const completedApptxAddressOptions = useMemo(() =>
    guaranteesWithProducts.map(guarantee => ({
      label: guarantee.fullAddress,
      value: guarantee.id,
      productName: guarantee.productName
    })),
  )

  const defaultTab = () => {
    switch (true) {
      case fasttrackOrders.length > 0:
        return 'fasttrack-orders'
      case props.appointments.length > 0:
        return 'booked-apptx'
      case newApptxAddressOptions.length > 0:
        return 'new-apptx'
      default:
        return 'booked-apptx'
    }
  }

  return (
    <div className='band-vh'>
      <header className='header__customer--orders'>
        <h1 className='heading__customer--orders'>
          <span className='heading__icon--orders'></span>
          Enquiries &amp; Appointments
        </h1>
        <p className='text__paragraph--orders!cp'>
          Below you can request a service or repair appointment for your products. Please remember to select the
          property where the product is installed to arrange your appointment.
        </p>
      </header>
      <Tabs
        defaultItem={defaultTab()}
        items={[
          {
            altText: 'FastTrack Icon',
            children: <FastTrackOrders items={fasttrackOrders}/>,
            disabled: fasttrackOrders.length === 0,
            iconSrc: '/img/icons/fast-track-logo.png',
            key: 'fasttrack-orders',
            text: 'FastTrack Orders',
          },
          {
            altText: 'Engineer Icon',
            children: <IndependentEngineerEnquiries items={engineerEnquires}/>,
            disabled: engineerEnquires.length === 0,
            iconSrc: '/img/icons/user-repair-purple.svg',
            key: 'engineer-enquiries',
            text: 'Independent engineer enquiries',
          },
          {
            altText: 'Add to Calendar Icon',
            children: (
              <>
                <RequestNewAppointment
                  options={newApptxAddressOptions}
                  repairRoute={props.repairRoute}
                  serviceRoute={props.serviceRoute}
                />
                {showActionRequiredModal && (
                  <RectificationNeededModal
                    customerActions={customerActions}
                    onClose={() => setActionRequiredModal(false)}
                    onSuccess={() => setActionRequiredModal(false)}
                  />
                )}
              </>
            ),
            disabled: newApptxAddressOptions.length === 0,
            iconSrc: '/img/icons/calendar-add.svg',
            key: 'new-apptx',
            text: 'Request a new appointment',
          },
          {
            altText: 'Calendar Icon',
            children: <ManageBookedAppointments booked={props.appointments}/>,
            disabled: false,
            iconSrc: '/img/icons/calendar-blocks.svg',
            key: 'booked-apptx',
            text: 'Manage booked appointments',
          },
          {
            altText: 'Checkmark Icon',
            children: (
              <ViewCompletedAppointments
                addresses={guaranteesWithProducts}
                reportHistoryRoute={props.engineerReportHistoryRoute}
                reportRoute={props.engineerReportRoute}
                options={completedApptxAddressOptions}
              />
            ),
            disabled: guaranteesWithProducts.length === 0,
            iconSrc: '/img/icons/checkmark-circle.svg',
            key: 'completed-apptx',
            text: 'View completed appointments',
          },
        ]}
      />
    </div>
  )
}

const ViewCompletedAppointments = ({addresses, reportHistoryRoute, reportRoute, options}) => {
  const [address, setAddress] = useState(addresses[0])

  const changeProperty = event => {
    const address = addresses.find(address => address.id === parseInt(event.target.value))
    setAddress(address)
  }

  return (
    <div className='header__customer--orders'>
      <div className='sideaction'>
        <div className='frame frame--shadow sideaction__grow'>
          <label className='form__label'>Select property</label>
          <select className='form__control' name='address' id='address-select' onChange={changeProperty}>
            {options.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        {address.consumerServices.length > 0
          ? <a
            className='button button__primary'
            href={`${reportHistoryRoute}/${address.id}`}
            rel='noopener noreferrer'
            target='_blank'
            data-analytics
            data-analytics-category="reports"
            data-analytics-action="download"
            data-analytics-label="downloaded engineer history report"
          >View recent history for this property</a>
          : <button className='button button__primary' disabled>No recent history exists for this property</button>
        }
      </div>

      <Appointment address={address} reportRoute={reportRoute}/>
    </div>
  )
}

export default Orders
