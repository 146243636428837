import React from 'react'
import cx from 'classnames'

const RepairHeader = ({
  action,
  disabled,
  imageSrc,
  isLoading,
  noApptxText,
  noInstallersAvailable,
  text,
  title,
  url,
}) => {
  const isDisabled = disabled || noInstallersAvailable || noApptxText
  return (
    <div
      className={cx('repair__header', isDisabled && 'is-disabled')}
      onClick={action}
      style={{ cursor: 'pointer' }}
      data-analytics
      data-analytics-category='consumer-portal'
      data-analytics-label='Request repair from engineer'
      data-analytics-action='click'
    >
      <a href={url} className='service-frame service-frame--min-height'>
        <div className='repair__header-icon repair__header-icon--mxw100 repair__header-icon--mxh120'>
          <img src={imageSrc} alt='' />
        </div>
        <h2 className='repair__header-title text--center'>{title}</h2>
        <p className='repair__header-text text--center'>{text}</p>
        {!isLoading && noInstallersAvailable && (
          <div className='service-frame__notice'>
            Unfortunately no available engineers could be found in your area. Please arrange your service with an
            accredited engineer.
          </div>
        )}
        {!isLoading && noApptxText && (
          <div className='service-frame__notice'>
            Unfortunately we have no Worcester Bosch appointments available in your area. Please arrange your service
            with an accredited engineer.
          </div>
        )}
      </a>
    </div>
  )
}

export default RepairHeader
