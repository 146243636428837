import React from 'react'

const WelcomeModal = ({ action, show }) => (
  <div className='modal' style={{ display: show ? 'flex' : 'none' }}>
    <div className='modal__container _no-padding'>
      <header className='modal__header'>
        <h2 className='modal__heading--large-white'>Welcome to MyBosch</h2>
        <p className='modal__text--white'>
          Your MyBosch account is now ready to go.<br />We have automatically added in one or more products which we found registered at your address.
        </p>
      </header>
      <section className='modal__body padding-quadruple _no-padding-bot'>
        <div className='modal-card__container flex-row flex-row__justify flex-wrap'>
          <div className='modal-card'>
            <img className='modal-card__image' src='/img/icons/boiler_thick_blue.svg' />
            <span className='modal-card__body'>View all your products in one simple place</span>
          </div>
          <div className='modal-card'>
            <img className='modal-card__image' src='/img/icons/book-info-blue.svg' />
            <span className='modal-card__body'>Access useful information about your products</span>
          </div>
          <div className='modal-card'>
            <img className='modal-card__image' src='/img/icons/user-wrench.svg' />
            <span className='modal-card__body'>Easily request services or repairs</span>
          </div>
          <div className='modal-card'>
            <img className='modal-card__image' src='/img/icons/checkmark.svg' />
            <span className='modal-card__body'>View each product&apos;s guarantee</span>
          </div>
        </div>
        <h3 className='modal__title _sm-padding-top'>Your portal for all your Worcester Bosch products</h3>
        <p>
          Access and view everything you need for peace of mind when it comes to your Worcester products.<br />
          Your MyBosch account enables you to check the guarantee length for all your products,
          access specific help and advice for your appliance, view engineer job reports, easily
          request a service or repair and much more.
        </p>
      </section>
      <footer className='modal__buttons padding-quadruple _no-padding-top _no-margin'>
        <button className='button__primary modal__button modal__button--margin-top' onClick={action}>
          Continue to my account
        </button>
      </footer>
    </div>
  </div>
)

export default WelcomeModal
