import React from 'react'
import PropTypes from 'prop-types'

export default class LoadingOverlay extends React.Component {

  static propTypes = {
    description: PropTypes.string,
    spinner: PropTypes.bool,
    title: PropTypes.string
  }

  static defaultProps = {
    spinner: true
  }

  get showBox () {
    const { description, spinner, title } = this.props
    return description || spinner || title
  }

  renderSpinner () {
    const { spinner } = this.props
    if (!spinner) return null
    return <div className='circle-spinner' style={styles.spinner} />
  }

  renderBox () {
    if (!this.showBox) return null
    const { description, title } = this.props
    return (
      <div className='box' style={styles.box}>
        {this.renderSpinner()}
        {title && <h2>{title}</h2>}
        {description && <p style={styles.description}>{description}</p>}
      </div>
    )
  }

  render () {
    return (
      <div style={styles.container}>
        {this.renderBox()}
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  box: {
    padding: '36px',
    position: 'sticky',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  spinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '12px'
  },
  description: {
    margin: 0
  }
}
