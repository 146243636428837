import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { trackEvent } from '../../analytics'
import { objectToCamel } from '../../../fast-track/util/cases'
import { distanceInMiles } from '../../utils/calculateDistanceByLatLon'
import requestInstallersRequest from '../../utils/requestInstallers'
import AddressSelector from '../AddressSelector'
import ListAllEngineers from './ListAllEngineers'
import LoadingOverlay from '../../../shared/components/LoadingOverlay'
import SalesLeadModal from '../SalesLeadModal'
import ServiceHeader from './RepairHeader'
import ServiceWithOriginalInstaller from './ServiceWithOriginalInstaller'
import ServiceWithLocalEngineer from './ServiceWithLocalEngineer'
import ServiceWithWB from './ServiceWithWB'
import SuccessModal from '../SuccessModal'

export const DISPLAY_TOP_RESULTS_LIMIT = 3
export const ENGINEER_CONTACT_LIMIT = 5

export const calculateInstallerDistanceUsingGuaranteeData = guarantee => {
  const consumer = guarantee.address
  const installer = guarantee.installedByCompany.address
  const distance = distanceInMiles(consumer.latitude, consumer.longitude, installer.latitude, installer.longitude)
  return distance
}

/**
 * Prettifies the installedOn date from a guarantee
 * @param {string} dateString expected to be in the format of "21/03/2021"
 * @returns {string} formatted date string in the format of "21st Mar 2021"
 */
export const formatInstalledDate = dateString => {
  const [day, month, year] = dateString.split('/')
  const validDateString = [year, month, day].join('-')
  return moment(validDateString).format('Do MMMM YYYY')
}

const RequestService = ({
  accountRoute,
  guarantees,
  installersRoute,
  returnRoute,
  route,
  servicePrice,
  user,
}) => {
  const findDefaultAddress = () => {
    // Set default address via window location on mount
    const consumerGuaranteeAccountId = parseInt(window.location.pathname.split('/').pop())
    const guarantee = guarantees.find(guarantee => guarantee.id === consumerGuaranteeAccountId)
    return guarantee || guarantees[0]
  }

  const camelUser = objectToCamel(user)
  const [faiRequestStatus, setFaiRequestStatus] = useState(null)
  const [guaranteeWithAddress, setGuaranteeWithAddress] = useState(findDefaultAddress())
  const [installers, setInstallers] = useState([])
  const [selectedInstallers, setSelectedInstallers] = useState([])
  const [showAllEngineers, setShowAllEngineers] = useState(false)
  const [showSalesLeadModal, setSalesLeadModal] = useState(false)
  const [showSuccessModal, setSuccessModal] = useState(false)
  const [totalInstallersRetrievable, setTotalInstallersRetrievable] = useState(false)

  const addressOptions = useMemo(
    () =>
      guarantees.map(guarantee => ({
        label: `${guarantee.installedAt} - ${guarantee.boilerName}`,
        value: guarantee.id,
      })),
    [],
  )

  const createOriginalInstallerObject = useMemo(
  // eslint-disable-next-line
    () => () => {
      if (!guaranteeWithAddress.installedByCompany) {
        return null
      }
      return objectToCamel({
        ...guaranteeWithAddress.installedByCompany,
        distance: calculateInstallerDistanceUsingGuaranteeData(guaranteeWithAddress),
        installedOn: formatInstalledDate(guaranteeWithAddress.installedOn),
      })
    },

    [guaranteeWithAddress],
  )

  const requestInstallers = (fresh = false) => {
    setFaiRequestStatus(fresh ? 'Searching for available engineers in your area' : 'Finding more engineers')
    requestInstallersRequest({ ...guaranteeWithAddress.address, boilerType: guaranteeWithAddress.boilerType, fresh, currentInstallers: installers })
      .then(result => {
        setInstallers(result.installers)
        setTotalInstallersRetrievable(result.total)
        setFaiRequestStatus(null)
      })
      .catch(error => {
        setFaiRequestStatus(null)
        console.error(error)
      })
  }

  const toggleSelectedInstallers = (installer, append = false, reset = false) => {
    let newInstallers = [...selectedInstallers]
    if (reset) {
      newInstallers.length = 0
    }

    if (append) {
      const itemAlreadyAdded = newInstallers.find(item => item.companyId === installer.companyId)
      newInstallers.push(installer)
      const limitReached = newInstallers.length > ENGINEER_CONTACT_LIMIT
      if (!limitReached && !itemAlreadyAdded) {
        setSelectedInstallers(newInstallers)
      }
      return
    }

    newInstallers = newInstallers.filter(selectedInstaller => selectedInstaller.companyId !== installer.companyId)
    newInstallers.length === 0 && setSalesLeadModal(false)
    setSelectedInstallers(newInstallers)
  }

  const toggleShowAllEngineers = toggle => {
    typeof toggle !== 'undefined' ? setShowAllEngineers(toggle) : setShowAllEngineers(prev => !prev)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    setSelectedInstallers([])
    requestInstallers(true)
    // update the url with the current guarantee id so we can auto-populate the property selector
    window.history.pushState(null, document.title, `${route}/${guaranteeWithAddress.id}`)
  }, [guaranteeWithAddress])

  return (
    <>
      {faiRequestStatus && <LoadingOverlay spinner={true} description={faiRequestStatus} />}

      {showAllEngineers ? (
        <div className='repair'>
          <ListAllEngineers
            installers={installers}
            installersRoute={installersRoute}
            limit={ENGINEER_CONTACT_LIMIT}
            disableLoadMore={installers.length >= totalInstallersRetrievable}
            onLoadMore={() => requestInstallers()}
            openSalesLead={() => {
              trackEvent('modal-open', 'service sales lead')
              setSalesLeadModal(true)
            }}
            originalInstaller={createOriginalInstallerObject()}
            selectedInstallers={selectedInstallers}
            toggleInstaller={toggleSelectedInstallers}
            toggleShowMore={() => toggleShowAllEngineers()}
          />
        </div>
      ) : (
        <>
          <a href={returnRoute} className='plaintext-link plaintext-link--back'>
            Back
          </a>
          <header>
            <h1 className='heading__products'>
              <span className='heading__icon--boiler'></span> Request a service
            </h1>
            <p className='text--p text--sm'>
              A service helps your appliance perform at its best capability and reduces the chances of unexpected
              breakdowns.
              <br />
              Please select your preferred option below.
            </p>
          </header>
          <AddressSelector
            defaultValue={guaranteeWithAddress.id}
            onSelect={id => setGuaranteeWithAddress(guarantees.find(guarantee => String(guarantee.id) === id))}
            options={addressOptions}
          />
          <div className='repair'>
            <ServiceHeader
              action={() => toggleShowAllEngineers(true)}
              disabled={installers.length === 0}
              imageSrc='/img/icon-repair-150x120.svg'
              isLoading={!!faiRequestStatus}
              noInstallersAvailable={guaranteeWithAddress.installedByCompany && installers.length === 0}
              title='Request a service from your original engineer'
              text='The engineer who originally installed your boiler will always be best placed to understand your needs. They’ll be familiar with your setup and able to provide any of the additional services you may want, which Worcester Bosch cannot – whether it’s fitting a new radiator or installing an outside tap.'
            />
            {guaranteeWithAddress.installedByCompany ? (
              <ServiceWithOriginalInstaller
                installer={createOriginalInstallerObject()}
                installersRoute={installersRoute}
                openSalesLead={() => {
                  trackEvent('modal-open', 'service sales lead')
                  setSalesLeadModal(true)
                }}
                showCtas={guaranteeWithAddress.installedByCompany.isOnFai}
                action={() => toggleShowAllEngineers(true)}
                toggleInstaller={toggleSelectedInstallers}
              />
            ) : installers.length > 0 ? (
              <ServiceWithLocalEngineer
                installers={installers}
                limit={DISPLAY_TOP_RESULTS_LIMIT}
                installersRoute={installersRoute}
                openSalesLead={() => {
                  trackEvent('modal-open', 'service sales lead')
                  setSalesLeadModal(true)
                }}
                selectedInstallers={selectedInstallers}
                showMoreEngineers={() => toggleShowAllEngineers(true)}
                toggleInstaller={toggleSelectedInstallers}
              />
            ) : (
              <div className='repair__data'>
                <div className='sideaction sideaction--iconleft'>
                  <img src='/img/icon-calendar-darkblue-48x48.svg' alt='' />
                  <div>
                    <big className='sideaction__info'>
                      Unfortunately no available engineers could be found in your area. Please arrange your service with
                      a Worcester Bosch engineer.
                    </big>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ServiceWithWB
            servicePrice={servicePrice}
            url={`${route}/${guaranteeWithAddress.id}/create`}
          />
        </>
      )}

      {showSalesLeadModal && (
        <SalesLeadModal
          accountRoute={accountRoute}
          guarantee={guaranteeWithAddress}
          installers={selectedInstallers}
          onClose={() => setSalesLeadModal(false)}
          removeInstaller={installer => toggleSelectedInstallers(installer, false)}
          onSuccess={() => {
            setSalesLeadModal(false)
            setSuccessModal(true)
            trackEvent('submit', 'request service')
          }}
          type='Boiler Service'
          user={camelUser}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          ctaUrl='/'
          ctaText='Back to dashboard'
          text='Thank you for submitting your installer enquiry. We will contact you once one of your chosen installers has accepted your enquiry.'
          title='Thank you for submitting your enquiry'
        />
      )}
    </>
  )
}

export default RequestService
