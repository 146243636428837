import { CONSENT_TYPES } from '../consts'

const toOptions = array =>
  array.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: curr,
    }),
    {},
  )

export default data => {
  const details = [
    {
      label: 'Title',
      name: 'title',
      value: data.title,
      type: 'select',
      options: toOptions(data.titleOptions),
    },
    {
      label: 'First name',
      name: 'firstName',
      value: data.firstName || '',
      type: 'text',
    },
    {
      label: 'Last name',
      name: 'lastName',
      value: data.lastName || '',
      type: 'text',
    },
    {
      label: 'Telephone number',
      name: 'telephone',
      value: data.telephoneNumber,
      type: 'text',
      helpText: 'This is the number we will contact you with',
    },
    {
      label: 'Email address',
      name: 'emailAddress',
      value: data.emailAddress,
      type: 'text',
      helpText: 'Your email address is determined by your SingleKey ID and cannot be edited',
      disabled: true,
    },
    {
      label: 'Status',
      name: 'status',
      value: data.status,
      type: 'radio',
      options: toOptions(data.statusOptions),
    },
  ]

  const consents = [
    {
      ...CONSENT_TYPES.productsAndServices,
      value: data.productsAndServices,
      type: 'radio',
      options: toOptions(data.consentOptions),
    },
    {
      ...CONSENT_TYPES.otherGroups,
      value: data.otherGroups,
      type: 'radio',
      options: toOptions(data.consentOptions),
    },
    {
      ...CONSENT_TYPES.thirdParty,
      value: data.thirdParty,
      type: 'radio',
      options: toOptions(data.consentOptions),
    },
    {
      ...CONSENT_TYPES.surveysAndResearch,
      value: data.surveysAndResearch,
      type: 'radio',
      options: toOptions(data.consentOptions),
    },
  ]

  return {
    details,
    consents
  }
}
