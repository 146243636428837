import React from 'react'

const RejectedEnquiryModal = ({ hide }) => {
  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>Why was my enquiry not accepted?</div>
        <div className='modal__body'>
          <p>
            All of our Worcester Accredited Installers are trained and approved by Worcester Bosch to uphold our high
            standards of customer service and quality. However, on some occasions they may unfortunately be unavailable
            to assist with your enquiry. This can be for many reasons, including but not limited to:
          </p>
          <ul>
            <li>Busy work schedules</li>
            <li>Business shutdowns or holidays</li>
            <li>Unable to provide the services or products you require</li>
          </ul>
          <p>
            Please bear in mind that we work with many installers, both big and small, and some of our Accredited
            Installers are independent tradespeople or may have a small team, which may affect how many jobs they can
            undertake at one time.
          </p>
        </div>
        <div className='modal__buttons'>
          <button className='button__primary modal__button' onClick={hide}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default RejectedEnquiryModal
