import { ORDER_JOURNEY_LIST, JOURNEY_STATE, JOURNEY_STATUS, SURVEY_JOURNEY_LIST } from '../consts'

const buildJourney = (current, type = 'fast-track-order') => {
  const journeyList = type === 'fast-track-order' ? ORDER_JOURNEY_LIST : SURVEY_JOURNEY_LIST
  if (current === JOURNEY_STATUS.AWAITING) {
    return journeyList.map((journey, ix) => {
      if (ix === 0) {
        return {
          ...journey,
          icon: 'work-order',
          state: JOURNEY_STATE.TODO,
        }
      }

      return {
        ...journey,
        state: JOURNEY_STATE.DISABLED,
      }
    })
  }

  const currentIx = journeyList.findIndex(x => x.name === current)
  return journeyList.map((journey, ix) => {
    if (currentIx >= ix) {
      return {
        ...journey,
        icon: 'tick',
        state: JOURNEY_STATE.COMPLETED,
      }
    } else if (currentIx + 1 === ix) {
      return {
        ...journey,
        state: JOURNEY_STATE.TODO,
      }
    } else {
      return {
        ...journey,
        state: JOURNEY_STATE.DISABLED,
      }
    }
  })
}

export default buildJourney
