import React from 'react'

const ReplaceProductModal = ({ hide, routes }) => {
  return (
    <div className='modal'>
      <div className='modal__container modal__container--w1168 modal__container--with-close'>
        <div className='modal__title'>Replace your product</div>
        <div className='modal__body'>
          <div className="modal__replace-product">
            <a href={routes.findAnInstaller}>
              <img src="/img/icon-repair-white.svg" alt="" />
              <h3 className="modal__replace-product-title">Find an Installer</h3>
              <p>Our Find an Installer tool will ask for your address and put you in contact with a local Worcester Accredited Installer</p>
            </a>
            <a href={routes.findABoiler}>
              <img src="/img/icons/boilers-white.svg" alt="" />
              <h3 className="modal__replace-product-title">Find a Boiler</h3>
              <p>Our new boiler replacement tool is designed to help you find the boiler best suited for you and your home. </p>
            </a>
            <a href={routes.fastTrack}>
              <img src="/img/banners/fast-track-logo-white.png" alt="" />
              <h3 className="modal__replace-product-title">FastTrack Installation</h3>
              <p>Get your new Worcester Bosch boiler installed quickly and expertly through our network of Worcester Accredited Installers</p>
            </a>
          </div>
        </div>
        <button className="modal__close" onClick={hide}><span className="screen-reader-text">Close modal</span></button>
      </div>
    </div>
  )
}

export default ReplaceProductModal
