const toOptionsObject = options => {
  if (Array.isArray(options)) {
    return options.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: curr,
      }
    }, {})
  }

  if (options instanceof Object) {
    return options
  }

  console.warn('Invalid options given', options)
  return {}
}
export default toOptionsObject
