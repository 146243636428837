import React, { useMemo } from 'react'
import cx from 'classnames'

const Pagination = props => {
  const { indexPage, onChange, totalPages } = props

  if (totalPages === 0) return null

  const pages = useMemo(() => {
    return Array(totalPages)
      .fill(null)
      .map((_, i) => i + 1)
  }, [totalPages])

  const handlePrevious = () => {
    if (indexPage === 0) return

    onChange(indexPage - 1)
  }

  const handleNext = () => {
    if (indexPage === totalPages - 1) return

    onChange(indexPage + 1)
  }

  return (
    <ul className='pagination__list'>
      <li className='pagination__item'>
        <button onClick={handlePrevious} className='pagination__control'>
          <span className='pagination__prev'>Prev</span>
        </button>
      </li>
      {pages.map((pageNumber, idxPage) => (
        <li key={pageNumber} className='pagination__item'>
          <button
            onClick={() => onChange(idxPage)}
            className={cx('pagination__control', idxPage === indexPage ? 'is-active' : null)}
          >
            {pageNumber}
          </button>
        </li>
      ))}
      <li className='pagination__item'>
        <button onClick={handleNext} className='pagination__control'>
          <span className='pagination__next'>Next</span>
        </button>
      </li>
    </ul>
  )
}

export default Pagination
