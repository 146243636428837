import React, { useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { trackEvent } from '../analytics'
import Carousel from './ProductCarousel'
import Image from './Image'
import ReplaceProductModal from './ReplaceProductModal'
import RemoveProduct from './RemoveProduct'
import SelectOption from './SelectOption'
import RectificationNeededModal from './RectificationNeededModal'

/**
 * Find an address by either it's key or index in an object
 * @param {object} guarantees object of addresses with guarantees in them
 * @param {object} findBy specify what value you're searching by
 * @param {string?} findBy.key the nth address object we want to return
 * @param {number?} findBy.index the nth address object we want to return
 * @returns {Array<[object]>} array of guarantee objects at that address. If no match is made, will return guarantees form the first address
 */
const findGuaranteeAddressBy = (guarantees, { index, key }) => {
  if (key) return guarantees[key]
  return guarantees[Object.keys(guarantees)[index]]
}

const hasMinimumRequiredGuaranteeFields = guarantee =>
  ['email', 'installedBy', 'installedOn', 'phone'].some(key => !!guarantee[key])

const PropertySelector = ({ addresses, onChange }) => {
  const [value, setValue] = useState(addresses[0])

  const handleChange = event => {
    const newValue = event.target.value
    if (!newValue) return event.preventDefault()
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <div className='box select-property'>
      <SelectOption label='Select Property' value={value} onChange={handleChange} options={addresses} />
    </div>
  )
}

const Guarantee = ({ addRemovedProduct, customerActions, guarantee, routes, reasons }) => {
  const [actions, setActions] = useState(customerActions)
  const products = (guarantee.products || []).sort(a => (a.isBoiler ? -1 : 1))
  const [guaranteeToRemoveFrom, setGuaranteeToRemoveFrom] = useState('')
  const [modal, setModal] = useState(false)
  const [rectificationModalActionUrl, setRectificationModalActionUrl] = useState('')
  const [successRemove, setSuccessRemove] = useState(false)

  const handleSuccessRemove = productId => {
    addRemovedProduct(String(productId))
    setSuccessRemove(true)
    trackEvent('submit', 'Product removed from guarantee')
    window.location.reload()
  }

  useEffect(() => {
    if (successRemove) {
      setTimeout(() => {
        setSuccessRemove(false)
      }, 8000)
    }
  }, [successRemove])

  if (products.length === 0) {
    return (
      <div className='guarantee-block guarantee-block--empty'>
        <NoGuarantees />
      </div>
    )
  }

  return (
    <div className='guarantee-block'>
      {rectificationModalActionUrl && (
        <RectificationNeededModal
          customerActions={actions}
          onClose={() => {
            setRectificationModalActionUrl('')
          }}
          onSuccess={() => {
            setRectificationModalActionUrl('')
            setActions([])
            window.location = rectificationModalActionUrl
          }}
        />
      )}
      <p className='text__align--center heading--h3 mrg--tx2 mrg--bx3'>Installed at {guarantee.installedAt}</p>
      <Carousel products={products} />
      <div className='text__align--center text--sm mrg--tx5 mrg--bx5'>
        {!!products.length && (
          <button
            className='link link--underline'
            onClick={() => {
              setGuaranteeToRemoveFrom(guarantee.id)
              trackEvent('modal-open', 'Remove product')
            }}
          >
            I no longer have {products.length === 1 ? 'this item' : 'one or more of these items'}
          </button>
        )}
      </div>
      <RemoveProduct
        hide={() => setGuaranteeToRemoveFrom('')}
        guaranteeId={guaranteeToRemoveFrom}
        guarantee={guarantee}
        reasons={reasons}
        onSuccessRemove={handleSuccessRemove}
      />
      {successRemove && (
        <div className='alert fade' style={{ margin: 8, maxWidth: '100%' }}>
          <div className='alert__icon'>
            <span className='alert__icon--success'></span>
          </div>
          <div className='alert__content'>The product has been successfully removed</div>
        </div>
      )}

      <article>
        <div className='grnt'>
          <div className='grnt__info'>
            <div className='mediacard'>
              <div className='mediacard__img'>
                <img
                  src={`/img/consumer-portal/guarantees-${guarantee.isValid ? 'yes' : 'no'}.svg`}
                  alt=''
                  width='48'
                  height='60'
                />
                {guarantee.guaranteeLength > 0 && (
                  <Image
                    src={`/img/guarantee_${guarantee.guaranteeLength}.png`}
                    className='display--ls-'
                    width='48'
                    height='48'
                  />
                )}
              </div>
              <div className='mediacard__content'>
                <div className='mediacard__content-grid'>
                  <div>
                    <h3 className='grnt__info__title'>{guarantee.expiresAt}</h3>
                  </div>
                  <div>
                    <p className='grnt__info__text'>
                      {guarantee.isValid
                        ? 'Ensure your product is serviced annually to protect your guarantee'
                        : 'To help your appliance perform at its best capability we recommend your products are serviced annually, by a qualified professional.'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {hasMinimumRequiredGuaranteeFields(guarantee) && (
              <div className='mediacard'>
                <div className='mediacard__img'>
                  <img src='/img/consumer-portal/installed.svg' alt='' width='48' height='48' />
                </div>
                <div className='mediacard__content'>
                  <div className='mediacard__content-grid'>
                    <div>
                      <h3 className='grnt__info__title'>
                        Installed on: {guarantee.installedOn}
                        <br />
                        {guarantee.installedBy
                          ? `Installed by: ${guarantee.installedBy}`
                          : ''}
                      </h3>
                    </div>
                    <div>
                      <p className='grnt__info__text'>
                        {guarantee.phone && (
                          <>
                            <a href={`tel:${guarantee.phone}`} className='link link--blue-teal'>
                              <span className='icon icon--phone'></span> {guarantee.phone}
                            </a>
                            <br />
                          </>
                        )}
                        {guarantee.email && (
                          <a href={`mailto:${guarantee.email}`} className='link link--blue-teal'>
                            <span className='icon icon--email'></span> {guarantee.email}
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='grnt__badge display--ls'>
            {!!guarantee.guaranteeLength && guarantee.isContractValid && (
              <Image
                src={`/img/guarantee_${guarantee.guaranteeLength}.png`}
                className='display--ls'
                width='78'
                height='78'
              />
            )}
          </div>
          <div>
            {guarantee.downloadCertificate && guarantee.isValid && (
              <a
                href={guarantee.downloadCertificate}
                download
                className='button button__primary'
                data-analytics
                data-analytics-category='consumer-portal'
                data-analytics-label='Download Guarantee Certificate'
                data-analytics-action='click'
              >
                Download guarantee certificate
              </a>
            )}
            {!guarantee.isValid && (
              <div className='grnt__cta'>
                <a
                  className='button button__primary button--no-txt-wrap'
                  onClick={evt => {
                    evt.preventDefault()
                    setModal(true)
                    trackEvent('modal-open', 'Why enquiry rejected')
                  }}
                >
                  Looking for a new boiler?
                </a>
                {modal && <ReplaceProductModal hide={() => setModal(false)} routes={routes} />}
              </div>
            )}
          </div>
        </div>
      </article>
      <footer>
        <section className='button-group'>
          <a href={routes.help} className='button__primary button--no-txt-wrap'
            data-analytics
            data-analytics-category='consumer-portal'
            data-analytics-label='Get Help with a product'
            data-analytics-action='click'>
            Get help with a product
          </a>
          <button
            onClick={() => {
              if (actions.length) {
                setRectificationModalActionUrl(guarantee.serviceRoute)
                return
              }
              window.location = guarantee.serviceRoute
            }}
            className='button__primary button--no-txt-wrap'
          >
            Request a service
          </button>
          <button
            onClick={() => {
              if (actions.length) {
                setRectificationModalActionUrl(guarantee.repairRoute)
                return
              }
              window.location = guarantee.repairRoute
            }}
            className='button__primary button--no-txt-wrap'
          >
            Request a repair
          </button>
          {!guarantee.contractEligibility && (
            <a href={routes.serviceAndMaintenance} className='button__primary button--no-txt-wrap'
              data-analytics
              data-analytics-category='consumer-portal'
              data-analytics-label='View service plans'
              data-analytics-action='click'>
              Service Plans
            </a>
          )}
        </section>
        <section className='sideaction mrg--tx4 mrg--bx4'>
          <p className='text text--p text--sm mrg--x0'>
            Download your recent appointment history to see details of completed services and repairs, carried out on
            your product.
            <br />
            To see individual reports for each specific appointment please{' '}
            <a href={routes.appointments} className='link link--blue-teal'
              data-analytics
              data-analytics-category='consumer-portal'
              data-analytics-label='View orders, enquiries and appointments'
              data-analytics-action='click'>
              visit Orders, Enquiries &amp; Appointments
            </a>
            .
          </p>
          {guarantee.reportRoute && (
            <a
              href={guarantee.reportRoute}
              className='button__primary button--no-txt-wrap'
              data-analytics
              data-analytics-category='consumer-portal'
              data-analytics-label='Product Manuals'
              data-analytics-action='click'
            >
              Download recent appointment history
            </a>
          )}
        </section>
      </footer>
    </div>
  )
}

const NoGuarantees = () => (
  <div className='empty'>
    <p className='empty__title'>You currently have no products</p>
    <img src='/img/consumer-portal/placeholder-boiler.png' className='empty__image' />
  </div>
)

const ProductsV2 = props => {
  const { customerActions = [], routes, reasons } = props
  const [guarantees, setGuarantees] = useState(props.guarantees)
  const [guaranteesAtAddress, setGuaranteesAtAddress] = useState(findGuaranteeAddressBy(guarantees, { index: 0 }))
  const [removedProducts, setRemovedProducts] = useState([])

  const handleAddressChange = key => {
    setGuaranteesAtAddress(findGuaranteeAddressBy(guarantees, { key }))
  }

  const findGuaranteesWithProducts = useMemo(
    () => (guarantees, removedProducts) =>
      Object.entries(guarantees).reduce((accumulator, [address, guarantees]) => {
        const newGuarantees = Object.values(guarantees)
          .map(guarantee => {
            const filteredProducts = guarantee.products.filter(product => !removedProducts.includes(String(product.id)))
            if (!filteredProducts.length) return false
            return {
              ...guarantee,
              products: filteredProducts,
            }
          })
          .filter(Boolean)
        return { ...accumulator, [address]: newGuarantees }
      }, {}),
    [removedProducts],
  )

  const stripRemovedProducts = () => {
    const guaranteesWithProducts = findGuaranteesWithProducts(guarantees, removedProducts)
    setGuarantees(guaranteesWithProducts)
    setGuaranteesAtAddress(findGuaranteeAddressBy(guaranteesWithProducts, { index: 0 }))
  }

  useEffect(stripRemovedProducts, [removedProducts])

  return (
    <div className='my-products'>
      <header className='container__wai--jobs'>
        <div className=' header__wai--jobs'>
          <h1 className='heading__products'>
            <span className='heading__icon--boiler'></span>
            My Products
          </h1>
        </div>
      </header>
      {isEmpty(guarantees) ? (
        <NoGuarantees />
      ) : (
        <div className='guarantee-selector'>
          {Object.keys(guarantees).length > 1 && (
            <PropertySelector addresses={Object.keys(guarantees)} onChange={handleAddressChange} />
          )}
          {guaranteesAtAddress.map(guarantee => (
            <Guarantee
              key={guarantee.id}
              customerActions={customerActions}
              guarantee={guarantee}
              routes={routes}
              reasons={reasons}
              removedProducts={removedProducts}
              addRemovedProduct={productId => setRemovedProducts(state => [...state, productId])}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ProductsV2
