import React, {useState} from 'react'
import { trackEvent } from '../analytics'
import confirmConsent from '../network/requests/confirmConsent'
import deleteAccount from '../network/requests/deleteAccount'

const ConsentModal = ({ confirmed = false, consent, termsRoute }) => {
  const [hasConfirmed, setHasConfirmed] = useState(confirmed)

  const onAgree = () =>
    confirmConsent(consent.revisionId).then(() => {
      setHasConfirmed(true)
      trackEvent('modal', 'accepted consent modal')
    })

  const onDecline = () => {
    deleteAccount().then(() => {
      trackEvent('delete account', 'refused consent modal')
      window.location.href = '/'
    }
    )
  }

  return (
    <>
      {!hasConfirmed && (
        <div tabIndex='-1' className='BoschPrivacySettingsV2__overlay'>
          <div role='dialog' aria-modal='true' className='BoschPrivacySettingsV2__container'>
            <div className='BoschPrivacySettingsV2__header'></div>
            <div role='heading' className='BoschPrivacySettingsV2__title'>
              Terms and Conditions for the use of MyBosch
            </div>

            <div className='BoschPrivacySettingsV2__description'>
              <div className='BoschPrivacySettingsV2__description__paragraph center'>
                <p>I hereby consent to my personal data being transferred to Bosch Thermotechnology Ltd. We will store
                  the information you provide to us. We will only use this information for the purpose of assisting you
                  in your enquiries. We will not disclose your data to third parties. In the course of assisting you we
                  may need to transfer your information outside of the EU. By submitting this form you agree to these
                  terms. Note: Your consent is voluntary and can be revoked at any point in the future by contacting <a
                  href="mailto:privacy.ttgb@bosch.com" title="privacy mailbox" target="_blank"
                  rel="noopener noreferrer">privacy.ttgb@bosch.com</a> as stated in our <a
                  href="https://show-policy.ttprivacy.com/b801db4e-96cb-49f1-9ed2-d977ceb23278" title="privacy policy"
                  target="_blank" rel="noopener noreferrer">privacy policy</a> or by selecting
                  the &apos;Unsubscribe&apos; link on any text message or email that we send you.</p>
                <p>
                  <a
                    rel="noopener noreferrer" target="_blank"
                    className='BoschPrivacySettingsV2__link link'
                    aria-label='More information about marketing cookies'
                    href={termsRoute}
                  >
                    Read more
                  </a>
                </p>
              </div>
            </div>
            <div className='BoschPrivacySettingsV2__buttonContainer BoschPrivacySettingsV2__buttonContainer--fullWidth'>
              <button
                className='BoschPrivacySettingsV2__button BoschPrivacySettingsV2__button--primary'
                onClick={onAgree}
              >
                Agree
              </button>
              <button
                className='BoschPrivacySettingsV2__button BoschPrivacySettingsV2__button--tertiary'
                onClick={onDecline}
              >
                Decline - Please note, by declining the terms and conditions, we will delete your account and you will no longer be able to access it
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConsentModal
