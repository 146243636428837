import { array, boolean, object, number, string } from 'yup'

const REQUIRED = 'Required'

export default object().shape({
  address_id: string().required(REQUIRED),
  consumer_guarantee_account_id: number().required(REQUIRED),
  customer_number: string().nullable(),
  day_availability: array()
    .of(string())
    .min(1)
    .required('You must select at least one day'),
  fault_description: string(),
  nature: string().required(REQUIRED),
  nps_receive_market_research: boolean().required(REQUIRED),
  parking_availability: string().required(REQUIRED),
  serviced: boolean(REQUIRED).required(REQUIRED),
  status: string().required(REQUIRED),
})
