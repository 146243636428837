import React, { useState } from 'react'

const Image = props => {
  const [error, setError] = useState(false)

  if (error) return null

  return <img {...props} onError={() => setError(true)}></img>
}

export default Image
