import React, { useState } from 'react'
import deleteAccount from '../network/requests/deleteAccount'

function CloseMyAccountModal ({ onConfirm, hide }) {
  const [error, setError] = useState('')

  const handleConfirm = () => {
    setError('')
    deleteAccount()
      .then(() => {
        onConfirm()
      })
      .catch(err => {
        setError(err.message)
      })
  }

  return (
    <div className='modal'>
      <div className='modal__container--remove-address'>
        <div className='modal__title'>Close your account</div>
        <div className='modal__body'>
          <p>
            You are about to permanently close your MyBosch account. In doing so you will lose access to the MyBosch portal, as well as details about all of the products and orders associated with your account.
          </p>
          <p>
            Please be aware that closing your MyBosch account will not affect your Bosch ID.
          </p>
          <p>
            Please also note that while we will close and delete your MyBosch account, your details may still be
            recorded on documents such as guarantee records where we are required to store your details to support the
            operation of our business.
          </p>
          {error && <p style={{ color: 'red' }}>An error occured {error}</p>}
        </div>
        <div className='modal__buttons'>
          <button className='button__primary modal__button' onClick={hide}>
            Cancel
          </button>
          <button className='button__secondary--danger modal__button' onClick={handleConfirm}>
            Continue and close account
          </button>
        </div>
      </div>
    </div>
  )
}

export default CloseMyAccountModal
