import { array, number, object, string } from 'yup'
import addressValidator from '../../../registration/validation/schemas/addressValidator'

const MIN_INSTALLER_MESSAGE = 'You must select at least one installer to contact'
const REQUIRED = 'Required'

const formScheme = object().shape({
  address: string().required(REQUIRED),
  companies: array()
    .of(number())
    .min(1, MIN_INSTALLER_MESSAGE),
  emailAddress: string().required(REQUIRED),
  firstName: string().required(REQUIRED),
  installers: array()
    .of(string())
    .min(1, MIN_INSTALLER_MESSAGE),
  lastName: string().required(REQUIRED),
  message: string().required(REQUIRED),
  telephone: string().required(REQUIRED),
})

export default formScheme.concat(addressValidator)
