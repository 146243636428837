import flatten from 'lodash/flatten'
/**
 * Concatenate all the unique products in all of the guarantee objects at this address
 * @param {object} guaranteesAtAddress
 * @returns {object[]} An array of product objects from all guarantees
 */
const findAllUniqueProductsAtAddress = guaranteesAtAddress => {
  const extractGroupedProductsFromGuarantees = Object.values(guaranteesAtAddress)
    .map(guarantee => guarantee.products)
    .filter(Boolean)

  const flatProducts = flatten(extractGroupedProductsFromGuarantees)

  const uniqueProducts = flatProducts.reduce((accumulated, product) => {
    const ids = accumulated.map(product => product.id)
    if (!ids.includes(product.id)) {
      accumulated.push(product)
    }
    return accumulated
  }, [])

  return uniqueProducts
}

export default findAllUniqueProductsAtAddress
