import React from 'react'

const Journey = ({ items }) => {

  return (
    <ul className='journey__container--orders!cp'>
      {items.map((journey, ix) => (
        <li key={ix} className='journey__item'>
          <span className={`journey__badge--${journey.state}`}>
            <span role='presentation' className={`journey__icon--${journey.icon}`}></span>
          </span>
          <span className='journey__title'>{journey.label}</span>
        </li>
      ))}
    </ul>
  )
}

export default Journey
