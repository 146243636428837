import React from 'react'

/**
 * Takes [{label'', value: ''}] and produces an property selector component
 * @param {Object} param0
 * @param {String} param0.defaultValue The value of the default option to preselect, should match the address.value
 * @param {Function} param0.onSelect The action to take, with param0.address.value as the return value
 * @param {Object[]} param0.options Array of {label, value} objects
 * @param {String} param0.options.label The test to display in the selector
 * @param {String} param0.options.value The value to be returned when property has been selected
 * @returns {JSX.Element}
 */
export default function AddressSelector ({ defaultValue, onSelect, options }) {
  const handleOnChange = event => {
    const { value } = event.target
    onSelect(value)
  }

  return (
    <div className='frame frame--shadow mrg--tx3 mrg--bx3'>
      <form action=''>
        <div className='form__group mrg--x0'>
          <label htmlFor='select-property' className='form__label'>
            Select property
          </label>
          <select name='select-property' id='select-property' className='form__control' onChange={handleOnChange} defaultValue={defaultValue}>
            {options.map(({label, value}, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  )
}
