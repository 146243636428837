import React, { useMemo, useState } from 'react'
import buildJourney from '../../utils/buildJourney'
import { JOURNEY_STATUS } from '../../consts'
import Journey from './Journey'
import PhotosModal from './PhotosModal'
import QuoteModal from './QuoteModal'
import { trackEvent } from '../../analytics'

const Fasttrack = props => {
  const {
    type,
    orderNum,
    submittedDate,
    installationAddress,
    requestedInstallationDate,
    package: selectedPackage,
    maxPrice,
    installer,
    status,
    photos,
    quote,
    uploadPhotosUrl,
  } = props

  const [photoModal, setPhotoModal] = useState(false)
  const [quoteModal, setQuoteModal] = useState(false)
  const journey = useMemo(() => buildJourney(status, type), [status, type])

  const header = useMemo(() => {
    const label = {
      'fast-track-survey': 'Survey',
      'fast-track-order': 'Order',
    }

    return `FastTrack ${label[type]} #${orderNum}`
  }, [type, orderNum])

  const handleViewPhotos = e => {
    e.preventDefault()
    setPhotoModal(true)
    trackEvent('modal-open', 'Installation photos')
  }

  const helpText = useMemo(() => {
    switch (status) {
      case JOURNEY_STATUS.AWAITING:
        return 'For us to perform your remote survey, we need you to upload photos of your current installation.'
      case JOURNEY_STATUS.SUBMITTED:
        return 'Once we have completed your survey, we will be in touch to confirm your price and installation date.'
      case JOURNEY_STATUS.INSTALLER_ASSIGNMENT:
        return 'Please confirm you are happy to proceed with the installation'
      case JOURNEY_STATUS.ACCEPT_QUOTE:
        return 'Your installer has confirmed the price of your package and installation. Please check your email inbox and select the link to accept your quote. The order cannot proceed until this quote is accepted'
      case JOURNEY_STATUS.READY:
        return 'Your installation is booked and confirmed. The installer will be in touch to arrange an exact arrival time on your agreed date.'
      default:
        return ''
    }
  }, [status])

  return (
    <div className='band__cp--orders band__cp--enquiry'>
      <div className='band__body--cp'>
        <h2 className='heading__customer--fasttrack'>{header}</h2>
        <div className='grid__cp--orders'>
          <div className='grid__col--details'>
            <ul className='list__columnised--cp'>
              <li className='list__item'>
                <strong className='list__heading--cp'>Order submitted:</strong>
                {submittedDate}
              </li>
              <li className='list__item'>
                <strong className='list__heading--cp'>Installation address:</strong>
                {installationAddress}
              </li>
              <li className='list__item'>
                <strong className='list__heading--cp'>{ status !== JOURNEY_STATUS.ACCEPT_QUOTE && status !== JOURNEY_STATUS.READY ? 'Requested installation date' : 'Installation date' }:</strong>
                {requestedInstallationDate}
              </li>
              <li className='list__item'>
                <strong className='list__heading--cp'>Your installation photos:</strong>
                {photos.length
                  ? <a onClick={handleViewPhotos} href='#'>View your photos</a>
                  : <a href={uploadPhotosUrl}>Upload your photos</a>
                }
              </li>
              {!!selectedPackage && (
                <li className='list__item'>
                  <strong className='list__heading--cp'>Selected package:</strong>
                  {selectedPackage}
                </li>
              )}
              {!!maxPrice && quote && (
                <li className='list__item'>
                  <strong className='list__heading--cp'>Quoted price:</strong>{maxPrice}
                </li>
              )}
              {!!installer && (
                <li className='list__item'>
                  <strong className='list__heading--cp'>Assigned installer:</strong>
                  <span className='text__highlight--cp text__box'>{installer}</span>
                </li>
              )}
            </ul>
          </div>
          <aside className='grid__col--journey'>
            <Journey items={journey} />
            <p className='journey__text--orders!cp'>
              <strong>What next? </strong>
              {helpText}
            </p>
          </aside>
        </div>
        {!!quote && Boolean(quote.showQuoteModal) && (
          <a
            className='button button__primary tracker__alert--button'
            onClick={() => setQuoteModal(true)}
            style={{ marginTop: '20px' }}
            data-analytics
            data-analytics-category='consumer-protal'
            data-analytics-label='Review price and confirm installation modal'
            data-analytics-action='open-modal'
          >
            Review price and confirm installation
          </a>
        )}
      </div>
      {photoModal && <PhotosModal hide={() => setPhotoModal(false)} photos={photos} />}
      {quoteModal && <QuoteModal hide={() => setQuoteModal(false)} quote={quote} />}
    </div>
  )
}

export default Fasttrack
