import React, { useState } from 'react'
import { trackEvent } from '../analytics'
import CloseMyAccountModal from './CloseMyAccountModal'

const SideBoxes = (props) => {
  const { changePasswordLink, contactUsRoute, isPhaseTwoEnabled } = props
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className='box__account'>
        <span role='heading' className='box__title'>
          How do I change my password?
        </span>
        <p className='box__text--small'>
          Your email address and password are now both managed by SingleKey ID, a Bosch managed service that will enable you to login to any Bosch platform using one email address.
        </p>
        <p className='box__text--small'>
          To change your password click on the button below. Please be aware that changing your password will also apply to any other services you log into using your SingleKey ID.
        </p>
        <div className='box__cta'>
          <a
            href={changePasswordLink}
            rel='noopener noreferrer'
            target='_blank'
            className='button button__primary'
            onClick={() => {
              trackEvent('click', 'Change password')
            }}
          >
            Manage SingleKey ID
          </a>
        </div>
      </div>
      {isPhaseTwoEnabled && (
        <div className='box__account'>
          <span role='heading' className='box__title'>
            Contact us
          </span>
          <p className='box__text--small'>
            Do you have a compliment, complaint, or any feedback for us?
          </p>
          <div className='box__cta'>
            <a
              rel='noopener noreferrer'
              target='_blank'
              className='button button__primary'
              href={contactUsRoute}
            >
              Give feedback
            </a>
          </div>
        </div>
      )}
      <div className='box__account'>
        <span role='heading' className='box__title'>
          Close my account
        </span>
        <p className='box__text--small'>
          You can close your MyBosch account at any time. Please be aware that closing your MyBosch account will not
          affect your SingleKey ID.
        </p>
        <p className='box__text--small'>
          Please also note that while we will close and delete your MyBosch account, your details may still be recorded
          on documents such as guarantee records.
        </p>
        <div className='box__cta'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            className='button button__secondary--danger'
            onClick={() => {
              setShowModal(true)
              trackEvent('modal-open', 'Close my account')
            }}
          >
            Close my account
          </a>
        </div>
      </div>
      {showModal && (
        <CloseMyAccountModal
          hide={() => setShowModal(false)}
          onConfirm={() => {
            trackEvent('click', 'Close my account')
            window.location.href = '/'
          }}
        />
      )}
    </>
  )
}
export default SideBoxes
