import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { trackEvent } from '../../analytics'
import { objectToCamel } from '../../../fast-track/util/cases'
import requestInstallersRequest from '../../utils/requestInstallers'
import ListAllEngineers from './ListAllEngineers'
import LoadingOverlay from '../../../shared/components/LoadingOverlay'
import SalesLeadModal from '../SalesLeadModal'
import RepairHeader from './RepairHeader'
import SuccessModal from '../SuccessModal'
import { ENGINEER_CONTACT_LIMIT } from './RequestService'

const RequestRepair = ({
  accountRoute,
  address,
  boilerType,
  createRoute,
  guarantee,
  installersRoute,
  isValid,
  returnRoute,
  user,
  weeksAway,
}) => {
  const showExcelerateRepair = !isValid
  const available = isValid && weeksAway !== false
  const camelUser = objectToCamel(user)
  const [faiRequestStatus, setFaiRequestStatus] = useState(null)
  const [installers, setInstallers] = useState([])
  const [selectedInstallers, setSelectedInstallers] = useState([])
  const [showAllEngineers, setShowAllEngineers] = useState(false)
  const [showSalesLeadModal, setSalesLeadModal] = useState(false)
  const [showSuccessModal, setSuccessModal] = useState(false)
  const [totalInstallersRetrievable, setTotalInstallersRetrievable] = useState(false)

  const requestInstallers = (fresh = false) => {
    setFaiRequestStatus(fresh ? 'Searching for available engineers in your area' : 'Finding more engineers')
    requestInstallersRequest({ ...address, fresh, currentInstallers: installers, boilerType })
      .then(result => {
        setInstallers(result.installers)
        setTotalInstallersRetrievable(result.total)
        setFaiRequestStatus(null)
      })
      .catch(error => {
        setFaiRequestStatus(null)
        console.error(error)
      })
  }

  const toggleShowAllEngineers = toggle => {
    if (typeof toggle !== 'undefined') {
      setShowAllEngineers(toggle)
    } else {
      setShowAllEngineers(prev => !prev)
    }
    window.scrollTo(0, 0)
  }

  const toggleSelectedInstallers = (installer, append = false, reset = false) => {
    let newInstallers = [...selectedInstallers]
    if (reset) {
      newInstallers.length = 0
    }

    if (append) {
      const itemAlreadyAdded = newInstallers.find(item => item.companyId === installer.companyId)
      newInstallers.push(installer)
      const limitReached = newInstallers.length > ENGINEER_CONTACT_LIMIT
      if (!limitReached && !itemAlreadyAdded) {
        setSelectedInstallers(newInstallers)
      }
      return
    }

    newInstallers = newInstallers.filter(selectedInstaller => selectedInstaller.companyId !== installer.companyId)
    newInstallers.length === 0 && setSalesLeadModal(false)
    setSelectedInstallers(newInstallers)
  }

  useEffect(() => {
    requestInstallers(true)
  }, [])

  return (
    <>
      {faiRequestStatus && <LoadingOverlay spinner={true} description={faiRequestStatus} />}

      {showAllEngineers ? (
        <div className='repair'>
          <ListAllEngineers
            installers={installers}
            installersRoute={installersRoute}
            limit={ENGINEER_CONTACT_LIMIT}
            disableLoadMore={installers.length >= totalInstallersRetrievable}
            onLoadMore={() => requestInstallers()}
            openSalesLead={() => {
              trackEvent('modal-open', 'repair sales lead')
              setSalesLeadModal(true)
            }}
            selectedInstallers={selectedInstallers}
            toggleInstaller={toggleSelectedInstallers}
            toggleShowMore={() => toggleShowAllEngineers()}
          />
        </div>
      ) : (
        <>
          <a href={returnRoute} className='plaintext-link plaintext-link--back'>
            Back
          </a>
          <header>
            <h1 className='heading__products'>
              <span className='heading__icon--boiler'></span> Request a Repair
            </h1>
            <p className='text--p text--sm'>
              {showExcelerateRepair
                ? 'You can have your repair completed by one of our local Accredited Installers, or by Worcester Bosch directly. Select your preferred option below.'
                : 'You can have your repair completed by Worcester Bosch directly.'}
            </p>
          </header>
          <div className='repair repair-request'>
            <div className={cx('matrix matrix--appointment', !showExcelerateRepair && 'matrix--center')}>
              {showExcelerateRepair && (
                <div className='matrix__cell'>
                  <RepairHeader
                    action={() => toggleShowAllEngineers(true)}
                    imageSrc='/img/icon-repair-150x120.svg'
                    title='Request Accredited Engineer'
                    text='Worcester Bosch has a network of accredited engineers based across the UK and Ireland. These are local engineers who have been trained to our high standards and specialise in Worcester Bosch products.'
                  />
                </div>
              )}
              <div className='matrix__cell'>
                <RepairHeader
                  disabled={!available}
                  imageSrc='/img/worcester-bosch-logo-stacked.svg'
                  noApptxText={!available}
                  title='Request Worcester Bosch Engineer'
                  text='Book your repair with Worcester Bosch and receive a visit from one of our qualified engineers.'
                  url={createRoute}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {showSalesLeadModal && (
        <SalesLeadModal
          accountRoute={accountRoute}
          guarantee={guarantee}
          installers={selectedInstallers}
          onClose={() => setSalesLeadModal(false)}
          removeInstaller={installer => toggleSelectedInstallers(installer, false)}
          onSuccess={() => {
            setSalesLeadModal(false)
            setSuccessModal(true)
            trackEvent('modal-close', 'submit request repair')
          }}
          type='Boiler repair'
          user={camelUser}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          ctaUrl='/'
          ctaText='Back to dashboard'
          text='Thank you for submitting your installer enquiry. We will contact you once one of your chosen installers has accepted your enquiry.'
          title='Thank you for submitting your enquiry'
        />
      )}
    </>
  )
}

export default RequestRepair
