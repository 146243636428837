import uuid from 'uuid/v4'

export default (primaryAddress, additionalAddresses = []) => {
  const { fullAddress, id, ...restPrimaryAddress } = primaryAddress
  const result = [
    {// This is used as a key for the .map(), it has no meaning for the backend
      clientId: uuid(),
      id: id,
      label: 'Primary address',
      name: 'primaryAddress',
      value: fullAddress,
      helpText: 'This is the address where you mostly live and can be contacted',
      address: restPrimaryAddress,
    },
    ...additionalAddresses.map((address, i) => {
      const { fullAddress, id, ...restAddress } = address
      return {
        clientId: uuid(),
        id: id,
        label: `Additional address ${i + 1}`,
        name: `additionalAddress${i + 1}`,
        value: fullAddress,
        address: restAddress,
      }
    }),
  ]

  return result
}
