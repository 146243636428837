import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import { first, last } from 'lodash/array'

const DISPLAY_QUANTITY = 3

const Slide = ({ isCurrent, product }) => (
  <section className={cx('carousel-slide', isCurrent && 'carousel-slide--current')}>
    <header>
      <h2 className='carousel-slide__title'>{product.name}</h2>
      <p className='carousel-slide__sn'>{product.serialNumber}</p>
    </header>
    <img src={`/img/${product.image}`} alt={product.name} />
  </section>
)

const Carousel = ({ products }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const slides = useMemo(() => (products.length === 1 ? products : [last(products), ...products, first(products)]))

  const checkIsCurrentSlide = index => (products.length === 1 ? index === currentIndex : index === currentIndex + 1)

  const next = () => (currentIndex === products.length - 1 ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1))

  const prev = () => (currentIndex === 0 ? setCurrentIndex(products.length - 1) : setCurrentIndex(currentIndex - 1))

  return (
    <div className='product-carousel-wrap'>
      <div className='product-carousel'>
        <div className='carousel-wrapper'>
          {products.length > 1 && (
            <button onClick={prev} className='left-arrow'>
              <span className='screen-reader-text'>Previous</span>
            </button>
          )}
          <div className='carousel-content-wrapper'>
            <div
              className={`carousel-content show-${DISPLAY_QUANTITY}`}
              style={{
                transform: `translateX(-${currentIndex * (100 / DISPLAY_QUANTITY)}%)`,
              }}
            >
              {slides.map((product, index) => (
                <Slide key={index} isCurrent={checkIsCurrentSlide(index)} product={product} />
              ))}
            </div>
          </div>
          {products.length > 1 && (
            <button onClick={next} className='right-arrow'>
              <span className='screen-reader-text'>Next</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Carousel
