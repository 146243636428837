import React, { useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import uuid from 'uuid/v4'
import { produce } from 'immer'
import addressToString from '../../shared/util/addressToString'
import CapturePlusInput from '../../shared/components/CapturePlusInput'
import RemoveAddressModal from './RemoveAddressModal'
import AddressFallbackInput from './AddressFallbackInput'
import FormRow from '../../shared/components/FormRow/FormRow'
import { isEqual } from 'lodash'

export default function MultipleAddressesV2 (props) {
  const { fields, editable, onChange, onDelete, errors, statusOptions, initialState } = props
  const [addressToRemove, setAddressToRemove] = useState()
  const [manually, setManually] = useState({})

  const handleConfirmRemove = async () => {
    if (addressToRemove.id) {
      onDelete(addressToRemove.id)
    }
    handleRemoveAddress(addressToRemove)
  }

  const handleRemoveAddress = field => {
    const newFields = fields
      .filter(item => item.name !== field.name)
      .map((item, ix) =>
        ix !== 0
          ? {
            ...item,
            label: `Additional address ${ix}`,
            name: `additionalAddress${ix}`,
          }
          : item,
      )
    onChange(newFields)
    setAddressToRemove(undefined)
  }

  const handleAdd = () => {
    const newFields = [...fields, {}].map((field, ix) => ({
      ...field,
      clientId: field.clientId || uuid(),
      label: field.label || `Additional address ${ix}`,
      name: field.name || `additionalAddress${ix}`,
    }))
    onChange(newFields)
  }

  const handleOnChange = (name, address, value) => {
    if (!value) return
    const newFields = produce(fields, draft => {
      const ix = draft.findIndex(x => x.name === name)
      if (draft[ix]) {
        draft[ix].value = value
        draft[ix].address = address
      }
    })
    onChange(newFields)
  }

  const handleAddressChange = (addressClientId, name, value) => {
    const target = fields.find(field => field.clientId === addressClientId)

    // remove udprn and pcaId as manually entering an address will invalidate them
    if (manually[target.clientId] && target.address) {
      delete target.address.udprn
      delete target.address.pcaId
    }

    const newField = {
      ...target,
      address: {
        ...target.address,
        [name]: value,
      },
      value: addressToString({ ...target.address, [name]: value }, ', '),
    }
    onChange(fields.map(field => (field.clientId === addressClientId ? newField : field)))
  }

  useEffect(() => {
    setManually({})
  }, [editable])

  /* const handleSetAsPrimary = field => {
    const primaryAddress = Object.assign({}, fields[0])
    // Swap the selected address with the primary address
    const newFields = fields.map((item, ix) => {
      if (ix === 0) {
        return {
          ...field,
          // Values that shouldn't change for the input
          id: primaryAddress.id,
          label: primaryAddress.label,
          name: primaryAddress.name,
          helpText: primaryAddress.helpText,
        }
      }
      if (item.clientId === field.clientId) {
        return {
          ...primaryAddress,
          // Values that shouldn't change for the input
          id: field.id,
          label: `Additional address ${ix}`,
          name: `additionalAddress${ix}`,
          helpText: '',
        }
      }

      return item
    })

    onChange(newFields, [primaryAddress.id, field.id])
  } */

  const getFieldsErrors = useCallback(
    field => {
      return errors[field.id] || errors[field.clientId]
    },
    [errors],
  )

  const didResidentialStatusChanged = () => {
    return isEqual(fields[0].address.residentialStatus, initialState[0].address.residentialStatus)
  }

  const didPrimaryAddressChanged = () => {
    return isEqual(fields[0].id, initialState[0].id)
  }

  const primaryAddressResidentialStatusEditable = () => {
    return fields[0].address.residentialStatus && didPrimaryAddressChanged() && didResidentialStatusChanged()
  }

  return (
    <div>
      {fields.map((field, ix) => {
        const showStatusPlaceholder = !field?.address?.residentialStatus && !editable

        return (
          <div key={field.clientId} className='details__row--group'>
            <div className='details__row'>
              <div className='details__col--label'>
                <div className='details__header'>{field.label}</div>
                {field.helpText && <div className='details__subheader'>{field.helpText}</div>}
              </div>
              <div className='details__col--value'>
                <div className='details__value details__value--consumer-portal'>
                  {editable && ix !== 0 ? (
                    <>
                      <div className={classnames(getFieldsErrors(field) ? 'has-error' : null)}>
                        <CapturePlusInput
                          hideResultsOnBlur
                          onChange={(address, value) => handleOnChange(field.name, address, value)}
                          value={field.value}
                        />
                        <span className='input__validation'>
                          {getFieldsErrors(field) && !getFieldsErrors(field)?.residentialStatus
                            ? 'Some address fields are missing, please enter your address manually'
                            : ''}
                        </span>
                      </div>
                      {manually[field.clientId] ? (
                        <AddressFallbackInput
                          address={field.address}
                          onChange={(name, value) => handleAddressChange(field.clientId, name, value)}
                          errors={getFieldsErrors(field)}
                          oneLine
                        />
                      ) : (
                        <a className='input__link' onClick={() => setManually({ ...manually, [field.clientId]: true })}>
                          Can&apos;t find your address? Enter your address details manually.
                        </a>
                      )}
                    </>
                  ) : (
                    field.value
                  )}
                </div>
              </div>
            </div>
            <div className={classnames(getFieldsErrors(field)?.residentialStatus ? 'has-error' : null)}>
              <FormRow
                label='Status'
                name={`residentialStatus-${field.clientId}`}
                value={
                  showStatusPlaceholder
                    ? '-- Please set a residential status for this address --'
                    : field?.address?.residentialStatus
                }
                type={showStatusPlaceholder ? 'text' : 'radio'}
                options={statusOptions}
                editable={ ix === 0 && primaryAddressResidentialStatusEditable() ? false : editable}
                onChange={(name, value) => handleAddressChange(field.clientId, 'residentialStatus', value)}
              />
              <p className='input__validation text-center' style={{ position: 'relative' }}>
                {getFieldsErrors(field)?.residentialStatus || ''}
              </p>
            </div>
            {editable && ix !== 0 && (
              <div className='details__row'>
                <div className='details__col--label'></div>
                <div className='details__col--value'>
                  <div className='details__value details__value--consumer-portal'>
                    <button
                      style={{ marginTop: 10, float: 'right' }}
                      onClick={() => (!field.value ? handleRemoveAddress(field) : setAddressToRemove(field))}
                      className='button__secondary details__button'
                    >
                      Remove
                    </button>
                    {/* BOSCH-20832 - Hiding temporary the Set as Primary Button
                     {field.value && (
                      <button
                        style={{ marginTop: 10, float: 'right' }}
                        onClick={() => handleSetAsPrimary(field)}
                        className='button__primary'
                      >
                        Set as primary
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      })}
      {editable && (
        <div style={{ marginTop: '20px' }}>
          <button style={{ float: 'right' }} onClick={handleAdd} className='button__primary'>
            Add
          </button>
        </div>
      )}
      {addressToRemove && (
        <RemoveAddressModal
          hide={() => setAddressToRemove(undefined)}
          address={addressToRemove.value}
          onConfirm={handleConfirmRemove}
        />
      )}
    </div>
  )
}
