import React from 'react'

function ModalBuilder ({ title, body, close, footer, hide }) {
  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>{title}</div>
        <div className='modal__body'>
          <p>{body}</p>
        </div>
        <div className="verify-reg__button-wrapper">
          <button className='button__primary modal__button modal__button--cancel' onClick={hide}>
            {close}
          </button>
        </div>
        {footer}
      </div>
    </div>
  )
}

export default ModalBuilder
