import React, { useState } from 'react'
import cx from 'classnames'
import { trackEvent } from '../analytics'
import appointmentFormSchema from '../validation/schemas/appointmentForm'
import validator from '../validation/validator'
import transformError from '../validation/transformError'
import submitAppointmentForm from '../network/requests/submitAppointmentForm'
import SelectOption from './SelectOption'

const updateIdInUrl = id => {
  const newPath = window.location.pathname.replace(/\d{1,}/, id)
  window.history.pushState(null, document.title, `${window.location.origin}${newPath}`)
}

const PropertySelector = ({ addresses, onChange }) => {
  const [value, setValue] = useState(addresses[0])

  const handleChange = event => {
    const newValue = event.target.value
    if (!newValue) return event.preventDefault()
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <div className='box select-property'>
      <SelectOption label='Select Property' value={value} onChange={handleChange} options={addresses} />
    </div>
  )
}

export default function AppointmentForm ({
  consumerGuarantees,
  repair,
  returnRoute,
  myAccountRoute,
  user,
  weeksAway,
  termsRoute,
}) {
  const availableAddresses = weeksAway === false ? consumerGuarantees : consumerGuarantees.filter(
    address => weeksAway[address.addressId] !== null && weeksAway[address.addressId] !== undefined,
  )
  const [address, setAddress] = useState(availableAddresses[0])
  const [fieldErrors, setFieldErrors] = useState({})
  const [state, setState] = useState({
    address_id: address.addressId,
    consumer_guarantee_account_id: address.id,
    customer_number: '',
    day_availability: ['0', '1', '2', '3', '4'],
    fault_description: '',
    nature: repair ? 'I have an intermittent fault' : 'My product needs a service',
    nps_receive_market_research: false,
    parking_availability: '',
    serviced: '',
    status: 'New',
  })

  const handleAddressChange = key => {
    const newAddress = availableAddresses.find(address => address.fullAddress + ' - ' + address.boilerName === key)
    setAddress(newAddress)
    setState(prev => ({ ...prev, address_id: newAddress.addressId, consumer_guarantee_account_id: newAddress.id }))
    updateIdInUrl(newAddress.id)
  }

  const updateDayAvailability = event => {
    const { value, checked } = event.target
    let days
    if (checked) {
      days = [...state.day_availability, value].sort()
    } else {
      days = state.day_availability.filter(day => day !== value)
    }
    setState(prev => ({ ...prev, day_availability: days }))
  }

  const updateState = (key, value) => {
    setState(prev => ({ ...prev, [key]: value }))
  }

  const handleSubmit = () => {
    setFieldErrors({})
    const payload = { ...state }
    validator(appointmentFormSchema, state)
      .then(() => submitAppointmentForm(payload))
      .then(response => {
        trackEvent('Appointment Form', 'Submitted', 'Success')
        window.location = `/account/products/appointment/${response.data.id}/confirmation`
      })
      .catch(error => {
        setFieldErrors(transformError(error))
      })
  }

  const renderForm = () => (
    <div className='mrg-reset'>
      <h3 className='form__subheading'>Confirm your Details</h3>
      <span className='form__label form__label--left'>
        If any of the details below are incorrect, please change them in <a href={myAccountRoute}>my account</a> page
      </span>
      <br />
      <span className='form__label form__label--left'>
        Please tell us about the nature of the appointment you need:
      </span>
      <label className='form__label form__label--radio'>
        <input
          type='radio'
          name='nature'
          value='I have no heating or hot water'
          onClick={event => updateState('nature', event.target.value)}
        />
        <span>I have no heating or hot water</span>
      </label>
      <label className='form__label form__label--radio'>
        <input
          type='radio'
          name='nature'
          value='I have an intermittent fault'
          defaultChecked={!!repair}
          onClick={event => updateState('nature', event.target.value)}
        />
        <span>I have an intermittent fault</span>
      </label>
      <label className='form__label form__label--radio'>
        <input
          type='radio'
          name='nature'
          value='My product needs a service'
          defaultChecked={!repair}
          onClick={event => updateState('nature', event.target.value)}
        />
        <span>My product needs a service</span>
      </label>
      <div className={cx('form__group', fieldErrors.nature && 'has-error')}>
        <span className='form__feedback input__validation'>{fieldErrors.nature}</span>
      </div>
      <div className={cx('form__group', fieldErrors.customer_number && 'has-error')}>
        <label className='form__label'>Your customer or contract number (if known)</label>
        <input
          type='text'
          className='form__control'
          placeholder='1234567890'
          name='customer_number'
          onChange={event => updateState('customer_number', event.target.value)}
        />
        <span className='form__feedback input__validation'>{fieldErrors.customer_number}</span>
      </div>
      <div className='form__grid form__grid--identity'>
        <div className='form__group'>
          <label className='form__label'>Title</label>
          <input
            type='text'
            className='form__control color-black'
            placeholder='Title'
            name='title'
            value={address.title}
            disabled
          />
        </div>
        <div className='form__group'>
          <label className='form__label'>First Name</label>
          <input
            type='text'
            className='form__control color-black'
            placeholder='First name'
            name='first_name'
            value={user.firstName}
            disabled
          />
        </div>
        <div className='form__group'>
          <label className='form__label'>Last Name</label>
          <input
            type='text'
            className='form__control color-black'
            placeholder='Last name'
            name='last_name'
            value={user.lastName}
            disabled
          />
        </div>
      </div>
      <div className='form__grid form__grid--1+1'>
        <div className='form__group'>
          <label className='form__label'>Telephone number</label>
          <input
            type='tel'
            className='form__control color-black'
            placeholder='0161 123 4567'
            required
            name='telephone_number'
            value={address.phone}
            disabled
          />
          <small className='form__hint'>
            Provide us with your mobile number and we’ll text you a link with a 3 hour timeslot of when your engineer
            will arrive
          </small>
        </div>
        <div className='form__group'>
          <label className='form__label'>Email Address</label>
          <input
            type='email'
            className='form__control color-black'
            placeholder='john.smith@example.com'
            required
            name='email_address'
            value={user.email}
            disabled
          />
          <small className='form__hint'>
            Appointment Confirmation will be sent to the email address you provide here
          </small>
        </div>
      </div>
      <div className='form__group'>
        <label className='form__label'>Address</label>
        <input
          type='text'
          className='form__control color-black'
          placeholder='Flat 4, Berkely house, Worcester, WR1 2DP'
          name='address'
          value={address.fullAddress}
          disabled
        />
      </div>
      <div className={cx('form__group', fieldErrors.fault_description && 'has-error')}>
        <label className='form__label'>Please briefly describe the fault:</label>
        <textarea
          type='text'
          className='form__control'
          placeholder='Leaking from the boiler pipes...'
          name='fault_description'
          rows='4'
          cols='50'
          onChange={event => updateState('fault_description', event.target.value)}
        ></textarea>
        <span className='form__feedback input__validation'>{fieldErrors.fault_description}</span>
      </div>
      <div className={cx('form__group', fieldErrors.day_availability && 'has-error')}>
        <span className='form__label form__label--left'>Which weekdays can you receive an engineer?</span>
        <label className='form__label form__label--checkbox'>
          <input
            type='checkbox'
            name='day_availability[]'
            value='0'
            defaultChecked={true}
            onClick={updateDayAvailability}
          />
          <span>Monday</span>
        </label>
        <label className='form__label form__label--checkbox'>
          <input
            type='checkbox'
            name='day_availability[]'
            value='1'
            defaultChecked={true}
            onClick={updateDayAvailability}
          />
          <span>Tuesday</span>
        </label>
        <label className='form__label form__label--checkbox'>
          <input
            type='checkbox'
            name='day_availability[]'
            value='2'
            defaultChecked={true}
            onClick={updateDayAvailability}
          />
          <span>Wednesday</span>
        </label>
        <label className='form__label form__label--checkbox'>
          <input
            type='checkbox'
            name='day_availability[]'
            value='3'
            defaultChecked={true}
            onClick={updateDayAvailability}
          />
          <span>Thursday</span>
        </label>
        <label className='form__label form__label--checkbox'>
          <input
            type='checkbox'
            name='day_availability[]'
            value='4'
            defaultChecked={true}
            onClick={updateDayAvailability}
          />
          <span>Friday</span>
        </label>
        <span className='form__hint'>
          Please note: Deselecting weekdays will limit engineer availability and could impact the appointment date
          you’re offered.
        </span>
        <span className='form__feedback input__validation'>{fieldErrors.day_availability}</span>
      </div>
      <div className={cx('form__group', fieldErrors.serviced && 'has-error')}>
        <span className='form__label form__label--left'>Has your boiler been serviced in the past 12 months?</span>
        <label className='form__label form__label--radio'>
          <input type='radio' name='serviced' onClick={() => updateState('serviced', true)} />
          <span>Yes</span>
        </label>
        <label className='form__label form__label--radio'>
          <input type='radio' name='serviced' onClick={() => updateState('serviced', false)} />
          <span>No</span>
        </label>
        <span className='form__feedback input__validation'>{fieldErrors.serviced ? 'Required' : ''}</span>
      </div>
      <div className={cx('form__group', fieldErrors.parking_availability && 'has-error')}>
        <label className='form__label'>Parking availability</label>
        <select
          name='parking_availability'
          className='form__control'
          onChange={event => updateState('parking_availability', event.target.value)}
        >
          <option value=''>Please select</option>
          <option value='Pay & Display'>Pay &amp; Display</option>
          <option value='Permit Required (customer to provide)'>Permit Required (customer to provide)</option>
          <option value='Park on driveway'>Park on driveway</option>
          <option value='On street parking'>On street parking</option>
          <option value='Secure car park'>Secure car park</option>
        </select>
        <span className='form__hint'>
          Please highlight any specific parking situations that the engineer may need to know, such as onsite parking.
        </span>
        <span className='form__feedback input__validation'>{fieldErrors.parking_availability}</span>
      </div>
      <div className={cx('form__group', fieldErrors.nps_receive_market_research && 'has-error')}>
        <span className='form__label form__label--left'>
          From time to time we may contact you with surveys and market research to help improve our services, are you
          happy to receive these emails?
        </span>
        <label className='form__label form__label--checkbox'>
          <input
            type='checkbox'
            name='nps_receive_market_research'
            onClick={event => updateState('nps_receive_market_research', event.target.checked)}
          />
          <span>Yes, I am happy to receive these emails</span>
        </label>
        <span className='form__feedback input__validation'>{fieldErrors.nps_receive_market_research}</span>
      </div>
      <div className='form__group form__group--alignright'>
        <a href={termsRoute} target='_blank' rel='noopener noreferrer' className='link'>
          View terms and conditions of Worcester Bosch services
        </a>
      </div>
      <div className='form__group form__group--alignright'>
        <a href={returnRoute} className='link'>
          Cancel
        </a>
        <button className='button__primary' onClick={handleSubmit}>
          Submit appointment request
        </button>
      </div>
    </div>
  )

  return (
    <>
      <PropertySelector
        addresses={availableAddresses.map(address => address.fullAddress + ' - ' + address.boilerName)}
        onChange={handleAddressChange}
      />

      <div className='frame frame--formcontainer'>{renderForm()}</div>
    </>
  )
}
