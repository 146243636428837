import React, { useState } from 'react'
import { trackEvent } from '../analytics'
import DownloadManualModal from './DownloadManualModal'

const DownloadManual = ({ products }) => {
  const [open, setOpen] = useState(false)

  if (!products.length) return null
  if (products.length === 1) {
    return (
      <a
        href={products[0].manual}
        className='button__primary modal__button'
        download
        rel='noopener noreferrer'
        target='_blank'
        data-analytics
        data-analytics-category='consumer-portal'
        data-analytics-label='Product Manuals'
        data-analytics-action='click'
      >
        Product Manuals
      </a>
    )
  }

  return (
    <>
      <a
        onClick={e => {
          setOpen(true)
          trackEvent('modal-open', 'Product manuals')
          e.preventDefault()
        }}
        className='button button__primary'
      >
        Product Manuals
      </a>
      {open && <DownloadManualModal products={products} hide={() => setOpen(false)} />}
    </>
  )
}

export default DownloadManual
