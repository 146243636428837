import React, { useState, useMemo } from 'react'
import Header from './Header'
import Fasttrack from './Fasttrack'
import Pagination from './Pagination'
import chunkArray from '../../utils/chunkArray'
import FaiOrFab from './FaiOrFab'
import toArray from '../../utils/toArray'

const Orders = props => {
  const { values } = props
  const items = useMemo(() => toArray(values), [values])

  const [page, setPage] = useState(0)
  const chunkedItems = useMemo(() => chunkArray(items, 2), [items])

  return (
    <div className='band-vh'>
      <Header />
      {chunkedItems[page] &&
        chunkedItems[page].map((item, ix) =>
          item.type.includes('fast-track') ? <Fasttrack key={ix} {...item} /> : <FaiOrFab key={ix} {...item} />,
        )}
      <div className="band__cp--pagination">
        <Pagination
          indexPage={page}
          onChange={p => {
            setPage(p)
            window.scrollTo({ top: 0 })
          }}
          totalPages={Math.ceil(items.length / 2)}
        />
      </div>
    </div>
  )
}

export default Orders
