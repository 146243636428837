const transformAddressesErrors = errors => {
  const formatted = Object.keys(errors).reduce((acc, errorKey) => {
    if (!errorKey.includes('address')) return acc
    const addressId = errorKey.split('_')[1]
    const addressErrors = errors[errorKey]
    const currentErrors = Object.keys(addressErrors).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: addressErrors[curr][0],
      }),
      {},
    )

    return {
      ...acc,
      [addressId]: currentErrors,
    }
  }, {})

  return formatted
}

export default transformAddressesErrors
