import React, { useState, useMemo } from 'react'
import SelectOption from './SelectOption'
import makeObjectById from '../utils/makeObjectById'
import { trackEvent } from '../analytics'

function DownloadManualModal ({ products, hide }) {
  const [productId, setProductId] = useState('')
  const [manualLink, setManualLink] = useState('')
  const [error, setError] = useState('')

  const manuals = useMemo(() => makeObjectById(products, 'manual'), [products])
  const productsOptions = useMemo(() => makeObjectById(products, 'name'), [products])

  const handleDownload = () => {
    setError('')

    if (!productId) {
      setError('Please select a product')
      return
    }

    if (!manualLink) {
      setError('No manual found for this product')
      return
    }

    trackEvent('click', 'Download PDF manual')
  }

  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>Which product do you require a manual for?</div>
        <div className='modal__body'>
          <p>
            There are multiple products grouped together in this system. Please select which product you require a
            manual for.
          </p>
          <SelectOption
            label='Select a product'
            value={productId}
            onChange={e => {
              const id = e.target.value
              setProductId(id)
              setManualLink(manuals[id])
            }}
            options={productsOptions}
            error={error}
          />
        </div>
        <div className='modal__buttons modal__buttons--manual'>
          <button className='button__primary modal__button modal__button--cancel' onClick={hide}>
            Cancel
          </button>
          <a
            href={manualLink}
            className='button__primary modal__button modal__button--margin-top'
            download
            rel='noopener noreferrer'
            target='_blank'
            onClick={handleDownload}
          >
            Download PDF manual
          </a>
        </div>
      </div>
    </div>
  )
}

export default DownloadManualModal
