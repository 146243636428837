import React, { useEffect, useState, Fragment } from 'react'
import fetchSerial from '../../network/requests/fetchSerial'
import ModalBuilder from '../ModalBuilder'
import WelcomeModal from '../WelcomeModal'
import Genesys from '../Genesys'
import { trackEvent } from '../../analytics'

const VerifySerial = url => {
  const [serial, setSerial] = useState('')
  const [showReenterSerial, setShowReenterSerial] = useState(false)
  const [showWelcome, setShowWelcome] = useState(false)

  const handleSubmit = () => {
    fetchSerial(serial)
      .then(() => {
        setShowWelcome(true)
        trackEvent('serial-verify-closed', 'successful')
      })
      .catch(() => {
        setShowReenterSerial(true)
        trackEvent('serial-verify-opened', 'unsuccessful')
      })
  }

  useEffect(() => {
    trackEvent('serial-verify-opened')
  }, [])

  return (
    <Fragment>
      <div className='form__group mrg--bx3'>
        <label className='form__label'>Your product serial number</label>
        <input
          className='form__control'
          placeholder='0000 - 000 - 000000 - 0000000000'
          onChange={event => setSerial(event.target.value)}
        />
      </div>
      <button id='verify-registration-serial' className='button button__primary' onClick={handleSubmit}>
        Submit serial number
      </button>
      {showReenterSerial && (
        <ModalBuilder
          title='The serial number you have entered does not seem to match the one we have on record'
          body='Please double check the serial number you have and try again.'
          close='Re-enter your serial number'
          footer={
            <Fragment>
              <p>Alternatively, please contact our Customer Care Team:</p>
              <div className='verify-reg__button-wrapper'>
                {<Genesys />}
                <a href='tel:03301239559'>
                  <img src='/img/icon-phone-bluedark-24x24.svg' />
                  <span>0330 123 9559</span>
                </a>
              </div>
            </Fragment>
          }
          hide={() => {
            setShowReenterSerial(false)
            trackEvent('serial-verify-closed', 'user closed')
          }}
        />
      )}
      {showWelcome && <WelcomeModal show={showWelcome} action={() => (window.location.href = '/')} />}
    </Fragment>
  )
}

export default VerifySerial
