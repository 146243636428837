import React, { useState, useMemo } from 'react'
import validator from '../validation/validator'
import myDetailsSchema from '../validation/schemas/myDetails'
import transformError from '../../fast-track/validation/transformError'
import FormRow from '../../shared/components/FormRow/FormRow'
import flattenFields from '../../shared/util/flattenFields'
import submitMyDetailsForm from '../network/requests/submitMyDetailsForm'
import formatMyDetailsFormData from '../utils/formatMyDetailsFormData'
import { trackEvent } from '../analytics'

export default function MyDetailsForm ({ data }) {
  const { details, consents } = useMemo(() => formatMyDetailsFormData(data.details), [data.details])
  const allFields = [...details, ...consents]

  const [initialState] = useState(() => flattenFields(allFields), [allFields])
  const [fieldErrors, setFieldErrors] = useState({})
  const [editable, setEditable] = useState(false)
  const [values, setValues] = useState(initialState)

  const placeholders = {
    firstName: '',
    lastName: '',
    telephone: '01234567890',
  }

  const payload = useMemo(() => ({
    id: data.id,
    consent_type: {
      consumer_surveys_and_research: values.surveysAndResearch.toLowerCase() !== 'no',
      consumer_products_and_services: values.productsAndServices.toLowerCase() !== 'no',
      consumer_other_groups: values.otherGroups.toLowerCase() !== 'no',
      consumer_third_party: values.thirdParty.toLowerCase() !== 'no',
    },
    user: {
      id: data.details.userId,
      firstName: values.firstName,
      lastName: values.lastName,
      residentialStatus: values.status,
    },
    addresses: [{
      id: data.primaryAddress.id,
      telephoneNumber: values.telephone,
      title: values.title,
    }],
  }), [values, data])

  const handleOnChange = (name, value) => setValues({ ...values, [name]: value })
  const handleSave = () => {
    validator(myDetailsSchema, values)
      .then(() => submitMyDetailsForm(data.id, payload))
      .then(() => {
        setEditable(false)
        trackEvent('submit', 'My details form')
      })
      .catch(error => {
        const errors = transformError(error)
        setFieldErrors(errors)
      })
  }

  const renderRow = (field, index) =>
    <FormRow
      key={index}
      {...field}
      error={fieldErrors[field.name]}
      value={values[field.name]}
      editable={editable}
      onChange={handleOnChange}
      placeholder={placeholders[field.name]}
    />

  return (
    <div>
      <div>
        <h2 className='header__details'>My details</h2>
      </div>
      <div>
        {details.map(renderRow)}
      </div>
      <div className='details__info-header'>From time to time we would like to contact you with offers we think you will be interested in, and also surveys and market research to help improve our services. Are you happy to receive these emails?</div>
      <div>
        {consents.map(renderRow)}
      </div>
      {editable && (
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <button onClick={handleSave} className='button__primary' style={{ marginLeft: 'auto' }}>
            Save your changes
          </button>
        </div>
      )}
    </div>
  )
}
