import React from 'react'
import mountReact from '../shared/mountReact'
import ConsentModal from './components/ConsentModal'
import ContactUsForm from './components/ContactUs/ContactUsForm'
import MyAccountForm from './components/MyAccountForm'
import MyAddressesForm from './components/MyAddressesForm'
import MyDetailsForm from './components/MyDetailsForm'
import RemoveProduct from './components/RemoveProduct'
import RegistrationForm from './components/RegistrationForm'
import Products from './components/Products'
import ProductsV2 from './components/ProductsV2'
import ProductAdvice from './components/ProductAdvice'
import RequestRepair from './components/RequestService/RequestRepair'
import RequestService from './components/RequestService/RequestService'
import AddProduct from './components/AddProduct'
import SideBoxes from './components/SideBoxes'
import Orders from './components/Orders/Orders'
import OrdersV2 from './components/Orders/OrdersV2'
import VerifySerial from './components/VerifySerial/VerifySerial'
import AppointmentForm from './components/AppointmentForm'
import Genesys from './components/Genesys'

mountReact(function ({ props, changeEmailLink }) {
  const data = {
    ...props,
    changeEmailLink
  }
  return (
    <MyAccountForm data={data} />
  )
}, '#my-account-form-mount')

mountReact(function ({ props }) {
  return <MyDetailsForm data={props} />
}, '#my-details-form-mount')

mountReact(function ({ props }) {
  return <MyAddressesForm data={props} />
}, '#my-address-form-mount')

mountReact(function (props) {
  return props.phaseTwo ? <ProductsV2 {...props} /> : <Products {...props} />
}, '#products-mount')

mountReact(function ({ props, phaseTwo, successUrl, wasStpNumberTransferred }) {
  return <RegistrationForm data={props} isPhaseTwo={!!phaseTwo} successUrl={successUrl} wasStpNumberTransferred={wasStpNumberTransferred}/>
}, '#registration-form-mount')

mountReact(function ({ props }) {
  return <VerifySerial data={props} />
}, '#verify-mount')

mountReact(function (props) {
  if (props.isPhaseTwo) return <OrdersV2 {...props} />

  return <Orders {...props} />
}, '#orders-mount')

mountReact(function (props) {
  return <Genesys {...props} />
}, '#intercom-live-chat-mount')

mountReact(function (props) {
  return <SideBoxes {...props} />
}, '#side-boxes-mount')

mountReact(AddProduct, '#add-product-mount')
mountReact(ConsentModal, '#consent-modal-mount')
mountReact(ContactUsForm, '#contact-us-form-mount')
mountReact(RemoveProduct, '#remove-product-mount')
mountReact(ProductAdvice, '#product-advice-mount')
mountReact(RequestRepair, '#request-repair-mount')
mountReact(RequestService, '#request-service-mount')
mountReact(AppointmentForm, '#appointment-form-mount')
