import api from '../../../shared/network/api'

export default () =>
  api.delete(`/v3/consumer-account/delete`).then(async () => {
    // Prevent ns_binding_aborted in FF by fetching the logout page to perform
    //  the logout action rather than navigating to the logout page.
    // When we navigate to the logout page, the request gets cancelled by later
    //  requests, which in FF cancels this request (causing the
    //  ns_binding_aborted error)
    await fetch('/logout')
  })
