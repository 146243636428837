import React, { useState, useMemo } from 'react'
import { trackEvent } from '../analytics'
import deleteProduct from '../network/requests/deleteProduct'
import SelectOption from './SelectOption'

const RemoveSelectProduct = props => {
  const { hide, reasonsOptions, products, guaranteeId, onSuccessRemove } = props
  const [reason, setReason] = useState()
  const [product, setProduct] = useState()
  const [errors, setErrors] = useState({})
  const [remoteError, setRemoteError] = useState('')

  const productsOptions = useMemo(
    () =>
      products.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.name,
        }),
        {},
      ),
    [products],
  )

  const handleRemoveProduct = () => {
    let err = {}
    if (!reason) err.reason = 'Please select a reason'
    if (!product) err.product = 'Please select a product'
    if (Object.keys(err).length) {
      setErrors(err)
    } else {
      deleteProduct(guaranteeId, {
        id: guaranteeId,
        product: {
          reason,
          id: product,
        },
      }).then(() => {
        hide()
        trackEvent('submit', 'Remove product')
        onSuccessRemove(product)
        window.scrollTo(window.scrollX, 0)
      }).catch(err => {
        setRemoteError(err.message)
      })
    }
  }

  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>Which product would you like to remove from this system?</div>
        <div className='modal__body'>
          <SelectOption
            label='Select a product'
            value={product}
            onChange={e => setProduct(e.target.value)}
            options={productsOptions}
            error={errors.product}
          />
          <p>
            You will no longer be able to access guarantee information or other details about this product from the
            MyBosch portal. Should you ever wish to re-add this product you will be required to enter its serial number.
          </p>
          <SelectOption
            label='Why are you removing this product?'
            value={reason}
            onChange={e => setReason(e.target.value)}
            options={reasonsOptions}
            error={errors.reason}
          />

          {remoteError && <p style={{ color: 'red' }}>{remoteError}</p>}
        </div>
        <div className='modal__buttons'>
          <button className='button__primary modal__button' onClick={hide}>
            Cancel
          </button>
          <button className='button__secondary--danger modal__button' onClick={handleRemoveProduct}>
            Continue and remove
          </button>
        </div>
      </div>
    </div>
  )
}

export default RemoveSelectProduct
