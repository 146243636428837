import React from 'react'

const PhotosModal = ({ hide, photos }) => {
  return (
    <div className='modal'>
      <div className='modal__container--installation-photos'>
        <div className='modal__title'>Your installation photos</div>
        <div className='modal__body'>
          <div className='modal__photos'>
            {photos.map((photo, ix) => (
              <img className='modal__photos--image' key={ix} alt={photo.alt} src={photo.src} />
            ))}
          </div>
        </div>
        <div className='modal__buttons'>
          <button className='button__primary modal__button' onClick={hide}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default PhotosModal
