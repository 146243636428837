import React, { useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import uuid from 'uuid/v4'
import { produce } from 'immer'
import CapturePlusInput from '../../shared/components/CapturePlusInput'
import RemoveAddressModal from './RemoveAddressModal'
import AddressFallbackInput from './AddressFallbackInput'

export default function MultipleAddresses (props) {
  const { fields, editable, onChange, onDelete, errors } = props
  const [addressToRemove, setAddressToRemove] = useState()
  const [manually, setManually] = useState({})

  const handleConfirmRemove = async () => {
    if (addressToRemove.id) {
      onDelete(addressToRemove.id)
    }
    handleRemoveAddress(addressToRemove)
  }

  const handleRemoveAddress = field => {
    const newFields = fields
      .filter(item => item.name !== field.name)
      .map((item, ix) =>
        ix !== 0
          ? {
            ...item,
            label: `Additional address ${ix}`,
            name: `additionalAddress${ix}`,
          }
          : item,
      )
    onChange(newFields)
    setAddressToRemove(undefined)
  }

  const handleAdd = () => {
    const newFields = [...fields, {}].map((field, ix) => ({
      ...field,
      clientId: field.clientId || uuid(),
      label: field.label || `Additional address ${ix}`,
      name: field.name || `additionalAddress${ix}`,
    }))
    onChange(newFields)
  }

  const handleOnChange = (name, address, value) => {
    if (!value) return
    const newFields = produce(fields, draft => {
      const ix = draft.findIndex(x => x.name === name)
      if (draft[ix]) {
        draft[ix].value = value
        draft[ix].address = address
      }
    })
    onChange(newFields)
  }

  const handleAddressChange = (addressClientId, name, value) => {
    const target = fields.find(field => field.clientId === addressClientId)

    if (manually[target.clientId]) {
      delete target.address.udprn
      delete target.address.pcaId
    }

    const newField = {
      ...target,
      address: {
        ...target.address,
        [name]: value,
      },
    }
    onChange(fields.map(field => (field.clientId === addressClientId ? newField : field)))
  }

  useEffect(() => {
    setManually({})
  }, [editable])

  const handleSetAsPrimary = field => {
    const primaryAddress = Object.assign({}, fields[0])
    // Swap the selected address with the primary address
    const newFields = fields.map((item, ix) => {
      if (ix === 0) {
        return {
          ...field,
          // Values that shouldn't change for the input
          id: primaryAddress.id,
          label: primaryAddress.label,
          name: primaryAddress.name,
          helpText: primaryAddress.helpText,
        }
      }
      if (item.clientId === field.clientId) {
        return {
          ...primaryAddress,
          // Values that shouldn't change for the input
          id: field.id,
          label: `Additional address ${ix}`,
          name: `additionalAddress${ix}`,
          helpText: '',
        }
      }

      return item
    })

    onChange(newFields)
  }

  const getFieldsErrors = useCallback((field) => {
    return errors[field.id] || errors[field.clientId]
  }, [errors])

  return (
    <div>
      {fields.map((field, ix) => (
        <div key={field.clientId} className='details__row'>
          <div className='details__col--label'>
            <div className='details__header'>{field.label}</div>
            {field.helpText && <div className='details__subheader'>{field.helpText}</div>}
          </div>
          <div className='details__col--value'>
            <div className='details__value details__value--consumer-portal'>
              {editable ? (
                <>
                  <div className={classnames(getFieldsErrors(field) ? 'has-error' : null)}>
                    <CapturePlusInput
                      value={field.value}
                      onChange={(address, value) => handleOnChange(field.name, address, value)}
                    />
                    <span style={{ color: 'red' }}>
                      {getFieldsErrors(field)
                        ? 'Some address fields are missing, please enter your address manually'
                        : ''}
                    </span>
                  </div>
                  {manually[field.clientId] ? (
                    <AddressFallbackInput
                      address={field.address}
                      onChange={(name, value) => handleAddressChange(field.clientId, name, value)}
                      errors={getFieldsErrors(field)}
                      oneLine
                    />
                  ) : (
                    <a className='input__link' onClick={() => setManually({ ...manually, [field.clientId]: true })}>
                      Can&apos;t find your address? Enter your address details manually.
                    </a>
                  )}
                </>
              ) : (
                field.value
              )}
            </div>
            {editable && ix !== 0 && (
              <button
                style={{ marginTop: 10, float: 'right' }}
                onClick={() => (!field.value ? handleRemoveAddress(field) : setAddressToRemove(field))}
                className='button__secondary details__button'
              >
                Remove
              </button>
            )}
            {editable && ix !== 0 && field.value && (
              <button
                style={{ marginTop: 10, float: 'right' }}
                onClick={() => handleSetAsPrimary(field)}
                className='button__primary'
              >
                Set as primary
              </button>
            )}
          </div>
        </div>
      ))}
      {editable && (
        <div style={{ marginTop: '20px' }}>
          <button style={{ float: 'right' }} onClick={handleAdd} className='button__primary'>
            Add
          </button>
        </div>
      )}
      {addressToRemove && (
        <RemoveAddressModal
          hide={() => setAddressToRemove(undefined)}
          address={addressToRemove.value}
          onConfirm={handleConfirmRemove}
        />
      )}
    </div>
  )
}
