import { object, string } from 'yup'

export default object().shape({
  title: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  status: string().required(),
  telephone: string().required(),
  emailAddress: string(),
  surveysAndResearch: string().required(),
  productsAndServices: string().required(),
  otherGroups: string().required(),
  thirdParty: string().required(),
})
