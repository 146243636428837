import React, { useState, useMemo } from 'react'
import flattenFields from '../../shared/util/flattenFields'
import formatMyDetailsFormData from '../utils/formatMyDetailsFormDataV2'
import myDetailsSchema from '../validation/schemas/myDetailsV2'
import submitMyDetailsForm from '../network/requests/submitMyDetailsForm'
import { trackEvent } from '../analytics'
import transformError from '../../fast-track/validation/transformError'
import validator from '../validation/validator'
import FormRow from '../../shared/components/FormRow/FormRow'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import SuccessModal from './SuccessModal'

export default function MyDetailsFormV2 ({ data }) {
  const { details, consents } = useMemo(() => formatMyDetailsFormData(data.details), [data.details])
  const allFields = [...details, ...consents]

  const [editable, setEditable] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})
  const [initialState, setInitialState] = useState(() => flattenFields(allFields), [allFields])
  const [isSaving, setIsSaving] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [values, setValues] = useState(initialState)

  const placeholders = {
    firstName: '',
    lastName: '',
    telephone: '01234567890', // ? shows as mobile number in DOM
    mobile: '01234567890', // ? shows as alternative number in DOM
  }

  const handleToggleEditable = () => {
    if (!editable) {
      // Will edit
      setInitialState(values)
    } else {
      // Will cancel
      setValues(initialState)
      setFieldErrors({})
    }
    setEditable(!editable)
  }

  const payload = useMemo(
    () => ({
      id: data.id,
      consent_type: consents.reduce((acc, consent) => {
        acc[consent.name] = values[consent.name] === 'Yes'
        return acc
      }, {}),
      user: {
        id: data.details.userId,
        firstName: values.firstName,
        lastName: values.lastName,
      },
      addresses: [
        {
          id: data.primaryAddress.id,
          telephoneNumber: values.telephone,
          mobilePhoneNumber: values.mobile || null,
          title: values.title,
          residentialStatus:
            data.primaryAddress?.residentialStatus || data.primaryAddress?.address?.residentialStatus || '',
        },
      ],
    }),
    [values, data],
  )

  const handleOnChange = (name, value) => setValues({ ...values, [name]: value })
  const handleSave = () => {
    setIsSaving(true)
    validator(myDetailsSchema, values)
      .then(() => submitMyDetailsForm(data.id, payload))
      .then(() => {
        setIsSaving(false)
        setShowSuccessModal(true)
        setEditable(false)
        trackEvent('submit', 'updated account details')
      })
      .catch(error => {
        const errors = transformError(error)
        setIsSaving(false)
        setFieldErrors(errors)
      })
  }

  const renderRow = (field) => (
    <FormRow
      key={field.name}
      label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
      name={field.name}
      type={field.type}
      options={field.options}
      error={fieldErrors[field.name]}
      value={values[field.name]}
      editable={editable}
      onChange={handleOnChange}
      placeholder={placeholders[field.name]}
    />
  )

  const renderEmailRow = detail =>
    renderRow({
      ...detail,
      subtext: (
        <>
          <p>Your email address is now managed by SingleKey ID, a Bosch managed service that will enable you to login to any Bosch platform using one email address.</p>
          <p>To change your email address click on Manage my SingleKey ID below.</p>
          <a rel='noopener noreferrer' target='_blank' className='button button__primary' href={data.changeEmailLink}>
            Manage SingleKey ID
          </a>
        </>
      ),
    })

  return (
    <div>
      <div>
        <h2 className='header__details'>My details</h2>
        <button className='accordion__link--edit' onClick={handleToggleEditable}>
          {editable ? 'Cancel' : 'Edit'}
        </button>
      </div>
      <div>
        {details.map(detail => {
          if (detail.name === 'emailAddress') return renderEmailRow(detail)
          return renderRow(detail)
        })}
      </div>
      <div className='details__info-header'>
        From time to time we would like to contact you with offers we think you will be interested in, and also surveys
        and market research to help improve our services. Are you happy to receive these emails?
      </div>
      <div>{consents.map(renderRow)}</div>
      {editable && (
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <button onClick={handleSave} className='button__primary' style={{ marginLeft: 'auto' }}>
            Save your changes
          </button>
        </div>
      )}
      {isSaving && <LoadingOverlay description={'Saving changes'} spinner />}
      {showSuccessModal && (
        <SuccessModal
          onClose={() => setShowSuccessModal(false)}
          message='You changes have been saved successfully.'
          title='Changes saved'
        />
      )}
    </div>
  )
}
