import React, { useState } from 'react'
import cx from 'classnames'
import { trackEvent } from '../analytics'
import { objectToCamel } from '../../fast-track/util/cases'
import requestService from '../validation/schemas/requestService'
import submitSalesLeadRequest from '../../shared/network/requests/submitSalesLeadRequest'
import transformError from '../../fast-track/validation/transformError'
import validator from '../validation/validator'
import LoadingOverlay from '../../shared/components/LoadingOverlay'

export default function SalesLeadModal ({
  accountRoute,
  guarantee,
  installers,
  onClose,
  onSuccess,
  removeInstaller,
  type,
  user,
}) {
  const address = objectToCamel(guarantee.address)
  const [loadingStatus, setLoadingStatus] = useState('')
  const [fieldErrors, setFieldErrors] = useState({})
  const [enquiry, setEnquiry] = useState({
    ...address,
    address: guarantee.installedAt,
    emailAddress: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    message: '',
    telephone: user.phone,
    type,
  })

  const handleSubmit = async () => {
    console.warn('handling submit...')
    const { companyIds, installerNos } = installers.reduce(
      (acc, installer) => {
        acc.companyIds.push(installer.companyId)
        acc.installerNos.push(installer.id)
        return acc
      },
      { companyIds: [], installerNos: [] },
    )

    setFieldErrors({})

    try {
      await validator(requestService, { ...enquiry, companies: companyIds, installers: installerNos })
    } catch (error) {
      const errors = transformError(error)
      setFieldErrors(errors)
      return
    }

    setLoadingStatus('Submitting you enquiry')

    submitSalesLeadRequest(enquiry, installerNos, companyIds)
      .then(() => {
        setLoadingStatus('')
        onSuccess()
        trackEvent('submit', 'Sale lead form')
      })
      .catch(error => {
        console.error(error)
        setLoadingStatus('')
      })
  }

  const updateMessage = event => {
    const { value } = event.target
    const message = `
Product:
${guarantee.boilerName}
---
Message:
${value}
`.trim()
    setEnquiry(prev => ({ ...prev, message }))
  }

  return (
    <div className='modal' data-modal='sales-lead'>
      {loadingStatus && <LoadingOverlay spinner={true} description={loadingStatus} />}
      <div className='modal__container modal__container--with-close'>
        <div className='modal__title'>Discuss a service with:</div>
        <section className='form__wrapper'>
          <div className='modal__body'>
            <div className={cx('mrg--tx2 form__group', fieldErrors.installers && 'has-error')}>
              {installers.map(installer => (
                <div className='pill' key={installer.companyId}>
                  {installer.company ? installer.company : installer.name}
                  <button className='pill__close' onClick={() => removeInstaller(installer)}>
                    <span className='screen-reader-text'>Close</span>
                  </button>
                </div>
              ))}
              <span className='input__validation form__feedback'>{fieldErrors.installers}</span>
            </div>
            <h3 className='form__heading form__heading--light mrg--bx0'>Confirm your Details</h3>
            <p>
              If any of these details are incorrect, please amend them in the <a href={accountRoute}>my account</a>{' '}
              section.
            </p>
            <div className='form__grid--1+1'>
              <div className={cx('form__group', fieldErrors.firstName && 'has-error')}>
                <div className='form__label'>First Name *</div>
                <input type='text' className='form__control' value={enquiry.firstName} disabled />
                <span className='input__validation form__feedback'>{fieldErrors.firstName}</span>
              </div>
              <div className={cx('form__group', fieldErrors.lastName && 'has-error')}>
                <div className='form__label'>Last Name *</div>
                <input type='text' className='form__control' value={enquiry.lastName} disabled />
                <span className='input__validation form__feedback'>{fieldErrors.lastName}</span>
              </div>
            </div>
            <div className={cx('form__group', fieldErrors.address && 'has-error')}>
              <div className='form__label'>Address *</div>
              <input type='text' className='form__control' value={enquiry.address} disabled />
              <span className='input__validation form__feedback'>{fieldErrors.address}</span>
            </div>
            <div className='form__grid--1+1'>
              <div className={cx('form__group', fieldErrors.emailAddress && 'has-error')}>
                <div className='form__label'>Email Address *</div>
                <input type='email' className='form__control' value={enquiry.emailAddress} disabled />
                <span className='input__validation form__feedback'>{fieldErrors.email}</span>
              </div>
              <div className={cx('form__group', fieldErrors.telephone && 'has-error')}>
                <div className='form__label'>Telephone number *</div>
                <input type='text' className='form__control' value={enquiry.telephone} disabled />
                <span className='input__validation form__feedback'>{fieldErrors.phone}</span>
              </div>
            </div>
            <div className={cx('form__group', fieldErrors.message && 'has-error')}>
              <div className='form__label'>Message to engineer *</div>
              <textarea className='form__control' rows='2' required onChange={updateMessage}></textarea>
              <span className='input__validation form__feedback'>{fieldErrors.message}</span>
              <small className='form__hint'>
                Please specify if there are any faults or important information that the engineers should be aware of
              </small>
            </div>
          </div>
          <div className='modal__buttons modal__buttons--stack'>
            <button className='button__secondary modal__button modal__button--plain js-modal-close' onClick={onClose}>
              Cancel
            </button>
            <button
              className='button__primary modal__button js-modal-form-submit js-modal-close'
              onClick={handleSubmit}
            >
              Send enquiry
            </button>
          </div>
        </section>
        <button className='modal__close js-modal-close' onClick={onClose}>
          <span className='screen-reader-text'>Close modal</span>
        </button>
      </div>
    </div>
  )
}
