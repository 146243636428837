const toOptions = array =>
  array.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: curr,
    }),
    {},
  )

export default data => {
  const details = [
    {
      label: 'Title',
      name: 'title',
      value: data.title,
      type: 'select',
      options: toOptions(data.titleOptions),
    },
    {
      label: 'First name',
      name: 'firstName',
      value: data.firstName || '',
      type: 'text',
    },
    {
      label: 'Last name',
      name: 'lastName',
      value: data.lastName || '',
      type: 'text',
    },
    {
      label: 'Mobile number',
      name: 'telephone',
      value: data.telephoneNumber,
      type: 'text',
      helpText: 'This is the number we will contact you with',
    },
    {
      label: 'Alternative number',
      name: 'mobile',
      value: data.mobileNumber,
      type: 'text',
      helpText: '',
    },
    {
      label: 'Email address',
      name: 'emailAddress',
      value: data.emailAddress,
      type: 'html',
      helpText: '',
    },
    {
      label: 'Status',
      name: 'statuses',
      value: data.statuses.sort().join(', '),
      type: 'text',
      disabled: true,
    },
  ]

  const consents = Object.values(data.consents).map(consent => ({
    label: consent.text,
    name: consent.id,
    value: consent.consented === true ? 'Yes' : 'No',
    type: 'radio',
    options: toOptions(data.consentOptions),
  }))

  return {
    details,
    consents,
  }
}
