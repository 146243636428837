import { object, string } from 'yup'

export const DETAILS_MAX_CHARS = 1024
export const DETAILS_MIN_CHARS = 50
const DETAILS_MAX_CHARS_ERROR = `You have exceeded the ${DETAILS_MAX_CHARS} character limit`
const DETAILS_MIN_CHARS_ERROR = `You must enter at least ${DETAILS_MIN_CHARS} characters`
const INVALID_EMAIL_ERROR = 'You must supply a valid email address'
const REQUIRED_MESSAGE = 'Required'
const REQUIRED_UNIT_BUILDING_HOUSE = 'At least one unit number, building name, house number is required'

const validateUnitBuildingHouse = (field1, field2) => {
  return !field1 && !field2
    ? string()
      .nullable()
      .required(REQUIRED_UNIT_BUILDING_HOUSE)
    : string().nullable()
}

export default object().shape({
  type: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  details: string()
    .nullable()
    .min(DETAILS_MIN_CHARS, DETAILS_MIN_CHARS_ERROR)
    .max(DETAILS_MAX_CHARS, DETAILS_MAX_CHARS_ERROR),
  contactMethod: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  files: string().nullable(),
  title: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  firstName: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  lastName: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  phone: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  email: string()
    .email(INVALID_EMAIL_ERROR)
    .nullable()
    .required(REQUIRED_MESSAGE),
  surveysAndResearch: string().required(REQUIRED_MESSAGE),
  address: object().shape(
    {
      unitNumber: string()
        .nullable()
        .test('has buildinName or houseNumber', REQUIRED_UNIT_BUILDING_HOUSE, function (value) {
          const { buildingName, houseNumber } = this.parent
          return value || buildingName || houseNumber
        }),
      buildingName: string()
        .nullable()
        .when(['unitNumber', 'houseNumber'], validateUnitBuildingHouse),
      houseNumber: string()
        .nullable()
        .when(['unitNumber', 'buildingName'], validateUnitBuildingHouse),
      street: string()
        .nullable()
        .required(REQUIRED_MESSAGE),
      district: string().nullable(),
      city: string()
        .nullable()
        .required(REQUIRED_MESSAGE),
      postcode: string()
        .nullable()
        .required(REQUIRED_MESSAGE),
    },
    ['houseNumber', 'buildingName'],
  ),
})
