import React from 'react'
import cx from 'classnames'

const Genesys = ({ email, invisibleLaunch, firstName, lastName, stpNumber }) => {
  const load = () => {

    window.CXBus.command('WebChat.open', {
      form: {
        autoSubmit: false,
        firstname: firstName,
        lastname: lastName,
        email: email,
      }
    })
  }

  return (
    <div className={cx('intercom__live-chat-container', invisibleLaunch && 'intercom__live-chat-container--hidden')} onClick={() => load()}>
      <img className='intercom__live-chat-icon' src={'/img/service-chat.png'} alt='Intercom logo' />
      <p className='intercom__live-chat-text'>
        Start a Live Chat session
      </p>
    </div>
  )
}

export default Genesys
