
// Takes an array of objects that have an id attribute and the targeted field to map
// e.g: makeObjectById([{id: 1, name: 'a'}, { id: 2, name: 'b'}], 'name')
// => { 1: 'a', 2: 'b' }
const makeObjectById = (array, fieldKey) => {
  if (!Array.isArray(array)) {
    console.warn('Should be an array', array)
    return array
  }

  return array.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr[fieldKey],
    }),
    {},
  )
}
export default makeObjectById
