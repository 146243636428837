import Presenter from '../../../shared/network/presenters/Presenter'
import { AddressPresenter } from './AddressPresenter'
import { UserPresenter } from './UserPresenter'

export class ConsumerAccountAddressesPresenter extends Presenter {
  relationships () {
    return {
      user: UserPresenter,
      'primary-address': AddressPresenter,
      addresses: AddressPresenter
    }
  }
}
ConsumerAccountAddressesPresenter.prototype.type = 'consumer-account'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(ConsumerAccountAddressesPresenter, Presenter)

export default data => ConsumerAccountAddressesPresenter.render(data)
