import React from 'react'

export default function SuccessModal ({ ctaUrl, ctaText, isSuccess, message, onClose, title }) {
  return (
    <div className='modal' data-modal='enquiry-confirmation'>
      <div className='modal__container modal__container--with-close'>
        <div className='modal__title text--center'>{title}</div>
        <div className='modal__body'>
          <div className='text--center mrg--tx2 mrg--bx4'>
            {isSuccess === false ? (
              <img
                src='/img/customer-loyalty/journey/alert-error.svg'
                alt='Red octagon with exclamation mark'
                style={{ maxHeight: '150px' }}
              />
            ) : (
              <img src='/img/icon-checked-circle-hollow-green-160x160.svg' alt='Green checkmark' />
            )}
          </div>
          <div className='text text--p text--sm mrg--x0'>{message || title}</div>
        </div>
        <div className='modal__buttons'>
          {onClose && (
            <button onClick={onClose} className='button__primary modal__button'>
              Close
            </button>
          )}
          {ctaUrl && (
            <a href={ctaUrl} className='button__primary modal__button'>
              {ctaText}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
