import React from 'react'
import moment from 'moment'

const createDateString = dateIn => {
  let date = moment(dateIn)
  if (dateIn.includes('.')) {
    const [day, month, year] = dateIn.split('.')
    date = moment(`${year}-${month}-${day}`)
  }

  return date.format('Do MMMM YYYY')
}

const Appointment = props => {
  const { address, reportRoute } = props
  const hasAppointments = address.consumerServices.length > 0

  return (
    <div>
      <div className='sideaction sideaction--iconleft mrg--t60 mrg--b32'>
        <img src='/img/icons/icon-previous-orders.svg' alt='' />
        <h2 className='heading heading--h2 mrg--x0'>
          {hasAppointments ? 'Your' : 'You have no'} previous Worcester Bosch appointments
        </h2>
      </div>
      {hasAppointments &&
        address.consumerServices.map((item, index) => (
          <div key={index} className='frame frame--appointment'>
            <div>
              <ul className='list-2x2'>
                <li>
                  <strong>Address</strong>
                  {address.fullAddress}
                </li>
                <li>
                  <strong>Appointment date:</strong>
                  {createDateString(item.serviceOrderConcatBookedEndDate)}
                </li>
                <li>
                  <strong>Product</strong>
                  {item.productName}
                </li>
                <li>
                  <strong>Appointment type</strong>
                  {item.serviceOrderConcatInterventionType}
                </li>
              </ul>
            </div>
            <div>
              <a
                className='button button__primary'
                href={`${reportRoute}/${item.id}`}
                rel='noopener noreferrer'
                target='_blank'
                data-analytics
                data-analytics-category="reports"
                data-analytics-action="download"
                data-analytics-label="downloaded engineer single report"
              >
                View engineer report
              </a>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Appointment
