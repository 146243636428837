import React from 'react'
import Genesys from './Genesys'

const ProductNotFoundModal = ({ hide }) => {
  return (
    <div className='modal'>
      <div className='modal__container modal__container--no-products'>
        <div className='modal__title'>Sorry, we were unable to find your product</div>
        <div className='modal__body'>
          <p>
            We were unable to find a guarantee registration at one of your addresses for the serial number you provided.
            Please note that if your product has only recently been installed and registered it may take up to 72 hours
            for your guarantee to be fully processed and recorded across all of our systems.
          </p>
          <p>
            If your product has not automatically appeared in your account, or you are still unable to manually add the
            product after this time, please contact our support team.
          </p>

          <div className='modal__two-columns'>
            <div style={{ margin: '20px 0' }}>
              <h2 className='modal__heading'>Get in touch</h2>
              <div className='guarantee__row'>
                <span className='list__icon--phone guarantee__icon'></span>
                <a
                  href='tel:03301239339'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='guarantee__contact'
                  data-analytics
                  data-analytics-category='consumer-protal'
                  data-analytics-label='Services enquiries telephone'
                  data-analytics-action='click'
                >
                  0330 123 9339
                </a>
              </div>
              <div className='guarantee__row'>
                {<Genesys />}
              </div>
            </div>
            <div style={{ margin: '20px 0' }}>
              <div className='modal__heading'>We are open</div>
              <p>Monday to Friday 7.00am - 6.00pm</p>
              <p>Saturday 8.00am - 5.00pm</p>
              <p>Sunday 9.00am - 12.00pm</p>
            </div>
          </div>
        </div>

        <div className='modal__buttons'>
          <button className='button__primary modal__button' onClick={hide}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductNotFoundModal
