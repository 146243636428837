import React, { useState } from 'react'
import MultipleAddresses from './MultipleAddresses'
import formatMyAddressesFormData from '../utils/formatMyAddressesFormData'
import flattenFields from '../../shared/util/flattenFields'
import submitMyAddressesForm from '../network/requests/submitMyAddressesForm'
import deleteAddress from '../network/requests/deleteAddress'
import { trackEvent } from '../analytics'
import transformAddressesErrors from '../validation/transformAddressesErrors'

export default function MyAddressesForm ({ data }) {
  const [initialState, setInitialState] = useState(
    () => formatMyAddressesFormData(data.primaryAddress, data.additionalAddresses),
    [data],
  )

  const [editable, setEditable] = useState(false)
  const [state, setState] = useState(initialState)
  const [addressesToDelete, setAddressesToDelete] = useState([])
  const [errors, setErrors] = useState({})

  const handleToggleEditable = () => {
    if (!editable) {
      // Will edit
      setInitialState(state)
    } else {
      // Will cancel
      setState(initialState)
    }
    setAddressesToDelete([])
    setEditable(!editable)
    setErrors({})
  }

  const handleSave = async () => {
    setErrors({})
    flattenFields(state)
    const [primaryAddress, ...addresses] = state
    const payload = {
      id: data.id,
      user: {
        id: data.details.userId,
      },
      'primary-address': {
        ...primaryAddress.address,
        id: data.primaryAddress.id,
      },
      addresses: addresses
        .filter(x => x.address)
        .map(({ clientId, ...x }) => ({
          ...x.address,
          id: x.id || clientId,
        })),
    }

    try {
      if (addressesToDelete.length) {
        await Promise.all(addressesToDelete.map(addressId => deleteAddress(addressId)))
      }
    } catch (err) {
      console.warn('error', err)
    }

    submitMyAddressesForm(data.id, payload)
      .then(response => {
        const newState = state
          .filter(x => x.address)
          .map((x, i) => ({
            ...x,
            id: response[i].id,
            address: response[i],
          }))
        setState(newState)
        setEditable(false)
        trackEvent('submit', 'My addresses form')
      }).catch((error) => {
        if (error.status === 422) {
          setErrors(transformAddressesErrors(error.responseJSON.errors))
        }
      })
  }

  return (
    <div>
      <div>
        <h2 className='header__details--address'>My addresses</h2>
        <button className='accordion__link--edit' onClick={() => handleToggleEditable()}>
          {editable ? 'Cancel' : 'Edit'}
        </button>
      </div>
      <MultipleAddresses
        fields={state}
        onChange={setState}
        editable={editable}
        onDelete={addressId => setAddressesToDelete([...addressesToDelete, addressId])}
        errors={errors}
      />
      {editable && (
        <div style={{ marginTop: '20px' }}>
          <button onClick={handleSave} className='button__secondary'>
            Save your changes
          </button>
        </div>
      )}
    </div>
  )
}
