import React from 'react'
import TrustPilotScore from '../../../shared/components/TrustPilotScore'
import { columns } from './FaiSelectInstallerTableList'

const FaiSelectInstallerTable = ({ installers, installersRoute, selectedInstallers, toggleInstaller }) => (
  <div className='repair__data'>
    {installers.map(installer => (
      <div key={installer.companyId} className='flex-row' style={{ marginBottom: '-2px' }}>
        <div style={{ flexBasis: columns[0].width, flexGrow: 1, flexShrink: 0 }}>
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div
              className='flex-column padding-base-vertical-max-desktop'
              style={{ width: '100%', height: '100%', justifyContent: 'center' }}
            >
              <h3 className='repair__data-card-title'>
                <a>{installer.company}</a>
              </h3>
              <p style={{ margin: '0' }}>
                {installer.distance} mile{installer.distance !== 1 && 's'} away
              </p>
            </div>
          </div>
        </div>
        <div style={{ flexBasis: columns[1].width, flexGrow: 1, flexShrink: 0 }}>
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div
              className='flex-row padding-base-vertical-max-desktop'
              style={{ width: '100%', height: '100%', justifyContent: 'center' }}
            >
              {installer.safeInYourHome && <img src='/img/staysafe-logo.png' width='78' height='78' alt='' />}
            </div>
          </div>
        </div>
        <div style={{ flexBasis: columns[2].width, flexGrow: 1, flexShrink: 0 }}>
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div
              className='flex-row padding-base-vertical-max-desktop'
              style={{ width: '100%', height: '100%', justifyContent: 'center' }}
            >
              <TrustPilotScore
                stars={installer.trustPilotStars || undefined}
                reviews={installer.trustPilotReviews}
                url={installer.trustPilotUrl}
              />
            </div>
          </div>
        </div>
        <div
          className='display-flex flex-grow'
          style={{ flexBasis: columns[3].width, flexGrow: 1, flexShrink: 0 }}
        >
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div className='flex-column' style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
              <a
                href={`${installersRoute}/${installer.slug}`}
                className='button__legacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                About Installer
              </a>
              {selectedInstallers.find(si => si.companyId === installer.companyId) ? (
                <button
                  onClick={() => toggleInstaller(installer, false)}
                  className='button__legacy button__legacy-success'
                >
                  Selected
                </button>
              ) : (
                <button
                  onClick={() => toggleInstaller(installer, true)}
                  className='button__legacy button__legacy-primary'
                >
                  Select
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default FaiSelectInstallerTable
