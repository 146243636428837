import api from '../../../shared/network/api'
import parseConsumerAccountAddresses from '../presenters/ConsumerAccountAddressesPresenter'
import parseAddress from '../transformers/parseAddress'

export default (accountId, data) =>
  api
    .patch(
      `/v3/consumer-account`,
      parseConsumerAccountAddresses(data),
    )
    .then(parseAddress)
