import React from 'react'
import TrustPilotScore from '../../../shared/components/TrustPilotScore'

export const columns = [
  {
    name: 'Engineer',
    width: '40%',
  },
  {
    name: 'Safe In Your Home',
    width: '18.75%',
  },
  {
    name: 'Reviews',
    width: '22.5%',
  },
  {
    name: '',
    width: '18.75%',
  },
]

const FaiSelectInstallerTable = ({ installers, installersRoute, selectedInstallers, toggleInstaller }) => (
  <div className='fai-installer repair__data'>
    {installers.map(installer => (
      <div key={installer.companyId} className='flex-row '>
        <div style={{ width: columns[0].width, border: '2px solid #eeeeee', borderLeft: 'none' }}>
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div
              className='flex-column padding-base-vertical-max-desktop'
              style={{ width: '100%', height: '100%', justifyContent: 'center' }}
            >
              <h3 className='repair__data-card-title'>
                <a>{installer.company}</a>
              </h3>
              <p style={{ margin: '0' }}>
                {installer.distance} mile{installer.distance !== 1 && 's'} away
              </p>
            </div>
          </div>
        </div>
        <div style={{ width: columns[1].width, border: '2px solid #eeeeee', borderLeft: 'none' }}>
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div
              className='flex-row padding-base-vertical-max-desktop'
              style={{ width: '100%', height: '100%', justifyContent: 'center' }}
            >
              {installer.safeInYourHome && <img src='/img/staysafe-logo.png' width='78' height='78' alt='' />}
            </div>
          </div>
        </div>
        <div style={{ width: columns[2].width, border: '2px solid #eeeeee', borderLeft: 'none' }}>
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div
              className='flex-row padding-base-vertical-max-desktop'
              style={{ width: '100%', height: '100%', justifyContent: 'center' }}
            >
              <TrustPilotScore
                stars={installer.trustPilotStars || undefined}
                reviews={installer.trustPilotReviews}
                url={installer.trustPilotUrl}
              />
            </div>
          </div>
        </div>
        <div
          className='display-flex flex-grow width-full-max-desktop'
          style={{ width: columns[3].width, border: '2px solid #eeeeee', borderLeft: 'none' }}
        >
          <div style={{ padding: '16px', alignSelf: 'center', width: '100%', height: '100%' }}>
            <div className='flex-column' style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
              <a
                className='button__legacy'
                style={{ textAlign: 'center' }}
                href={`${installersRoute}/${installer.slug}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                About engineer
              </a>
              {selectedInstallers.find(si => si.companyId === installer.companyId) ? (
                <button
                  onClick={() => toggleInstaller(installer, false)}
                  className='button__legacy button__legacy-success'
                >
                  Selected
                </button>
              ) : (
                <button
                  onClick={() => toggleInstaller(installer, true)}
                  className='button__legacy button__legacy-primary'
                >
                  Select
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default FaiSelectInstallerTable
