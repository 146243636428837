import React, { useMemo } from 'react'
import cx from 'classnames'
import toOptionsObject from '../utils/toOptionsObject'

const SelectOption = props => {
  const { label, value, options, error, onChange } = props

  const optionsObject = useMemo(() => toOptionsObject(options), [options])

  return (
    <div className={cx(error && 'has-error', 'input__container--text')}>
      <label className='input__label'>{label}</label>
      <select className='input__control' value={value} onChange={onChange}>
        <option value=''>Please select</option>
        {Object.keys(optionsObject).map((option, ix) => (
          <option key={ix} value={option}>
            {optionsObject[option]}
          </option>
        ))}
      </select>
      <span className='input__feedback input__validation'>{error}</span>
    </div>
  )
}

export default SelectOption
