import { object, string } from 'yup'

const PHONE_NUMBER_ERROR = `The telephone number format is invalid`
const PHONE_NUMBER_REGEX = /^$|^(\+?)[0-9()]{5,20}$/

export default object().shape({
  title: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  telephone: string().matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_ERROR).required(),
  mobile: string().nullable(true).matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_ERROR),
  emailAddress: string(),
  consents: object()
})
