import { bool, mixed, object, string } from 'yup'

const CONSENT_OPTIONS = ['Yes', 'No']
const REQUIRED_MESSAGE = 'Required'
const REQUIRED_UNIT_BUILDING_HOUSE = 'At least one unit number, building name, house number is required'
const PHONE_NUMBER_REGEX = /^$|^(\+?)[0-9()]{5,20}$/
const PHONE_NUMBER_ERROR = `The telephone number format is invalid`

const validateUnitBuildingHouse = (field1, field2) => {
  return !field1 && !field2
    ? string()
      .nullable()
      .required(REQUIRED_UNIT_BUILDING_HOUSE)
    : string().nullable()
}

export default object().shape({
  title: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  firstName: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  lastName: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  telephoneNumber: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  mobileNumber: string()
    .nullable(),
  acceptTc: bool().test('acceptTc', 'Please accept the terms and conditions', value => value),
  hasExistingProduct: string(),
  surveysAndResearch: string().required(),
  productsAndServices: string().required(),
  otherGroups: string().required(),
  thirdParty: string().required(),
  primaryAddress: object().shape({
    value: string(),
    address: object().shape(
      {
        unitNumber: string()
          .nullable()
          .test('has buildinName or houseNumber', REQUIRED_UNIT_BUILDING_HOUSE, function (value) {
            const { buildingName, houseNumber } = this.parent
            return value || buildingName || houseNumber
          }),
        buildingName: string()
          .nullable()
          .when(['unitNumber', 'houseNumber'], validateUnitBuildingHouse),
        houseNumber: string()
          .nullable()
          .when(['unitNumber', 'buildingName'], validateUnitBuildingHouse),
        street: string()
          .nullable(),
        district: string().nullable(),
        city: string()
          .nullable()
          .required(REQUIRED_MESSAGE),
        postcode: string()
          .nullable()
          .required(REQUIRED_MESSAGE),
      },
      ['houseNumber', 'buildingName'],
    ),
  }),
})

export const registrationPhaseTwoSchema = object().shape({
  title: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  firstName: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  lastName: string()
    .nullable()
    .required(REQUIRED_MESSAGE),
  telephoneNumber: string()
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_ERROR)
    .required(REQUIRED_MESSAGE),
  mobileNumber: string()
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_ERROR)
    .nullable(),
  acceptTc: bool().test('acceptTc', 'Please accept the terms and conditions', value => value),
  hasExistingProduct: mixed()
    .oneOf(CONSENT_OPTIONS, REQUIRED_MESSAGE),
  surveysAndResearch: mixed()
    .oneOf(CONSENT_OPTIONS, REQUIRED_MESSAGE),
  productsAndServices: mixed()
    .oneOf(CONSENT_OPTIONS, REQUIRED_MESSAGE),
  otherGroups: mixed()
    .oneOf(CONSENT_OPTIONS, REQUIRED_MESSAGE),
  thirdParty: mixed()
    .oneOf(CONSENT_OPTIONS, REQUIRED_MESSAGE),
  primaryAddress: object().shape({
    value: string(),
    address: object().shape(
      {
        unitNumber: string()
          .nullable()
          .test('has buildinName or houseNumber', REQUIRED_UNIT_BUILDING_HOUSE, function (value) {
            const { buildingName, houseNumber } = this.parent
            return value || buildingName || houseNumber
          }),
        buildingName: string()
          .nullable()
          .when(['unitNumber', 'houseNumber'], validateUnitBuildingHouse),
        houseNumber: string()
          .nullable()
          .when(['unitNumber', 'buildingName'], validateUnitBuildingHouse),
        street: string()
          .nullable(),
        district: string().nullable(),
        city: string()
          .nullable()
          .required(REQUIRED_MESSAGE),
        postcode: string()
          .nullable()
          .required(REQUIRED_MESSAGE),
      },
      ['houseNumber', 'buildingName'],
    ),
  }),
})
