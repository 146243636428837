import React, { useState } from 'react'
import { trackEvent } from '../../analytics'

const Header = ({ activeKey, items, onChange }) => (
  <div className='wb-grey'>
    <div className='header__customer--tabs'>
      <div className='flex-column tab__customer'>
        <h2 className='tab__customer--header'>Select category</h2>
        <div className='horizontal-scroll'>
          <div className='horizontal-scroll__tabs'>
            {items.map(item => (
              <button
                key={item.key}
                className={`tab__button tab__customer--item ${activeKey === item.key ? 'active' : ''}`}
                disabled={item.disabled}
                onClick={() => onChange(item.key, item.text)}
              >
                <img className='tab__customer--image' alt={item.altText} src={item.iconSrc} />
                <span>{item.text}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

/**
 *
 * @param {object} param0
 * @param {string} param0.defaultItem Key for the default item to show
 * @param {object[]} param0.items Array of objects used to render the Header and Tabbed content
 * @param {string} param0.item.altText 'FastTrack Icon',
 * @param {string} param0.item.children <FastTrackOrders items={items} />,
 * @param {string} param0.item.disabled Will disable the header and prevent it's content being rendered
 * @param {string} param0.item.iconSrc '/img/fast-track/fast-track-logo.svg',
 * @param {string} param0.item.key 'fasttrack',
 * @param {string} param0.item.text 'FastTrack Orders',
 * @returns JSX.Element
 */
const Tabs = ({ defaultItem, items }) => {
  const [activeKey, setActiveKey] = useState(defaultItem || items[0].key)

  const renderActiveTabItem = () => {
    const item = items.filter(item => item.key === activeKey)[0]
    return item.children || null
  }

  return (
    <>
      <Header activeKey={activeKey} items={items} onChange={(key, description) => {
        trackEvent('dashboard-tab-open', description)
        setActiveKey(key)
      }} />
      {renderActiveTabItem()}
    </>
  )
}

export default Tabs
