import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { trackEvent } from '../../analytics'
import transformError from '../../validation/transformError'
import contactUsSchema, { DETAILS_MAX_CHARS, DETAILS_MIN_CHARS } from '../../validation/schemas/contactus'
import validator from '../../validation/validator'
import submitContactUsForm from '../../network/requests/submitContactUsForm'
import uploadFileRequest from '../../../shared/network/requests/uploadFileRequest'
import { objectToCamel } from '../../../fast-track/util/cases'
import DropzoneFileUpload from './DropzoneFileUpload'
import InputText from '../InputText'
import SuccessModal from '../SuccessModal'
import LoadingOverlay from '../LoadingOverlay'
import SelectOption from '../SelectOption'
import { TITLES } from '../../../shared/constants'

const ACCEPTABLE_EXTENSIONS = ['.docx', '.pdf', '.txt', '.jpg', '.jpeg', '.png']

const UPLOAD_QUANTITY_LIMIT = 1

const INITIAL_STATE = {
  type: '',
  details: '',
  contactMethod: 'email',
  files: [],
  title: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  addressId: null,
  address: {
    unitNumber: '',
    buildingName: '',
    houseNumber: '',
    street: '',
    district: '',
    city: '',
    postcode: '',
  },
  surveysAndResearch: 'No',
}

const setInitialData = (user, address) => {
  const {
    id,
    title,
    telephoneNumber,
    unitNumber,
    buildingName,
    houseNumber,
    street,
    localDistrict: district,
    city,
    postcode,
  } = address
  const { email, firstName, lastName } = user
  return {
    ...INITIAL_STATE,
    title,
    firstName,
    lastName,
    email,
    phone: telephoneNumber,
    address: {
      id,
      unitNumber,
      buildingName,
      houseNumber,
      street,
      district,
      city,
      postcode,
    },
  }
}

const ContactUsForm = ({ addresses, consent, feedbackTypes, user }) => {
  const camelAddresses = Object.values(addresses).map(a => objectToCamel(a))
  const primaryAddress = camelAddresses[0]
  const camelUser = objectToCamel(user)

  const [fieldErrors, setFieldErrors] = useState({})
  const [uploadedFileIds, setUploadedFileIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [state, setState] = useState(setInitialData(camelUser, primaryAddress))
  const randomFirstName = Math.random() < 0.5 ? 'John' : 'Jane'

  const addressOptions = camelAddresses.reduce((acc, address) => {
    acc[address.id] = address.fullAddress
    return acc
  }, {})

  const handleChange = key => e => {
    const value = e.target ? e.target.value : e
    const newState = {
      ...state,
      [key]: value,
    }
    setState(newState)
  }

  const handleNewFiles = files => {
    const newState = {
      ...state,
      files,
    }
    setState(newState)
  }

  const handleChangeAddress = event => {
    const id = Number(event.target.value)
    const newState = {
      ...state,
      addressId: id,
      address: camelAddresses.filter(a => a.id === id)[0],
    }
    setState(newState)
  }

  const handleValidationErrors = error => {
    setIsLoading(false)

    const errors = transformError(error)
    setFieldErrors(errors)
  }

  const submitFiles = () => Promise.all(state.files.map(file => uploadFileRequest(file)))

  const submitForm = () => {
    const payload = {
      consent_type: {
        consumer_surveys_and_research: state.surveysAndResearch === 'Yes',
      },
      consentRevisionId: consent.revisionId,
      enquiryType: state.type,
      details: state.details,
      contact_method: state.contactMethod,
      title: state.title,
      first_name: state.firstName,
      last_name: state.lastName,
      phone: state.phone,
      email: state.email,
      is_actioned: false,
      enquiryFile: { id: uploadedFileIds[0] },
      address: {
        ...state.address,
        firstName: state.firstName,
        lastName: state.lastName,
        title: state.title,
        telephoneNumber: state.phone,
        emailAddress: state.email,
      },
    }

    submitContactUsForm(payload)
      .then(() => {
        setIsLoading(false)
        setShowSuccessModal(true)
        trackEvent('submit', 'My Contact form')
      })
      .catch(() => {
        setIsLoading(false)
        setFieldErrors({ submission: 'Could not submit your enquiry. Please try again.' })
      })
  }

  const handleSubmit = () => {
    setFieldErrors({})
    setIsLoading(true)

    validator(contactUsSchema, state)
      .then(async () => {
        if (!state.files.length) {
          submitForm()
          return
        }

        try {
          const uploads = await submitFiles()
          setUploadedFileIds(uploads.map(u => u.id))
        } catch (error) {
          setFieldErrors({ files: 'There was an issue uploading one or more files. Please try again.' })
          setIsLoading(false)
        }
      })
      .catch(handleValidationErrors)
  }

  useEffect(() => {
    if (uploadedFileIds.length === 0) return
    submitForm()
  }, [uploadedFileIds])

  return (
    <form action='' className='form contact-us'>
      <div className='form__group'>
        <SelectOption
          label='Type of feedback *'
          value={state.type}
          onChange={handleChange('type')}
          options={feedbackTypes}
          error={fieldErrors.type}
        />
      </div>
      <div
        className={cx(
          (state.details.length > DETAILS_MAX_CHARS ||
            state.details.length < DETAILS_MIN_CHARS ||
            fieldErrors.details) &&
            'has-error',
          'form__group',
        )}
      >
        <label className='form__label'>Tell us about your experience *</label>
        <textarea
          className='form__control'
          label='Details of enquiry *'
          name='details'
          onChange={handleChange('details')}
          placeholder={`Please write your message here including how we could make it right... (minimum ${DETAILS_MIN_CHARS} characters)`}
          rows='6'
          value={state.details}
        ></textarea>
        <span className='form__feedback input__validation'>
          {fieldErrors.details ? fieldErrors.details : ''} {state.details.length}/{DETAILS_MAX_CHARS} characters
        </span>
      </div>
      <h3 className='form__heading'>
        You can upload an image or document which may help explain your enquiry if you feel they are relevant
      </h3>
      <div className={cx(fieldErrors.files && 'has-error', 'form__group')}>
        <DropzoneFileUpload
          extensions={ACCEPTABLE_EXTENSIONS}
          onNewFiles={handleNewFiles}
          quantity={UPLOAD_QUANTITY_LIMIT}
        />
        <span className='form__feedback input__validation'>{fieldErrors.files}</span>
      </div>
      <h3 className='form__heading'>
        Please review your details and ensure they are correct before submitting your enquiry
      </h3>
      <div className='form__grid form__grid--identity'>
        <div className='form__group'>
          <SelectOption
            label='Title *'
            value={state.title}
            onChange={handleChange('title')}
            options={TITLES}
            error={fieldErrors.title}
          />
        </div>
        <div className='form__group'>
          <InputText
            value={state.firstName}
            onChange={handleChange('firstName')}
            label='First Name *'
            error={fieldErrors.firstName}
          />
        </div>{' '}
        <div className='form__group'>
          <InputText
            value={state.lastName}
            onChange={handleChange('lastName')}
            label='Last Name *'
            error={fieldErrors.lastName}
          />
        </div>
      </div>
      <div className='consumer-register__row--two'>
        <InputText
          value={state.phone}
          onChange={handleChange('phone')}
          label='Telephone number *'
          error={fieldErrors.phone}
          placeholder='01234567890'
        />
        <InputText
          value={state.email}
          onChange={handleChange('email')}
          label='Email Address *'
          error={fieldErrors.email}
          placeholder={`${randomFirstName}.smith@example.com`}
        />
      </div>
      <div
        className={cx(fieldErrors.address && 'has-error', 'input__container--text input__container--address')}
        style={{ borderBottom: 'none' }}
      >
        <label className='input__label details__header'>Address *</label>
        <SelectOption
          error={fieldErrors.addressId}
          onChange={handleChangeAddress}
          options={addressOptions}
          value={state.addressId || primaryAddress.id}
        />
      </div>

      <div className={cx(fieldErrors.submission && 'has-error', 'form__group form__group--alignright')}>
        <span className='form__feedback input__validation'>{fieldErrors.submission}</span>
        <button className='button__primary' onClick={handleSubmit} type='button'>
          Submit Feedback
        </button>
      </div>
      {isLoading && <LoadingOverlay spinner={true} description='Submitting your query' />}
      {showSuccessModal && (
        <SuccessModal
          ctaUrl='/'
          ctaText='Back to dashboard'
          message={
            <>
              <p>Thank you for submitting your feedback.</p>
              <p>This will now be logged onto your account.</p>
              <p>
                Complaints will be assigned a dedicated case handler who will be in touch within 5 working days
                (Monday-Friday, excluding public holidays).
              </p>
              <p>
                If you would like to provide any additional information please reach out to us:{' '}
                <a className='link' href='mailto:correspondence.mailbox@uk.bosch.com'>
                  correspondence.mailbox@uk.bosch.com
                </a>
              </p>
            </>
          }
          title='Feedback Submitted'
        />
      )}
      <br />
      <p className='text--p text--sm'>
        * Bosch Thermotechnology Ltd is not regulated by a national body but in setting its policy on redress to
        customer complaints it works to a standard it believes to be fair and reasonable. This procedure does not affect
        your statutory rights.
      </p>
    </form>
  )
}

export default ContactUsForm
