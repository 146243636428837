import { distanceInMiles } from './calculateDistanceByLatLon'
import getInstallers from '../../fai-results/network/getInstallers'
import makeFaiRequestFilters, { mapProductToFuelType, mapProductToFaiType } from './makeFaiRequestFilters'

export const calculateDistanceBetweenLocations = (address, installer) =>
  distanceInMiles(address.latitude, address.longitude, installer.location.lat, installer.location.lon)

const requestInstallers = ({ boilerType = '', currentInstallers = [], fresh = false, latitude, longitude, postcode }) => {
  const productType = mapProductToFaiType(boilerType ?? '')
  const fuelType = mapProductToFuelType(productType)

  const filters = makeFaiRequestFilters({
    fuelType,
    latitude,
    longitude,
    offset: fresh ? 0 : currentInstallers.length,
    postcode,
    products: [productType],
    type: 'Boiler repair',
  })

  return getInstallers(makeFaiRequestFilters(filters)).then(results => {
    let installerList = [...results.installers]

    if (!fresh) {
      installerList = installerList.concat(currentInstallers)
    }

    installerList = installerList
      .map(installer => ({
        ...installer,
        distance: calculateDistanceBetweenLocations({ latitude, longitude }, installer),
      }))
      .sort((a, b) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0))

    return {
      installers: installerList,
      total: results.total
    }
  })
}

export default requestInstallers
