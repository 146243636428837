export const mapProductToFuelType = type => {
  switch (type.toLowerCase()) {
    case 'oil-boilers':
      return 'oil'
    case 'gas-boilers':
      return 'gas'
    case 'lpg':
      return 'lpg'
    default:
      return ''
  }
}

export const mapProductToFaiType = type => {
  switch (type.toLowerCase()) {
    case 'commercial boiler':
      return 'commercial'
    case 'cylinders':
      return 'cylinders'
    case 'gas boiler':
      return 'gas-boilers'
    case 'lpg':
      return 'lpg'
    case 'oil boiler':
      return 'oil-boilers'
    default:
      return ''
  }
}

/**
 * Create the basic filters required to make a FAI required
 * @param {object} filters - The specified filters to be used
 * @param {boolean} [filters.accreditations=false] - Must the installer have "Safe In Your Home" status
 * @param {boolean} [filters.customer=true] - Is this a customer making the request
 * @param {boolean} [filters.finance=false] - Must the installer provide finance options
 * @param {string} [filters.fuelType=] - One of: gas, lpg, oil, unknown
 * @param {boolean} [filters.includeNational=true] - Include national providers
 * @param {string} filters.latitude - The latitude of the address
 * @param {string} filters.longitude - The longitude of the address
 * @param {boolean} [filters.maintenance=true] - Must the installer provide maintenance services
 * @param {string} [filters.offset=0] - Used for pagination of returned results. Returns the next 5 installers that occur after the offset
 * @param {string[]} [filters.products=[]] - One or more of : commercial, cylinders, gas-boilers, lpg, oil-boilers
 * @param {string} [filters.priority=Normal] - One of: Low, Normal, Urgent
 * @param {string} filters.postcode - Required - the address postcode
 * @param {boolean} [filters.trustPilot=false] - Prioritise installers with trustpilot rating
 * @param {string} filters.type - One of the following: Boiler repair, Boiler service
 * @returns Filters object with all mandatory fields filled completed
 */
const makeFaiRequestFilters = ({
  accreditations = false,
  customer = true,
  finance = false,
  fuelType = '',
  includeNational = true,
  latitude,
  longitude,
  maintenance = true,
  offset = 0,
  products = [],
  priority = 'Normal',
  postcode,
  trustPilot = false,
  type,
}) => {
  if (!postcode) {
    throw new Error('You must supply a postcode in order to calculate engineer distances from your chosen location')
  }

  return {
    accreditations,
    customer,
    finance,
    fuelType,
    includeNational,
    latitude,
    longitude,
    maintenance,
    offset,
    priority,
    products,
    postcode,
    trustPilot,
    type,
  }
}

export default makeFaiRequestFilters
