import React from 'react'
import cx from 'classnames'

const InputText = props => {
  const { error, onChange, value, label, placeholder = '' } = props

  return (
    <div className={cx(error && 'has-error', 'input__container--consumer')}>
      <label className='input__label details__header'>{label}</label>
      <input placeholder={placeholder} className='input__control' value={value} onChange={onChange}></input>
      <span className='input__validation'>{error}</span>
    </div>
  )
}

export default InputText
