import Presenter from '../../../shared/network/presenters/Presenter'
import { ProductPresenter } from './ProductPresenter'

export class ComsumerAccountGuaranteePresenter extends Presenter {
  relationships () {
    return {
      product: ProductPresenter,
    }
  }
}
ComsumerAccountGuaranteePresenter.prototype.type = 'consumer-account-guarantee'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(ComsumerAccountGuaranteePresenter, Presenter)

export default data => ComsumerAccountGuaranteePresenter.render(data)
