export const JOURNEY_STATE = {
  COMPLETED: 'completed',
  TODO: 'todo',
  DISABLED: 'disabled',
}

export const JOURNEY_STATUS = {
  AWAITING: 'awaiting',
  SUBMITTED: 'submitted',
  INSTALLER_ASSIGNMENT: 'installerAssignment',
  ACCEPT_QUOTE: 'acceptQuote',
  READY: 'ready',
}

export const ORDER_JOURNEY_LIST = [
  {
    icon: 'work-order',
    label: 'Order submitted',
    name: JOURNEY_STATUS.SUBMITTED,
  },
  {
    icon: 'complete',
    label: 'Preparing your survey and quote',
    name: JOURNEY_STATUS.INSTALLER_ASSIGNMENT,
  },
  {
    icon: 'action',
    label: 'Accept your quote',
    name: JOURNEY_STATUS.ACCEPT_QUOTE,
  },
  {
    icon: 'ready',
    label: 'Installation booked',
    name: JOURNEY_STATUS.READY,
  },
]

export const SURVEY_JOURNEY_LIST = [
  {
    icon: 'work-order',
    label: 'Survey submitted',
    name: JOURNEY_STATUS.SUBMITTED,
  },
  {
    icon: 'complete',
    label: 'Preparing your survey and quote',
    name: JOURNEY_STATUS.INSTALLER_ASSIGNMENT,
  },
  {
    icon: 'guarantee',
    label: 'Completed',
    name: JOURNEY_STATUS.READY,
  },
]

export const CONSENT_TYPES = {
  productsAndServices: {
    label: 'Information and offers about products and services from Worcester Bosch',
    name: 'productsAndServices',
  },
  otherGroups: {
    label: 'Information and offers from other Bosch group companies  e.g. power tools, home appliances, smart home products',
    name: 'otherGroups',
  },
  thirdParty: {
    label: 'Information and offers from carefully selected third party organisations',
    name: 'thirdParty',
  },
  surveysAndResearch: {
    label: 'Surveys and market research',
    name: 'surveysAndResearch',
  }
}
