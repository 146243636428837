import React, { useState } from 'react'
import FaiSelectInstallerTable from './FaiSelectInstallerTableList'
import ServiceWithOriginalInstaller from './ServiceWithOriginalInstaller'
import InfoIcon from '../../../configurator/components/InfoIcon'

const ListAllEngineers = ({
  disableLoadMore,
  installers,
  installersRoute,
  limit,
  onLoadMore,
  openSalesLead,
  originalInstaller,
  selectedInstallers,
  toggleInstaller,
  toggleShowMore,
}) => {
  const [staySafeTooltip, setStaySafeTooltip] = useState(false)
  return (
    <div className='repair__data'>
      <a className='plaintext-link plaintext-link--back' onClick={toggleShowMore}>
        Back
      </a>
      {originalInstaller && (
        <>
          <ServiceWithOriginalInstaller
            installer={originalInstaller}
            installersRoute={installersRoute}
            openSalesLead={openSalesLead}
            toggleInstaller={toggleInstaller}
          />
          <h2>Alternatively select up to {limit} local engineers and send them a service request</h2>
        </>
      )}
      <>
        {!originalInstaller && <h2>Please select up to {limit} local engineers to send a repair request to</h2>}
        <p>
          You can track your completed requests in the enquiries & appointments section, within the independent engineer enquiries category.
        </p>
        <div style={{ position: 'relative' }}>
          <div className='flex-row vertical-center text-center hide-max-desktop'>
            <h3 className='margin-none flex-grow' style={{ width: '40%', letterSpacing: '-0.25px', lineHeight: '0' }}>
              <small className='text-black' style={{ width: '80%', display: 'block', margin: 'auto' }}>
                <strong
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    lineHeight: '1.4',
                    fontSize: '1.5rem',
                    fontWeight: '400',
                  }}
                >
                  Engineer
                </strong>
              </small>
            </h3>
            <h3
              className='margin-none flex-grow'
              style={{ width: '18.75%', letterSpacing: '-0.25px', lineHeight: '0', position: 'relative' }}
            >
              <small className='text-black' style={{ width: '80%', display: 'block', margin: 'auto' }}>
                <strong
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    lineHeight: '1.4',
                    fontSize: '1.5rem',
                    fontWeight: '400',
                  }}
                >
                  Safe in
                  <br />
                  your Home
                </strong>
              </small>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translate(0,-50%)',
                }}
              >
                <InfoIcon
                  style={{
                    width: 20,
                    height: 20,
                    float: 'right',
                    marginLeft: 15,
                    color: '#007DB8',
                  }}
                  onClick={() => setStaySafeTooltip(!staySafeTooltip)}
                />
                {staySafeTooltip && (
                  <span className='fai-speech-bubble'>
                    Installers who have pledged to our &apos;Safe In Your Home&apos; commitment.
                    <br />
                    <a
                      href='https://www.worcester-bosch.co.uk/heating-advice/safe-in-your-home'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Learn more
                    </a>
                  </span>
                )}
              </div>
            </h3>
            <h3 className='margin-none flex-grow' style={{ width: '22.5%', letterSpacing: '-0.25px', lineHeight: '0' }}>
              <img src='/img/configurator/trustpilot.png' style={{ width: '60%' }} alt='TrustPilot' />
            </h3>
            <h3
              className='margin-none flex-grow'
              style={{ width: '18.75%', letterSpacing: '-0.25px', lineHeight: '0' }}
            >
              <small className='text-black' style={{ width: '80%', display: 'block', margin: 'auto' }}>
                <strong
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    lineHeight: '1.4',
                    fontSize: '1.5rem',
                    fontWeight: '400',
                  }}
                >
                  &nbsp;
                </strong>
              </small>
            </h3>
          </div>
          <FaiSelectInstallerTable
            installers={installers}
            installersRoute={installersRoute}
            selectedInstallers={selectedInstallers}
            toggleInstaller={toggleInstaller}
          />
        </div>
        <div className='text--center mrg--tx3'>
          <button onClick={onLoadMore} className='button__secondary' disabled={disableLoadMore}>
            Load More
          </button>
        </div>
        <div
          className='text--center mrg--tx3'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(0 85 146)',
            color: '#fefefe',
            position: 'absolute',
            left: 0,
            right: 0,
          }}
        >
          You may contact up to {limit} engineers at once{' '}
          <button
            onClick={openSalesLead}
            className='button__success'
            disabled={selectedInstallers.length === 0}
            style={{ margin: '10px' }}
          >
            Contact {selectedInstallers.length > 0 && selectedInstallers.length} selected engineer
            {selectedInstallers.length > 1 && 's'}
          </button>
        </div>
        <div style={{ height: '30px' }}></div>
      </>
    </div>
  )
}

export default ListAllEngineers
