import Presenter from '../../../shared/network/presenters/Presenter'
import { AddressPresenter } from './AddressPresenter'
import { UserPresenter } from './UserPresenter'

export class ConsumerAccountPresenter extends Presenter {
  relationships () {
    return {
      addresses: AddressPresenter,
      user: UserPresenter,
    }
  }
}
ConsumerAccountPresenter.prototype.type = 'consumer-account'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(ConsumerAccountPresenter, Presenter)

export default data => ConsumerAccountPresenter.render(data)
