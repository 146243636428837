import round from 'lodash/round'

const EARTH_RADIUS_IN_MILES = 3959

function areCoordsInvalid (...params) {
  return params.includes(null)
}

/**
 * https://www.movable-type.co.uk/scripts/latlong.html
 * @param {string} lat1 Latitude of location1
 * @param {string} lon1 Longitude of location1
 * @param {string} lat2 Latitude of location2
 * @param {string} lon2 Longitude of location2
 * @returns {number} The distance between the two points
 */
function calculateDistance (lat1, lon1, lat2, lon2, radius) {
  if (areCoordsInvalid(lat1, lon1, lat2, lon2)) return null

  const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180
  const Δφ = ((lat2 - lat1) * Math.PI) / 180
  const Δλ = ((lon2 - lon1) * Math.PI) / 180
  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return radius * c
}

/**
 * Find the distance between the two points
 * @param {string} lat1 Latitude of location1
 * @param {string} lon1 Longitude of location1
 * @param {string} lat2 Latitude of location2
 * @param {string} lon2 Longitude of location2
 * @param {number} decimalPlaces The number of decimal places to round to
 * @returns {number} The distance between the two points in miles, to 1 decimal place by default
 */
export function distanceInMiles (lat1, lon1, lat2, lon2, decimalPlaces = 1) {
  if (areCoordsInvalid(lat1, lon1, lat2, lon2)) return null

  const distance = calculateDistance(lat1, lon1, lat2, lon2, EARTH_RADIUS_IN_MILES)
  return round(distance, decimalPlaces)
}
