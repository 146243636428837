import React, { useState } from 'react'
import { trackEvent } from '../analytics'
import deleteProduct from '../network/requests/deleteProduct'
import SelectOption from './SelectOption'

const RemoveSingleProductModal = props => {
  const { product, hide, reasonsOptions, guaranteeId, onSuccessRemove } = props
  const [reason, setReason] = useState('')
  const [errors, setErrors] = useState({})
  const [remoteError, setRemoteError] = useState('')

  const handleRemoveProduct = () => {
    let err = {}
    if (!reason) err.reason = 'Please select a reason'
    if (Object.keys(err).length) {
      setErrors(err)
    } else {
      deleteProduct(guaranteeId, {
        id: guaranteeId,
        product: {
          reason,
          id: product.id,
        },
      })
        .then(() => {
          hide()
          trackEvent('submit', 'Remove product')
          onSuccessRemove(product.id)
          window.scrollTo(window.scrollX, 0)
        })
        .catch(err => {
          setRemoteError(err.message)
        })
    }
  }

  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>Remove a product</div>
        <div className='modal__body'>
          <p>You are about to remove the following product from your MyBosch account:</p>
          <p>
            <strong>{product.name}</strong>
          </p>
          <p>
            You will no longer be able to access guarantee information or other details about this product from the
            MyBosch portal. Should you ever wish to re-add this product you will be required to enter its serial number.
          </p>

          <SelectOption
            label='Why are you removing this product?'
            value={reason}
            onChange={e => setReason(e.target.value)}
            options={reasonsOptions}
            error={errors.reason}
          />
          {remoteError && <p style={{ color: 'red' }}>{remoteError}</p>}
        </div>
        <div className='modal__buttons'>
          <button className='button__primary modal__button' onClick={hide}>
            Cancel
          </button>
          <button className='button__secondary--danger modal__button' onClick={handleRemoveProduct}>
            Continue and remove
          </button>
        </div>
      </div>
    </div>
  )
}

export default RemoveSingleProductModal
