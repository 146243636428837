import React, { useMemo } from 'react'
import RemoveSelectProduct from './RemoveSelectProductModal'
import RemoveSingleProductModal from './RemoveSingleProductModal'

const REASONS = {
  replaced_due_to_age: 'Replaced due to age',
  replaced_due_to_efficiency: 'Replaced due to efficiency',
  replaced_due_to_renovation: 'Replaced due to renovation',
  i_dont_recognise_this_product: "I don't recognise this product",
  other: 'Other',
}

const RemoveProduct = props => {
  const { guarantee, reasons, guaranteeId, onSuccessRemove, hide } = props

  const products = guarantee.products
  const reasonsOptions = useMemo(
    () =>
      reasons.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: REASONS[curr],
        }),
        {},
      ),
    [reasons],
  )

  if (!products.length) return null
  if (guaranteeId !== guarantee.id) return null

  return (
    <>
      {products.length === 1 ? (
        <RemoveSingleProductModal
          guaranteeId={guaranteeId}
          reasonsOptions={reasonsOptions}
          product={products[0]}
          hide={hide}
          onSuccessRemove={onSuccessRemove}
        />
      ) : (
        <RemoveSelectProduct
          guaranteeId={guaranteeId}
          reasonsOptions={reasonsOptions}
          products={products}
          hide={hide}
          onSuccessRemove={onSuccessRemove}
        />
      )
      }
    </>
  )
}

export default RemoveProduct
