import api from '../../../shared/network/api'
import parseConsumerAccount from '../presenters/ConsumerAccountPresenter'

export default (accountId, data) =>
  api
    .patch(
      `/v3/consumer-account/`,
      parseConsumerAccount(data),
    )
    .then(console.warn)
