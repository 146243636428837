import React from 'react'
import ServiceHeader from './RepairHeader'

const ServiceWithWB = ({ servicePrice, url, weeks }) => {
  const disabled = weeks === null

  return (
    <div className='repair'>
      <ServiceHeader
        disabled={disabled}
        imageSrc='/img/worcester-bosch-logo-stacked.svg'
        title='Request a service with Worcester Bosch'
        text='If you don’t want to use your original installer, or can’t find a local installer, we will be happy to service your boiler with one of our qualified engineers.'
        url={url}
      />
      <div className='repair__data'>
        {disabled ? (
          <div className='sideaction sideaction--iconleft'>
            <img src='/img/icon-calendar-darkblue-48x48.svg' alt='' />
            <div>
              <big className='sideaction__info'>
                Sorry we don’t have any availability right now. Please contact an accredited engineer for a service quote or re-check our availability at a later date.
              </big>
            </div>
          </div>
        ) : (
          <>
          { weeks ? (
            <div className='sideaction sideaction--iconleft'>
              <img src='/img/icon-calendar-darkblue-48x48.svg' alt='' />
              <div>
                <div className='sideaction__text'>Current engineer availability for your postcode:</div>
                <big className='sideaction__info'>{weeks}</big>
              </div>
            </div>
          ) : ('')
          }
            <div className='sideaction sideaction--iconleft'>
              <img src='/img/icon-pound-darkblue-48x48.svg' alt='' />
              <div>
                <div className='sideaction__text'>Boiler Services start:</div>
                <big className='sideaction__info'>From &pound;{servicePrice} Inc. VAT.</big>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ServiceWithWB
