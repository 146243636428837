import React, { useState } from 'react'
import InfoIcon from '../../../configurator/components/InfoIcon'
import FaiSelectInstallerTable from './FaiSelectInstallerTableCard'

const ServiceWithLocalEngineer = ({
  installers,
  installersRoute,
  limit,
  openSalesLead,
  selectedInstallers,
  showMoreEngineers,
  toggleInstaller,
}) => {
  const list = installers.slice(0, limit)
  const [staySafeTooltip, setStaySafeTooltip] = useState(false)

  return (
    <div className='repair__data'>
      <div className='flex-row hide-max-desktop'>
        <h3 className='margin-none flex-grow' style={{ width: '40%' }}>
          <small className='text-black'>Installer</small>
        </h3>
        <h3 className='margin-none flex-grow' style={{ width: '18.75%', textAlign: 'center', position: 'relative' }}>
          <small className='text-black'>
            Safe In
            <br />
            Your Home
          </small>
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translate(0,-50%)',
            }}
          >
            <InfoIcon
              style={{
                width: 20,
                height: 20,
                float: 'right',
                marginLeft: 15,
                color: '#007DB8',
              }}
              onClick={() => setStaySafeTooltip(!staySafeTooltip)}
            />
            {staySafeTooltip && (
              <span className='fai-speech-bubble'>
                Installers who have pledged to our &apos;Safe In Your Home&apos; commitment.
                <br />
                <a
                  href='https://www.worcester-bosch.co.uk/heating-advice/safe-in-your-home'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Learn more
                </a>
              </span>
            )}
          </div>
        </h3>
        <h3 className='margin-none flex-grow' style={{ width: '22.5%', textAlign: 'center' }}>
          <img src='/img/configurator/trustpilot.png' style={{ width: '60%' }} alt='TrustPilot' />
        </h3>
        <h3 className='margin-none flex-grow' style={{ width: '18.75%' }}>
          &nbsp;
        </h3>
      </div>
      <FaiSelectInstallerTable
        installers={list}
        installersRoute={installersRoute}
        selectedInstallers={selectedInstallers}
        toggleInstaller={toggleInstaller}
        openSalesLead={openSalesLead}
      />
      <div className='text--center mrg--tx3'>
        {installers.length === 0 ? null
          : selectedInstallers.length === 0
            ? (
              <button onClick={showMoreEngineers} className='button__secondary'>
                See more local engineers
              </button>
            ) : (
              <button onClick={openSalesLead} className='button__secondary'>
                Contact installers
              </button>
            )}
      </div>
    </div>
  )
}

export default ServiceWithLocalEngineer
