import React from 'react'

function RemoveAddressModal ({ onConfirm, hide, address }) {
  return (
    <div className='modal'>
      <div className='modal__container--remove-address'>
        <div className="modal__title">Removing an address</div>
        <div className="modal__body">
          <p>You are about to remove the following address from your MyBosch account:</p>
          <p className="modal__bold">{address}</p>
          <p>Removing this address will also remove any products associated with this address from your account.
            You will no longer be able to access guarantee information or other details about these products from the MyBosch portal.
          </p>
        </div>
        <div className="modal__buttons">
          <button
            className='button__primary modal__button'
            onClick={hide}
          >
            Cancel
          </button>
          <button
            className='button__secondary--danger modal__button'
            onClick={onConfirm}
          >
            Continue and remove
          </button>
        </div>
      </div>
    </div>
  )
}

export default RemoveAddressModal
