import React, {useMemo, useCallback, useState} from 'react'
import {trackEvent} from '../../analytics'
import RejectedEnquiryModal from '../RejectedEnquiryModal'

const FaiOrFab = props => {
  const {type, enquiryNum, installers, status, createdAt, installationAddress, enquiryType, finance, message} = props

  const [modal, setModal] = useState(false)
  const header = useMemo(() => {
    const label = {
      'boiler-enquiry': 'a Boiler',
      'installer-enquiry': 'an Installer',
    }

    return `Find ${label[type]} Enquiry #${enquiryNum}`
  }, [type, enquiryNum])

  const renderInstaller = useCallback(
    installer => {
      const {name, status, feedbackRoute, contactNumber, email, complete} = installer
      switch (status) {
        case 'waiting':
          return (
            <li className='enquiry__response enquiry__response--waiting' id="waiting">
              <span className='enquiry__response--installer'>
                {name}
                <a className='response__title--contact' href={`tel:${contactNumber}`}>{contactNumber}</a>
                <a className='response__title--contact' href={`mailto:${email}`}>{email}</a>
              </span>
              <span className='enquiry__response--text'>
                <span role='presentation' className='response__icon'>
                  <span role='presentation' className='journey__icon--options'></span>
                </span>
                <span className='response__title'>Awaiting response</span>
              </span>
            </li>
          )
        case 'declined':
          return (
            <li className='enquiry__response enquiry__response--declined' id="declined">
              <span className='enquiry__response--installer'>
                {name}
                <a className='response__title--contact' href={`tel:${contactNumber}`}>{contactNumber}</a>
                <a className='response__title--contact' href={`mailto:${email}`}>{email}</a>
                {!!feedbackRoute &&
                  <a
                    href={feedbackRoute}
                    className='response__submit'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Submit feedback
                  </a>
                }
              </span>
              <span className='enquiry__response--text'>
                <span role='presentation' className='response__icon'>
                  <span role='presentation' className='journey__icon--close'></span>
                </span>
                <span className='response__title'>Unable to assist</span>
                <span className='response__info--declined'>
                  <a
                    onClick={e => {
                      e.preventDefault()
                      setModal(true)
                      trackEvent('modal-open', 'Why enquiry rejected')
                    }}
                  >
                    Why is this?
                  </a>
                </span>
              </span>
            </li>
          )
        case 'accepted':
          return (
            <li className='enquiry__response enquiry__response--accepted' id="accepted">
              <span className='enquiry__response--installer'>
                {name}
                <a className='response__title--contact' href={`tel:${contactNumber}`}>{contactNumber}</a>
                <a className='response__title--contact' href={`mailto:${email}`}>{email}</a>
                {!!feedbackRoute &&
                  <a
                    href={feedbackRoute}
                    className='response__submit'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Submit feedback
                  </a>
                }
              </span>
              <span className='enquiry__response--text'>
                <span role='presentation' className='response__icon'>
                  <span role='presentation' className='journey__icon--tick'></span>
                </span>
                {!complete
                  ? <>
                    <span className='response__title'>Accepted</span>
                    <span className='response__info--accepted'>The Installer will be in touch</span>
                  </>
                  : <span className='response__title'>Complete</span>
                }
              </span>
            </li>
          )
        default:
          return null
      }
    },
    [status, name],
  )

  return (
    <div className='band__fasttrack--current band__cp--fasttrack wb-white'>
      <div className='band__cp--orders'>
        <div className='band__body--cp'>
          <div className='tracker__content'>
            <div className='tracker__column--order'>
              <h2 className='heading__customer--fasttrack'>{header}</h2>
            </div>
            {installers?.length ? (
              <div className='tracker__column--enquiry'>
                <p className='tracker__heading'>Installers contacted:</p>
                <ul className='tracker__enquiry'>{installers.map(renderInstaller)}</ul>
              </div>
            ) : null}
            <div className='tracker__column'>
              <div className='tracker__item'>
                <p className='tracker__label'>Enquiry submitted:</p>
                <p className='tracker__data'>{createdAt}</p>
              </div>
              <div className='tracker__item'>
                <p className='tracker__label'>Installation address:</p>
                <p className='tracker__data'>{installationAddress}</p>
              </div>
              <div className='tracker__item'>
                <p className='tracker__label'>Enquiry type:</p>
                <p className='tracker__data'>{enquiryType}</p>
              </div>
            </div>
            <div className='tracker__column'>
              <div className='tracker__item'>
                <p className='tracker__label'>Interested in finance:</p>
                <p className='tracker__data'>{finance ? 'Yes' : 'No'}</p>
              </div>
              <div className='tracker__item'>
                <p className='tracker__label'>Your Message</p>
                <p className='tracker__data'>{message}</p>
              </div>
            </div>
          </div>
          {modal && <RejectedEnquiryModal hide={() => setModal(false)}/>}
        </div>
      </div>
    </div>
  )
}

export default FaiOrFab
