import React from 'react'
import InputText from './InputText'

const AddressFallbackInput = props => {
  const { address = {}, onChange, errors = {} } = props

  const handleChange = name => e => {
    onChange(name, e.target.value)
  }

  return (
    <>
      <div className='consumer-register__row--two'>
        <InputText
          value={address.unitNumber || ''}
          onChange={handleChange('unitNumber')}
          label='Unit number'
          error={errors.unitNumber}
        />
        <InputText
          value={address.buildingName || ''}
          onChange={handleChange('buildingName')}
          label='Building name'
          error={errors.buildingName}
        />
        <InputText
          value={address.houseNumber || ''}
          onChange={handleChange('houseNumber')}
          label='House number'
          error={errors.houseNumber}
        />
      </div>
      <div className='consumer-register__row--two'>
        <InputText
          value={address.street || ''}
          onChange={handleChange('street')}
          label='Street'
          error={errors.street}
        />
        <InputText
          value={address.district || ''}
          onChange={handleChange('district')}
          label='District'
          error={errors.district}
        />
      </div>
      <div className={'consumer-register__row--two'}>
        <InputText value={address.city || ''} onChange={handleChange('city')} label='City' error={errors.city} />
        <InputText
          value={address.postcode || ''}
          onChange={handleChange('postcode')}
          label='Postcode'
          error={errors.postcode}
        />
      </div>
    </>
  )
}

export default AddressFallbackInput
