import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { trackEvent } from '../analytics'
import findAllUniqueProductsAtAddress from '../utils/findAllUniqueProductsAtAddress'

const MAX_FAQS = 4

const FaqItem = ({name, question, answer}) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div className={cx(['accordion__item', isVisible && 'is-visible'])}>
      <div
        className='accordion__toggle text  text--p  text--sm  mrg-reset  mrg--x0'
        onClick={() => {
          trackEvent('faq-open', name + '-' + question)
          setIsVisible(!isVisible)
        }}
      >
        <p>{question}</p>
      </div>
      <div className='accordion__content text  text--p  text--sm mrg-reset  mrg--x0'>
        <div dangerouslySetInnerHTML={{__html: answer}}></div>
      </div>
    </div>
  )
}

const Faqs = ({faqs, name, url}) => {
  const items = Object.values(faqs)
  return items.length > 0 && (
    <div className='matrix__cell'>
      <h2 className='heading  heading--h2 heading__faqs mrg--bx4'>FAQs about your product</h2>
      <ul className='list-divided  accordion  js-accordion'>
        {items.slice(0, MAX_FAQS).map((faq, index) => (
          <li key={index}>
            <FaqItem name={name} question={faq.title} answer={faq.description} />
          </li>
        ))}
      </ul>
      {items.length >= MAX_FAQS && (
        <div className='text__align--center  mrg--tx3'>
          <a className='button__secondary' href={url}
            data-analytics
            data-analytics-category='consumer-portal'
            data-analytics-label={name + ' - Further information'}
            data-analytics-action='click'>
            Show more
          </a>
        </div>
      )}
    </div>
  )
}

const SelectAddress = ({addresses, onSelect}) => {
  return (
    <div className='matrix__cell'>
      <h2 className='heading  heading--h2  text__align--center  ts:text-left  mrg--bx4'>Select an address</h2>
      <form action='' className='form'>
        <div className='form__group'>
          <label className='form__label' htmlFor='address'>
            Address
          </label>
          <select
            name='address'
            id='address'
            className='form__control'
            onChange={event => onSelect(event.target.value)}
          >
            {addresses.map((address, key) => (
              <option key={key} value={address}>
                {address}
              </option>
            ))}
          </select>
        </div>
      </form>
    </div>
  )
}

const SelectProduct = ({activeProduct, products, onSelect}) => {
  return (
    <div className='matrix__cell'>
      <h2 className='heading heading--h2 text__align--center ts:text-left mrg--bx4'>Select a product</h2>
      <div className="matrix-hscroll">
        <div className='matrix--listing'>
          {products.map(product => (
            <button
              key={product.id}
              className={cx(['frame', 'frame--shadow', 'frame--dimmed', 'text-center', 'frame--is-clickable', product.id === activeProduct.id && 'is-active'])}
              onClick={() => {
                onSelect(product)
              }}
            >
              <div className='frame__icon'>
                <img src={`/img/${product.image}`} alt=''/>
              </div>
              {product.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

const UsefulDocuments = ({docs}) => (
  Array.isArray(docs) && docs.length > 0 && (
    <div className='matrix__cell'>
      <h2 className='heading  heading--h2  mrg--bx4'>Useful documents</h2>
      <ul className='list-divided'>
        {docs.map(doc => (
          <li key={doc.partNumber}>
            <div className='sideaction'>
              <div>
                <a href={doc.route} className='link  text--md'
                  data-analytics
                  data-analytics-category='View'
                  data-analytics-label={`${doc.partNumber} - ${doc.title}`}
                  data-analytics-action='Document'>
                  {doc.title}
                </a>
              </div>
              <div>
                <a
                  className='button__primary'
                  data-analytics
                  data-analytics-category='Download'
                  data-analytics-action='Document'
                  data-analytics-label={`${doc.partNumber} - ${doc.title}`}
                  download
                  href={doc.route}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Download
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
)

const UsefulPages = ({product}) => {
  const setImageSource = (page) => {
    if (page.image) {
      return page.image
    }
    if (product.image) {
      return '/img/' + product.image
    }
    return '/img/consumer-portal/useful-pages-placeholder.png'
  }
  return (
    <>
      <h2 className='heading heading--h2 text__align--center ts:text-left mrg--bx4'>Useful pages</h2>
      <div className='matrix matrix--4x4'>
        {product.pages.map((page, key) => (
          <div key={key} className='matrix__cell'>
            <div className='frame frame--bg-white'>
              <div className='frame__cover'>
                <img src={setImageSource(page)} alt='Product page header image'/>
              </div>
              <h3 className='heading heading--h3 text__align--center'>{page.title}</h3>
              <div className='frame__cta text__align--center'>
                <a target='_blank' rel='noopener noreferrer' className='button__primary display--block' href={page.route}
                  data-analytics
                  data-analytics-category='Read'
                  data-analytics-action='Document'
                  data-analytics-label={`${page.route}`}>
                  Read now
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const ProductAdvice = ({products: guaranteesByAddress}) => {
  const addressKeys = Object.keys(guaranteesByAddress)
  const [selectedAddress, setSelectedAddress] = useState(addressKeys[0])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [productsAtSelectedAddress, setProductsAtSelectedAddress] = useState([])

  const handleAddressChange = addressKey => {
    setSelectedAddress(addressKey)
  }

  const handleProductChange = product => {
    setSelectedProduct(product)
  }

  useEffect(() => {
    const products = findAllUniqueProductsAtAddress(guaranteesByAddress[selectedAddress])
    setProductsAtSelectedAddress(products)
    setSelectedProduct(products[0] || null)
  }, [selectedAddress])

  return (
    <div className='product-advice'>
      <div className='sect  sect--pdn40  sect--gray-100'>
        <div className='container-wrap'>
          <div className='matrix matrix--productAdvice matrix--1+1'>
            <SelectAddress
              addresses={addressKeys}
              onSelect={handleAddressChange}
            />
            <SelectProduct
              activeProduct={selectedProduct}
              products={productsAtSelectedAddress}
              onSelect={handleProductChange}/>
          </div>
        </div>
      </div>
      {selectedProduct !== null && (
        <>
          <div className='sect'>
            <div className='container-wrap'>
              <div className='matrix matrix--1+1'>
                <UsefulDocuments docs={Object.values(selectedProduct.documents)}/>
                <Faqs product={selectedProduct.name} faqs={selectedProduct.faqs.items} url={selectedProduct.faqs.url}/>
              </div>
            </div>
          </div>
          {selectedProduct.pages.length > 0 &&
          <div className='sect sect--gray-100'>
            <div className='container-wrap'>
              <UsefulPages product={selectedProduct}/>
            </div>
          </div>
          }
        </>
      )}
    </div>
  )
}

export default ProductAdvice
