import React from 'react'
import { trackEvent } from '../analytics'
import api from '../../shared/network/api'

const RectificationNeededModal = ({ customerActions, onClose, onSuccess }) => {
  trackEvent('modal', 'has seen rectification needed')

  const handleComplete = () => {
    api
      .post('/v3/consumer-account/service/customer-actions/complete', [])
      .then((response) => {
        onSuccess(response)
        trackEvent('modal', 'accepted rectification needed')
      })
      .catch(onClose)
  }
  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>
          We have noticed your heating system shows work which needs to be rectified by the homeowner before we can
          visit.
        </div>
        <div className='modal__body'>
          <ul>{customerActions.map(action => <li key={action.id}>{action.customer_action}</li>)}</ul>
          <p>If this work has been rectified, please let us know in the &apos;request form&apos; within the fault section.</p>
        </div>
        <div className='modal__buttons modal__buttons--stack'>
          <button className='button__primary modal__button' onClick={handleComplete}>
            Complete
          </button>
          <button className='button__secondary modal__button' onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default RectificationNeededModal
