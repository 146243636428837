import set from 'lodash/set'
/**
 * Transform a Yup ValidationError into an object of shape { fieldName : message }
 * @param error
 */

export default error => {
  if (!error || !error.inner) return {}
  return error.inner.reduce((errors, error) => {
    set(errors, error.path, error.errors[0])
    return errors
  }, {})
}
