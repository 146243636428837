import React, { useState } from 'react'
import { trackEvent } from '../analytics'
import addProduct from '../network/requests/addProduct'
import InputText from './InputText'

const AddProductModal = ({ hide, onError }) => {
  const [serialNumber, setSerialNumber] = useState('')
  const [error, setError] = useState('')

  const handleAddProduct = () => {
    if (!serialNumber) return setError('Please enter a serial number')

    addProduct(serialNumber)
      .then(() => {
        hide()
        trackEvent('submit', 'Add product')
        window.location.reload()
      })
      .catch(() => {
        onError()
      })
  }

  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>Add a product</div>
        <div className='modal__body'>
          <p>
            If your product has not automatically appeared in your MyBosch portal you can manually add it here. If you
            have recently had your new product installed please allow up to 72 hours for the product to appear before
            attempting to add it manually.
          </p>
          <p>
            To add a product please enter its serial number below. You can find this serial number in the products
            manual or in some cases on the data label attached to your product.
          </p>
          <p>
            <strong>
              Consider your safety and do not look for a data label if your appliance has been installed with restricted access or requires steps or ladders to reach.
            </strong>
          </p>

          <InputText
            value={serialNumber}
            placeholder='5572 918 003647 7731600167'
            onChange={e => setSerialNumber(e.target.value)}
            label='Enter a serial number'
            error={error}
          />
        </div>
        <div className='modal__buttons'>
          <button className='button__primary modal__button modal__button--margin-right' onClick={hide}>
            Cancel
          </button>
          <button className='button__primary modal__button modal__button--margin-top' onClick={handleAddProduct}>
            Search for Guarantee
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddProductModal
