import React, { useState, useEffect } from 'react'
import { trackEvent } from '../analytics'
import RemoveProduct from './RemoveProduct'
import DownloadManual from './DownloadManual'
import formatGuarantees from '../utils/formatGuarantees'
import Image from './Image'

const Products = props => {
  const { guarantees, reasons } = props
  const [guaranteeToRemove, setGuaranteeToRemove] = useState()
  const [successRemove, setSuccessRemove] = useState(false)
  const [items, setItems] = useState(() => formatGuarantees(guarantees))

  useEffect(() => {
    if (successRemove) {
      setTimeout(() => {
        setSuccessRemove(false)
      }, 8000)
    }
  }, [successRemove])

  const handleSuccessRemove = productId => {
    setSuccessRemove(true)

    const newItems = items
      .map(guarantee => ({
        ...guarantee,
        products: guarantee.products.filter(product => String(product.id) !== String(productId)),
      }))
      .filter(guarantee => guarantee.products.length)

    setItems(newItems)
  }

  return (
    <>
      {successRemove && (
        <div className='alert fade' style={{ margin: 8, maxWidth: '100%' }}>
          <div className='alert__icon'>
            <span className='alert__icon--success'></span>
          </div>
          <div className='alert__content'>The product has been successfully removed</div>
        </div>
      )}
      <header className='container__wai--jobs'>
        <div className=' header__wai--jobs'>
          <h1 className='heading__products'>
            <span className='heading__icon--boiler'></span>
            My Products
          </h1>
        </div>
      </header>
      <>
        {!items.length && (
          <div className='empty'>
            <p className='empty__title'>You currently have no products</p>
            <img src='/img/consumer-portal/placeholder-boiler.png' className='empty__image' />
          </div>
        )}
        {items.map(guarantee => (
          <div key={guarantee.id} className='guarantee'>
            <div className='guarantee__container'>
              <div className='guarantee__wrapper'>
                <div className='guarantee__wrapper--top'>
                  <img
                    src={`/img/guarantee_${guarantee.guaranteeLength}.png`}
                    className='guarantee__label'
                    width='82'
                    height='82'
                  />
                  <p className='guarantee__title'>{guarantee.heading}</p>
                  <p className='guarantee__serial'></p>
                  <div className='guarantee__installed'>
                    <p className='guarantee__subheading'>Installed at:</p>
                    <p className='guarantee__address'>{guarantee.installedAt}</p>
                  </div>
                  <ul className='guarantee__list'>
                    {guarantee.products.map(product => (
                      <li key={product.id} className='guarantee__item'>
                        <p className='guarantee__name'>{product.name}</p>
                        <span className='guarantee__number'>Serial number: {product.serialNumber}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='guarantee__box'>
                  <img src='/img/consumer-portal/installed.svg' className='guarantee__logo' width='50' height='50' />
                  <div className='guarantee__wrapper--box'>
                    <div className='guarantee__installed--box'>
                      <p className='guarantee__subheading--box'>Installed by:</p>
                      <p className='guarantee__address--box'>{guarantee.installedBy}</p>
                    </div>
                    <div className='guarantee__details'>
                      <div className='guarantee__row'>
                        <span className='list__icon--phone guarantee__icon'></span>
                        <a
                          href={`tel:${guarantee.phone}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='guarantee__contact'
                          data-analytics
                          data-analytics-category='consumer-protal'
                          data-analytics-label='Installer phone'
                          data-analytics-action='click'
                        >
                          {guarantee.phone}
                        </a>
                      </div>
                      <div className='guarantee__row'>
                        <span className='list__icon--email guarantee__icon'></span>
                        <a
                          href={`mailto:${guarantee.email}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='guarantee__contact'
                          data-analytics
                          data-analytics-category='consumer-protal'
                          data-analytics-label='Installer email'
                          data-analytics-action='click'
                        >
                          {guarantee.email}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='guarantee__box'>
                  <img
                    src='/img/consumer-portal/guarantees-yes.svg'
                    className='guarantee__logo'
                    width='50'
                    height='50'
                  />
                  <div className='guarantee__wrapper--box'>
                    <div className='guarantee__installed--box'>
                      <p className='guarantee__subheading--box'>Guarantee status:</p>
                      <p className='guarantee__address--box'>{guarantee.expiresAt}</p>
                    </div>
                    <div className='guarantee__details'>
                      <p className='guarantee__description'>
                        Ensure your product is serviced annually to protect your guarantee
                      </p>
                    </div>
                  </div>
                </div>
                <div className='guarantee__buttons'>
                  {guarantee.downloadCertificate && (
                    <a
                      href={guarantee.downloadCertificate}
                      className='button button__primary'
                      data-analytics
                      data-analytics-category='consumer-portal'
                      data-analytics-label='Download Guarantee Certificate'
                      data-analytics-action='click'
                    >
                      Download guarantee certificate
                    </a>
                  )}
                  <DownloadManual products={guarantee.products} />
                </div>
                {!!guarantee.products.length && (
                  <a
                    className='guarantee__link guarantee__link--mobile'
                    onClick={() => {
                      setGuaranteeToRemove(guarantee.id)
                      trackEvent('modal-open', 'Remove product')
                    }}
                  >
                    I no longer have one or more of these items
                  </a>
                )}
              </div>

              <div className='guarantee__column'>
                <div className='guarantee__wrapper--image'>
                  {!!guarantee.guaranteeLength && (
                    <Image
                      src={`/img/guarantee_${guarantee.guaranteeLength}.png`}
                      className='guarantee__label--desktop'
                    />
                  )}
                  <img src={`/img/${guarantee.products[0]?.image}`} className='guarantee__boiler' />
                  {!!guarantee.products.length && (
                    <a
                      className='guarantee__link guarantee__link--desktop'
                      onClick={() => {
                        setGuaranteeToRemove(guarantee.id)
                        trackEvent('modal-open', 'Remove product')
                      }}
                    >
                      I no longer have one or more of these items
                    </a>
                  )}
                </div>
              </div>
            </div>
            <RemoveProduct
              hide={() => setGuaranteeToRemove()}
              guaranteeId={guaranteeToRemove}
              guarantee={guarantee}
              reasons={reasons}
              onSuccessRemove={handleSuccessRemove}
            />
          </div>
        ))}
      </>
    </>
  )
}

export default Products
