import React, { useState } from 'react'
import MyAddressesFormV2 from './MyAddressesFormV2'
import MyDetailsFormV2 from './MyDetailsFormV2'

const getStatuses = addresses => {
  const statuses = addresses.map(address => address.residentialStatus)
  const unique = [...new Set(statuses)]
  return unique
}

// Serves as a HOC for mounting the form components and keeping the state in sync
export default function MyAccountForm ({ data }) {
  const [state, setState] = useState(data)

  const updateResidentialStatuses = addresses =>
    setState(prev => {
      const newState = { ...prev }
      const [primaryAddress, ...additionalAddresses] = addresses
      const statuses = getStatuses(addresses.map(item => item.address))
      return {
        ...newState,
        primaryAddress,
        additionalAddresses,
        details: { ...newState.details, statuses },
      }
    })

  // we're setting the key for MyDetailsForm to ensure the component is re-rendered on the state statuses changes
  return (
    <>
      <MyDetailsFormV2 data={state} key={state.details.statuses}/>
      <MyAddressesFormV2 data={state} onAddressChange={updateResidentialStatuses} />
    </>
  )
}
