import React from 'react'

const QuoteModal = ({ quote, hide }) => {
  if (!quote) return null

  const {
    orderNumber,
    totalPrice,
    customerConfirmationUrl,
    expectedInstallationDate,
    installationAddress,
    installerCompany,
  } = quote

  return (
    <div className='modal'>
      <div className='modal__container--fasttrack-confirmation'>
        <button className='confirmation-modal__cross' onClick={hide}></button>
        <div className='confirmation-modal__container'>
          <p className='confirmation-modal__title'>
            Great news, you are one step away from finalising your FastTrack installation
          </p>
          <p className='confirmation-modal__reference'>Reference number: {orderNumber}</p>
        </div>
        <div className='confirmation-modal__wrapper'>
          <p className='confirmation-modal__heading'>Below you will find a breakdown of your FastTrack installation:</p>
          <div className='confirmation-modal__row'>
            <p className='confirmation-modal__heading--large'>Agreed Installation price to be paid to installer:</p>
            <p className='confirmation-modal__price'>{totalPrice} inc VAT</p>
          </div>
        </div>
        <div className='confirmation-modal__container'>
          <p className='confirmation-modal__heading--black'>
            Please confirm you are happy to proceed with the installation
          </p>
          <p className='confirmation-modal__description'>
            Your FastTrack order has been organised and your installer is ready to go. To finalise your order and secure
            your installation date we require you to confirm your installation.
          </p>
          <div className='confirmation-modal__button-wrapper'>
            <a
              className='button button__primary tracker__alert--button'
              href={customerConfirmationUrl}
              data-analytics
              data-analytics-category='consumer-protal'
              data-analytics-label='Confirm installation'
              data-analytics-action='click'
            >
              Confirm installation
            </a>
          </div>
          <p className='confirmation-modal__description'>
            Please note that by confirming the installation, you are instigating an agreement with your assigned
            FastTrack installer to proceed with the installation at the agreed price, on the date shown. Please refer to
            our Terms and Conditions for further details.
          </p>
        </div>
        <div className='confirmation-modal__grid'>
          <div className='confirmation-modal__column'>
            <p>{expectedInstallationDate}</p>
            <p>Arrival any time after 8am</p>
          </div>
          <div className='confirmation-modal__column'>
            <p>{installationAddress.firstLine}</p>
            <p>{installationAddress.city}</p>
            <p>{installationAddress.county}</p>
            <p>{installationAddress.postcode}</p>
          </div>
        </div>
        {installerCompany && (
          <div className='confirmation-modal__wrapper'>
            <p className='confirmation-modal__heading--white'>Your installer</p>
            <p className='confirmation-modal__heading--large'>{installerCompany.name}</p>
            <p className='confirmation-modal__number'>{installerCompany.contactNumber}</p>
            <p className='confirmation-modal__email'>{installerCompany.email}</p>
            <p className='confirmation-modal__heading'>
              Our Worcester Accredited Installers are all fully trained and qualified to the highest standard.
            </p>
          </div>
        )}
        <div className='confirmation-modal__container'>
          <p className='confirmation-modal__heading--black'>Next steps to your installation...</p>
          <ol>
            <li className='confirmation-modal__list-item'>
              Your installer will contact you to discuss your monthly payment options and application
            </li>
            <li className='confirmation-modal__list-item'>
              Installer arrives and installs new Worcester Bosch boiler. Please ensure it is easily accessible.
            </li>
            <li className='confirmation-modal__list-item'>
              You will be required to pay the remaining balance; this will be discussed with your installer.<br />
              This can include, BACS, card or cash.
            </li>
            <li className='confirmation-modal__list-item'>
              Your installer will register the guarantee for your new products within 30 days.
            </li>
            <li className='confirmation-modal__list-item'>
              Now it is time to enjoy your heating and hot water from Worcester Bosch!
            </li>
          </ol>
          <p className='confirmation-modal__support'>
            For customer support please call&nbsp;
            <a href='tel:03330 604 891' title='Click here to phone support'>
              03330 604 891
            </a>
          </p>
        </div>
        <div className='confirmation-modal__footer'>
          <div className='confirmation-modal__footer-wrapper'>
            For more information please check the <a href="https://www.worcester-bosch.co.uk/fasttrack-terms-conditions" rel="noopener noreferrer" target="_blank">terms and conditions</a> of our FastTrack Installation service.
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteModal
