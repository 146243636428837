import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

const DropzoneFileUpload = ({ extensions, onNewFiles, quantity }) => {
  const [fileList, setFileList] = useState([])
  const refFileInput = useRef(null)

  const hasValidExtension = file => {
    if (!extensions.length) return true
    return extensions.some(ext => file.name.endsWith(ext))
  }

  const handleDragging = event => event.preventDefault()

  const updateFileList = files => {
    if (!files) return

    if (quantity === 1) {
      if (hasValidExtension(files[0])) {
        setFileList([files[0]])
        onNewFiles([files[0]])
      }
      return
    }

    const filtered = Array.from(files).reduce((acc, file) => (hasValidExtension(file) ? [...acc, file] : acc), [])

    setFileList(filtered)
    onNewFiles(filtered)
  }

  const launchOsFileChooserMenu = () => {
    // just activate native HTML5 functionality of <input type"file"
    refFileInput.current.click()
  }

  return (
    <>
      <button
        className='form__dropzone'
        onClick={launchOsFileChooserMenu}
        onDragOver={handleDragging}
        onDragEnter={handleDragging}
        onDrop={event => {
          event.preventDefault()
          updateFileList(event.dataTransfer.files)
        }}
        type='button'
      >
        {fileList.length > 0 ? (
          <section className='file-preview'>
            <p className='form__dropzone__title'>File{fileList.length > 1 && 's'} ready to upload:</p>
            {fileList.map(file => (
              <p className='form__dropzone__info' key={file.name}>
                {file.name}
              </p>
            ))}
          </section>
        ) : (
          <>
            <div className='form__dropzone__icon'></div>
            <div className='form__dropzone__title'>
              Drag and drop or select {quantity > 1 ? 'some files' : 'a file'} to upload
            </div>
            <div className='form__dropzone__info'>Accepted file formats: {extensions.join(', ')}</div>
          </>
        )}
      </button>
      <input
        accept={extensions.join(', ')}
        className='hidden-form-element'
        multiple
        onChange={event => {
          event.preventDefault()
          updateFileList(event.target.files)
        }}
        ref={refFileInput}
        type='file'
      ></input>
    </>
  )
}

DropzoneFileUpload.propTypes = {
  extensions: PropTypes.arrayOf(PropTypes.string),
  onNewFiles: PropTypes.func,
  quantity: PropTypes.number,
}

DropzoneFileUpload.defaultProps = {
  extensions: [],
  onNewFiles: () => {},
  quantity: 1,
}

export default DropzoneFileUpload
