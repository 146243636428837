import React from 'react'
import PropTypes from 'prop-types'

const ServiceWithOriginalInstaller = ({
  installer,
  installersRoute,
  openSalesLead,
  showCtas,
  action,
  toggleInstaller,
}) => {
  return (
    <div className='repair__data'>
      <h2 className='repair__data-title'>
        The engineer who originally installed your product may be available to complete your service
      </h2>
      <div className='repair__data-card'>
        <div className='repair__data-card-header'>
          <img src='/img/icon-repair-white.svg' alt='' />
          Your product’s original engineer
        </div>
        <div className='repair__data-card-details'>
          <h3 className='repair__data-card-title'>{installer.name ?? installer.companyName}</h3>
          <div className='repair__data-card-grid'>
            <div>
              {installer.distance !== null && <span className='highlight-info'>{installer.distance || 1} Miles away</span>}
              <a href={`tel:${installer.address.telephone_number}`} className='highlight-info'>
                <img src='/img/consumer-portal/phone.svg' alt='' />
                {installer.address.telephone_number}
              </a>
            </div>
            <div>{installer.safeAtHomeAt && <img src='/img/staysafe-logo.png' width='78' height='78' alt='' />}</div>
            <div>
              <p>
                {installer.name ?? installer.companyName} originally installed your product on the {installer.installedOn}
              </p>
            </div>
          </div>
          <div className='repair__data-card-contactgroup' style={{ visibility: showCtas ? 'visible' : 'hidden' }}>
            {installer.slug !== null &&
              <a
                href={`${installersRoute}/${installer.slug}`}
                className='button__secondary'
                target='_blank'
                rel='noopener noreferrer'
              >
                About installer
              </a>
            }
            <button
              onClick={() => {
                // reset the installers array, then add ours and show the contact modal
                toggleInstaller(installer, true, true)
                openSalesLead()
              }}
              className='button__primary'
            >
              Contact installer
            </button>
          </div>
        </div>
      </div>
      { action && (
        <div className='text--center mrg--tx3'>
          <button onClick={action} className='button__secondary'>
            Unable to contact original installer
          </button>
        </div>
      )}
    </div>
  )
}

ServiceWithOriginalInstaller.PropTypes = {
  installer: PropTypes.object,
  openSalesLead: PropTypes.func,
  toggleInstaller: PropTypes.func,
}

export default ServiceWithOriginalInstaller
